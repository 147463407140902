import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { SalesRepCustomer } from '../model/sales-rep-customer.model';
import { SalesRepCustomerState, SalesRepCustomerStore } from '../store/sales-rep-customer.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'sCompany1', sortByOrder: Order.ASC })
export class SalesRepCustomerQuery<T extends SalesRepCustomer> extends QueryEntity<SalesRepCustomerState<T>> {
  constructor(protected override store: SalesRepCustomerStore<T>) {
    super(store);
  }
}
