import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ShippingType } from '../model/shipping-type.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ShippingTypeState<T extends ShippingType> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'shipping-types', idKey: 'shtShippingTypeID' })
export class ShippingTypeStore<T extends ShippingType> extends EntityStore<ShippingTypeState<T>> {
  constructor() {
    super();
  }
}
