import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TicketCategory } from '../models/ticket-category.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TicketCategoryState<T extends TicketCategory> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ticket-categories', idKey: 'lngCategoryID' })
export class TicketCategoryStore<T extends TicketCategory> extends EntityStore<TicketCategoryState<T>> {
  constructor() {
    super();
  }
}
