import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { STRAPI_CMS_CONFIG, StrapiCmsConfig } from './config/strapi-cms.config';
import { PagePathPipe } from './pipes/page-path.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }],
  declarations: [PagePathPipe],
  exports: [PagePathPipe],
})
export class StrapiCmsModule {
  static forRoot(config: StrapiCmsConfig): ModuleWithProviders<StrapiCmsModule> {
    return {
      ngModule: StrapiCmsModule,
      providers: [
        {
          provide: STRAPI_CMS_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
