import { ID } from '@datorama/akita';
import { Article } from '../../catalog';
import { Discount } from '../../sales-order';

/**
 * Cart detail
 */
export interface CartItem {
  fakeId: ID;
  bAlternativeItem: boolean;
  bBlockDelivery: boolean;
  bPartialDelivery: boolean;
  decFactorSalesPrice: number;
  decItemGrossAmountFC: number;
  decItemNetAmountFC: number;
  decItemTaxAmountFC: number;
  decPrice: number;
  decPriceFactor: number;
  decPriceNetExDiscounts: number;
  decPricePG: number;
  decQuantity: number;
  decQuantityDelivered: number;
  decQuantityDelivered2: number;
  decQuantityOrdered: number;
  decSalesPrice: number;
  decTaxRate: number;
  dtDeliveryDate: number;
  dtDeliveryNoteIDDate: number;
  dtInvoiceDate: number;
  dtRequestedDate: number;
  lngCampaignID: number;
  lngCustomerOrderID: number;
  lngDeliveryNoteID: number;
  lngGoodsReceiptID: number;
  lngInvoiceID: number;
  lngOrderID: ID | number;
  lngPackingListID: number;
  lngSalesPriceUnit: number;
  lngSupplierID: number;
  oArticle: Article;
  oDiscount1: Discount;
  oDiscount2: Discount;
  oDiscount3: Discount;
  oDiscount4: Discount;
  oDiscount5: Discount;
  oDiscount6: Discount;
  sArticleID: string | ID;
  sArticleIDOfDataSupplier: string;
  sArticleName: string;
  sCustomerArticleID: string;
  sItemText: string;
  sItemType: string;
  sItemsListID: string;
  sOfferStatus: string;
  sOrderStatus: string;
  sOrderText: string;
  sOrderType: string;
  sPurchOrderNumber: string;
  sQuantityUnit: string;
  sTaxCode: string;
  sUniformNumber: string;
  shtBranchID: number;
  shtFixedItemID: number;
  shtFixedItemIDItemGroup: number;
  shtHasAdditionalItems: number;
  shtIsAdditionalToItem: number;
  shtItemID: number;
  shtItemsList: number;
  shtOfferStatus: number;
  shtPlanningCode: number;
  shtProcessedItem: number;
  shtRestOfItem: number;
  shtStatus: number;
  shtStorageID: number;
  sCode1: string;
  sCode2: string;
  sCode3: string;
}

/**
 * A factory function that creates CartItem
 */
export function createCartItem<T extends CartItem = CartItem, C extends Article = Article>({
  decQuantity = 0,
  decQuantityDelivered = 0,
  decQuantityOrdered = 0,
  sArticleID = '',
  sArticleName = '',
  sItemText = '',
  lngOrderID = 0,
  shtFixedItemID = 0,
  oArticle = undefined,
  decPrice = 0,
}: {
  decQuantity?: number;
  decQuantityDelivered?: number;
  decQuantityOrdered?: number;
  sArticleID?: ID;
  sArticleName?: string;
  sItemText?: string;
  lngOrderID?: ID;
  shtFixedItemID?: number;
  oArticle?: C;
  decPrice?: number;
}): Partial<T> {
  const fakeId = [lngOrderID, shtFixedItemID].join(',') as ID;
  return {
    fakeId,
    decQuantity,
    decQuantityDelivered,
    decQuantityOrdered,
    sArticleID,
    sArticleName,
    sItemText,
    lngOrderID,
    shtFixedItemID,
    oArticle,
    decPrice,
  } as unknown as Partial<T>;
}
