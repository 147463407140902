import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CRUD_CONFIG, CrudConfig } from './config/crud.config';

@NgModule({
  imports: [CommonModule],
})
export class CrudModule {
  static forRoot(config: CrudConfig): ModuleWithProviders<CrudModule> {
    return {
      ngModule: CrudModule,
      providers: [
        {
          provide: CRUD_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
