import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ID } from '@datorama/akita';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { User } from '../../user';
import { CartAdapterInterface } from '../model/cart-adapter.interface';
import { CartHeader } from '../model/cart-header.model';
import { CartItem, createCartItem } from '../model/cart-item.model';
import { Cart } from '../model/cart.model';
import { CreateCartItemInterface } from '../model/create-cart-item.interface';
import { CartItemLocalQuery } from '../query/cart-item-local.query';
import { CartItemLocalStore } from '../store/cart-item-local.store';
import { CartHeaderLocalQuery } from '../query/cart-header-local.query';
import { CartHeaderLocalStore } from '../store/cart-header-local.store';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CartLocalService<T extends CartHeader, R extends CartItem, S extends CreateCartItemInterface>
  implements CartAdapterInterface<T, R, S>
{
  static readonly ACTIVE_CART_ID = 1;

  constructor(
    protected cartItemLocalQuery: CartItemLocalQuery<R>,
    protected cartItemLocalStore: CartItemLocalStore<R>,
    protected cartHeaderLocalQuery: CartHeaderLocalQuery<T>,
    protected cartHeaderLocalStore: CartHeaderLocalStore<T>,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  changeActiveCart(_: number | ID): Observable<Cart<T, R> | undefined> {
    const header$ = this.cartHeaderLocalQuery.selectEntity(CartLocalService.ACTIVE_CART_ID);
    const items$ = this.cartItemLocalQuery.selectAll().pipe(map((x) => x.filter((i) => i.lngOrderID == CartLocalService.ACTIVE_CART_ID)));
    return combineLatest([header$, items$]).pipe(
      switchMap(([header, items]) => {
        const cart: Cart<T, R> = { oSalesMaster: header as T, oSalesItemList: items };
        return of(cart);
      }),
    );
  }

  createCartHeader(cartHeader: T): Observable<T> {
    return of(cartHeader).pipe(
      tap((header) => {
        const cart = { ...header, lngOrderID: CartLocalService.ACTIVE_CART_ID };
        cart.sCartName ??= formatDate(new Date(), 'dd.MM.yyyy HH:mm', this.locale);
        this.cartHeaderLocalStore.add(cart);
      }),
    );
  }

  createCartItem(cartItem: S, _: number | ID): Observable<Cart<T, R>> {
    const list = (this.cartItemLocalQuery.getAll() || []).sort((c1: R, c2: R) => c2.shtFixedItemID - c1.shtFixedItemID);
    const itemId = (list[0]?.shtFixedItemID ?? 0) + 1;

    return of(undefined).pipe(
      switchMap(() =>
        of(
          createCartItem<R>({
            oArticle: cartItem.oArticle as any,
            decQuantity: cartItem.decQuantity,
            decQuantityDelivered: cartItem.decQuantity,
            decQuantityOrdered: cartItem.decQuantity,
            sArticleID: cartItem.sArticleID,
            sArticleName: cartItem.sArticleName,
            sItemText: cartItem.sItemText,
            lngOrderID: CartLocalService.ACTIVE_CART_ID,
            shtFixedItemID: itemId,
            decPrice: cartItem.oArticle.oPriceInfo?.decPriceNet,
          }),
        ),
      ),
      tap((newCartItem: Partial<R>) => this.cartItemLocalStore.add(newCartItem as R)),
      switchMap(() =>
        of({
          oSalesMaster: this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID) as T,
          oSalesItemList: this.cartItemLocalQuery.getAll(),
        } as Cart<T, R>),
      ),
    );
  }

  deleteCart(lngOrderID: number | ID): Observable<void> {
    return of(undefined).pipe(tap(() => this.cartHeaderLocalStore.remove(lngOrderID)));
  }

  deleteCartItem(cartItem: R): Observable<Cart<T, R>> {
    return of(cartItem).pipe(
      tap(() => this.cartItemLocalStore.remove(cartItem.fakeId)),
      switchMap(() =>
        of({
          oSalesMaster: this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID) as T,
          oSalesItemList: this.cartItemLocalQuery.getAll(),
        }),
      ),
    );
  }

  getCartById(_: number | ID | undefined): Observable<Cart<T, R>> {
    const cart = {
      ...this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID),
      lngOrderID: CartLocalService.ACTIVE_CART_ID,
    } as T;
    cart.sCartName ??= formatDate(new Date(), 'dd.MM.yyyy HH:mm', this.locale);
    this.cartHeaderLocalStore.upsert(CartLocalService.ACTIVE_CART_ID, cart);
    return of({
      oSalesMaster: this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID) as T,
      oSalesItemList: this.cartItemLocalQuery.getAll(),
    });
  }

  getCartHeaders(): Observable<T[]> {
    return this.cartHeaderLocalQuery.selectEntity(CartLocalService.ACTIVE_CART_ID).pipe(
      filter((x): x is T => !!x),
      map((a) => [a]),
    );
  }

  submitCart(): Observable<void> {
    return of(undefined);
  }

  updateCartHeader(cartHeader: T): Observable<Cart<T, R> | undefined> {
    return of(cartHeader).pipe(
      tap(() => this.cartHeaderLocalStore.update(CartLocalService.ACTIVE_CART_ID, cartHeader)),
      switchMap(() =>
        of({
          oSalesMaster: this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID) as T,
          oSalesItemList: this.cartItemLocalQuery.getAll(),
        }),
      ),
    );
  }

  updateCartItem(cartItem: R): Observable<Cart<T, R>> {
    return of(cartItem).pipe(
      tap(() => this.cartItemLocalStore.update(cartItem.fakeId, { ...cartItem, decQuantityOrdered: cartItem.decQuantity })),
      switchMap(() =>
        of({
          oSalesMaster: this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID) as T,
          oSalesItemList: this.cartItemLocalQuery.getAll(),
        }),
      ),
    );
  }

  setActiveId(_?: User): Observable<number | ID> {
    return of(CartLocalService.ACTIVE_CART_ID);
  }

  reorderOrder(_1: number | ID, _2: string): Observable<Cart<T, R> | undefined> {
    return of(undefined);
  }

  printCart(_: T): Observable<any> {
    return of(undefined);
  }
}
