import { Inject, Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CATALOG_CONFIG, CatalogConfig, PageViewConfig } from '../config/catalog.config';
import { ArticleCount } from '../model/article-count.model';
import { Catalog } from '../model/catalog.model';
import { Category } from '../model/category.model';
import { Facet } from '../model/facet.model';
import { Product } from '../model/product.model';
import { SortByType } from '../model/sort-by.type';
import { ViewModeType } from '../model/view-mode.type';

export interface CachedHits<H, C> {
  id: string;
  totalHits: number;
  hits: H[];
  config?: C;
  queryFilters?: any;
}

export interface CategoryFacetedSearchState {
  category: Category | undefined;
  articleCount: ArticleCount[];
  facets: Facet[];
  hits: Product[];
  cachedHits: CachedHits<Product, PageViewConfig>[];
  pageIndex: number;
  pageSize: number;
  sortBy: SortByType;
  viewMode: ViewModeType;
  subLevelCategories: Catalog[];
  totalHits: number;
}

function createInitialState(catalogConfig: CatalogConfig): CategoryFacetedSearchState {
  return {
    category: undefined,
    articleCount: [],
    facets: [],
    hits: [],
    cachedHits: [],
    subLevelCategories: [],
    totalHits: 0,
    ...catalogConfig,
  };
}

@Injectable()
@StoreConfig({ name: 'category-faceted-search' })
export class CategoryFacetedSearchStore extends Store<CategoryFacetedSearchState> {
  constructor(@Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig) {
    super(createInitialState(catalogConfig));
  }
}
