import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CartItemLocalStore, CartItemLocalState } from '../store/cart-item-local.store';
import { CartItem } from '../model/cart-item.model';

@Injectable({ providedIn: 'root' })
export class CartItemLocalQuery<T extends CartItem> extends QueryEntity<CartItemLocalState<T>> {
  constructor(protected override store: CartItemLocalStore<T>) {
    super(store);
  }
}
