import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BlogPost } from '../model/blog-post';
import { CmsUiDynamicContentPage } from '../model/cms.models';
import { CmsUiDynamicContentState, CmsUiDynamicContentStore } from '../store/cms.store';

/**
 * Query object to query article entities
 */
@Injectable({
  providedIn: 'root',
})
export class CmsUiDynamicContentQuery extends QueryEntity<CmsUiDynamicContentState> {
  cmsMainMenu$ = this.select('cmsMainMenu').pipe(filter((data) => !!data));
  homePage$ = this.select('homePage').pipe(filter((data) => !!data));
  errorPage$ = this.select('errorPage').pipe(filter((data) => !!data));
  footer$ = this.select('footer').pipe(filter((data) => !!data));
  blogPosts$ = this.select('blogPosts').pipe(filter((data) => !!data));
  blogSearch$ = this.select('blogSearch').pipe(filter((data) => !!data));
  pageSearch$ = this.select('pageSearch').pipe(filter((data) => !!data));

  /**
   * Creates an instance of article query.
   * @param store the article store
   */
  constructor(protected override store: CmsUiDynamicContentStore) {
    super(store);
  }

  public page(id: number): Observable<CmsUiDynamicContentPage> {
    return this.select(`/cms/pages/${id}`).pipe(filter((data) => !!data));
  }

  public blog(id: number): Observable<BlogPost> {
    return this.select(`/cms/posts/${id}`).pipe(filter((data) => !!data));
  }
}
