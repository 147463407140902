import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { ContactState, ContactStore } from '../store/contact.store';
import { Contact } from '../model/contact.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'sFirstName', sortByOrder: Order.ASC })
export class ContactQuery<T extends Contact> extends QueryEntity<ContactState<T>> {
  constructor(protected override store: ContactStore<T>) {
    super(store);
  }
}
