import { Observable, of, OperatorFunction } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export function tapOnce<T>(tapFn: (t: T) => void, tapIndex = 0): OperatorFunction<T, T> {
  return (source$: Observable<T>) =>
    source$.pipe(
      concatMap((value: T, index: number) => {
        if (index === tapIndex) {
          tapFn(value);
        }
        return of(value);
      }),
    );
}
