import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { TicketState, TicketStore } from '../store/ticket.store';
import { TicketQuery } from '../query/ticket.query';
import { Ticket } from '../models/ticket.model';

@Injectable({ providedIn: 'root' })
export class TicketService<T extends Ticket> extends NgEntityService<TicketState<T>> {
  constructor(protected override store: TicketStore<T>, protected ticketQuery: TicketQuery<T>) {
    super(store);
  }
}
