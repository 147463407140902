import { Inject, Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ElasticSearchService, SearchResponse, SearchResponseHit, StringHelper, UrlHelperService } from '../../core';
import { Parameter, ParameterQuery } from '../../parameter';
import { Article } from '../model/article.model';
import { Product } from '../model/product.model';
import { ProductState, ProductStore } from '../store/product.store';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';

interface SearchResponseHits<T> {
  total: number;
  hits: T[];
}

@Injectable({ providedIn: 'root' })
export class ProductService extends NgEntityService<ProductState> {
  /**
   * Creates an instance of product service.
   */
  constructor(
    protected override store: ProductStore,
    protected elasticSearch: ElasticSearchService,
    protected parameterQuery: ParameterQuery,
    protected urlHelper: UrlHelperService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super(store);
  }

  public getById(id: string): Observable<Product> {
    const query = {
      query: {
        ids: {
          values: [id],
        },
      },
    };
    return this.executeAndMap(query);
  }

  public getByUrl(url: string): Observable<Product> {
    return this.parameterQuery.params$.pipe(
      map((params: Parameter) => {
        const catalog = StringHelper.trim('/' + this.urlHelper.normalize(params.sCatalogName), '/');
        const urlPath = StringHelper.trim(url.substring(3), '/');
        const path = `/${catalog}/${urlPath}/`;
        return {
          query: {
            bool: {
              filter: [
                {
                  term: {
                    'sUrlPath.keyword': {
                      value: path,
                    },
                  },
                },
              ],
            },
          },
        };
      }),
      switchMap((query) => this.executeAndMap(query)),
    );
  }

  protected executeAndMap(query: any): Observable<Product> {
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        map((response: SearchResponse<Product>) => this.parseHits(response?.hits)),
        filter((products: SearchResponseHits<Product>) => !!products?.hits),
        map((products: SearchResponseHits<Product>) => products?.hits[0]),
        tap((product: Product) => this.store.add(product)),
        shareReplay(),
      );
  }

  private parseHits(hits: any): SearchResponseHits<Product> {
    const products = hits?.hits.map((product: { _source: { oArticles: Article[] }; _score: string }) => {
      return { score: product._score, ...product._source };
    });
    return { total: hits?.total?.value, hits: products };
  }

  getProductsByIds(productIds: number[]): Observable<Product[]> {
    const query = {
      query: {
        bool: {
          must: [{ terms: { lngGroup: productIds } }],
        },
      },
    };
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        filter((response: SearchResponse<any>) => response.hits.hits.length > 0),
        map((response: SearchResponse<any>) => {
          return response.hits.hits.map((product: SearchResponseHit<any>) => product._source);
        }),
        tap((products: Product[]) => {
          this.store.upsertMany(products);
        }),
      );
  }
}
