import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SalesOfferStore, SalesOfferState } from '../store/sales-offer.store';
import { SalesOffer } from '../model/sales-offer.model';

@Injectable({ providedIn: 'root' })
export class SalesOfferQuery<T extends SalesOffer> extends QueryEntity<SalesOfferState<T>> {
  constructor(protected override store: SalesOfferStore<T>) {
    super(store);
  }
}
