import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { FavoriteItem } from '../model/favorite-item.model';
import { User } from '../../user';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FavoriteAdapterInterface } from '../model/favorite-adapter.interface';
import { FAVORITE_CONFIG, FavoriteConfig } from '../config/favorite.config';
import { FavoriteList } from '../model/favorite-list.model';

@Injectable({ providedIn: 'root' })
export class FavoriteHttpService<T extends FavoriteList, R extends FavoriteItem> implements FavoriteAdapterInterface<T, R> {
  constructor(private http: HttpClient, @Inject(FAVORITE_CONFIG) private favoriteConfig: FavoriteConfig) {}

  createFavoriteItem(favoriteItem: R, favoriteListId: ID): Observable<R> {
    return this.http.post<R>(this.favoriteConfig.apiUrl + '/favorites/' + favoriteListId + '/items', favoriteItem);
  }

  createFavoriteList(favoriteList: T): Observable<T> {
    return this.http.post<T>(this.favoriteConfig.apiUrl + '/favorites', favoriteList);
  }

  deleteFavoriteItem(favoriteItem: R): Observable<void> {
    return this.http.delete<void>(this.favoriteConfig.apiUrl + '/favorites/' + favoriteItem.gListID + '/items/' + favoriteItem.shtItemID);
  }

  deleteFavoriteList(favoriteList: T): Observable<void> {
    return this.http.delete<void>(this.favoriteConfig.apiUrl + '/favorites/' + favoriteList.gListID);
  }

  getFavoriteItems(favoriteListId: ID | string): Observable<R[]> {
    return this.http.get<R[]>(this.favoriteConfig.apiUrl + '/favorites/' + favoriteListId + '/items').pipe(
      map((favoriteItems: R[]) => {
        /**
         * The API returns an object instead of an empty array, when no items are in a list
         */
        if (!Array.isArray(favoriteItems)) {
          return [];
        }

        return favoriteItems;
      }),
    );
  }

  getFavoriteLists(): Observable<T[]> {
    return this.http.get<T[]>(this.favoriteConfig.apiUrl + '/favorites').pipe(
      map((favoriteLists: T[]) => {
        /**
         * The API returns an object instead of an empty array, when no list is saved yet
         */
        if (!Array.isArray(favoriteLists)) {
          return [];
        }

        return favoriteLists;
      }),
    );
  }

  getFavoriteListById(favoriteListId: ID): Observable<T> {
    return this.http.get<T>(this.favoriteConfig.apiUrl + '/favorites/' + favoriteListId);
  }

  updateFavoriteItem(favoriteItem: R): Observable<R> {
    return this.http.put<R>(
      this.favoriteConfig.apiUrl + '/favorites/' + favoriteItem.gListID + '/items/' + favoriteItem.shtItemID,
      favoriteItem,
    );
  }

  updateFavoriteList(favoriteList: T): Observable<T> {
    return this.http.put<T>(this.favoriteConfig.apiUrl + '/favorites/' + favoriteList.gListID, favoriteList);
  }

  setActiveFavorite(user?: User): Observable<ID | string | undefined> {
    return of(user?.gActiveBookmarkListID);
  }
}
