import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Catalog } from '../model/catalog.model';

export type CatalogState<T extends Catalog = Catalog> = EntityState<T>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'catalog', resettable: true })
export class CatalogStore<T extends Catalog = Catalog> extends EntityStore<CatalogState<T>> {
  constructor() {
    super();
  }
}
