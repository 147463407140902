import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PLAUSIBLE_CONFIG, PlausibleConfig } from './plausible.config';
import { PlausibleAdapter } from './plausible.adapter';
import { TrackingFactory } from '@lobos/library';

@NgModule({
  imports: [CommonModule],
})
export class PlausibleAdapterModule {
  constructor(private trackingFactory: TrackingFactory, private plausibleAdapter: PlausibleAdapter) {
    trackingFactory.register(plausibleAdapter);
  }

  static forRoot(config: PlausibleConfig): ModuleWithProviders<PlausibleAdapterModule> {
    return {
      ngModule: PlausibleAdapterModule,
      providers: [
        {
          provide: PLAUSIBLE_CONFIG,
          useValue: { ...config },
        },
      ],
    };
  }
}
