import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Hook, HookReturnType } from '../../base';
import { LaunchedHook } from '../models/app-hooks';

/**
 * Triggers the launched hook, as soon as Angular is ready.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LaunchedHookTriggerService {
  constructor() {
    of(true)
      .pipe(
        first(),
        switchMap((launched: boolean) => this.triggerLaunched(launched)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Hook<LaunchedHook, Observable<boolean>>({
    id: { type: 'LaunchedHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  private triggerLaunched(launched: boolean): Observable<boolean> {
    return of(launched);
  }
}
