import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CRUD_CONFIG, CrudConfig } from '../config/crud.config';
import { PaginationResponseInterface } from '../../core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CrudService {
  constructor(protected http: HttpClient, @Inject(CRUD_CONFIG) protected crudConfig: CrudConfig) {}

  getAll<T>(path: string, params?: { [key: string]: any }): Observable<T[]> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<PaginationResponseInterface<T>>(this.crudConfig.apiUrl + path, { params: httpParams }).pipe(map((x) => x.data));
  }

  getAllPaginated<T>(path: string, params?: { [key: string]: any }): Observable<PaginationResponseInterface<T>> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<PaginationResponseInterface<T>>(this.crudConfig.apiUrl + path, { params: httpParams });
  }

  getById<T>(path: string, id: string): Observable<T> {
    return this.http.get<T>(this.crudConfig.apiUrl + path + '/' + id);
  }

  create<T>(path: string, item: T): Observable<T> {
    return this.http.post<T>(this.crudConfig.apiUrl + path, item);
  }

  update<T>(path: string, item: Partial<T>, id: string): Observable<T> {
    return this.http.put<T>(this.crudConfig.apiUrl + path + '/' + id, item);
  }

  delete(path: string, id: string): Observable<any> {
    return this.http.delete(this.crudConfig.apiUrl + path + '/' + id);
  }
}
