import { Accessory } from '../model/article.model';

export function buildArticleAccessoriesQuery(oAccessories: Accessory[]) {
  return {
    size: oAccessories.length,
    query: {
      bool: {
        must: [{ terms: { 'sArticleID.keyword': oAccessories.map((accessory: Accessory) => accessory.sArticleID) } }],
      },
    },
  };
}
