import { ClassProvider, InjectionToken } from '@angular/core';

export const defaultLabelPrintConfig: LabelPrintConfig = {
  images: {
    categoryImage: 1,
    cloudinary: {
      active: false,
    },
  },
};

export interface LabelPrintConfig {
  images: {
    categoryImage: number;
    cloudinary: {
      active: boolean;
      cloudName?: string;
      fetchImageUrl?: string;
    };
  };
  logoPath?: string;
  mapper?: ClassProvider;
}

export const LABEL_PRINT_CONFIG = new InjectionToken<LabelPrintConfig>('labelPrintConfig');
