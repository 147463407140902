import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Article } from '../../catalog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackMethod } from '../../tracking';

interface CompareData<T extends Article> {
  items: T[];
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CompareService<T extends Article> {
  private data: CompareData<T> = {
    items: [],
  };
  private itemsSubject$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private onAddingSubject$: Subject<T> = new Subject();

  readonly items$: Observable<T[]> = this.itemsSubject$.pipe(untilDestroyed(this));
  readonly onAdding$: Observable<T> = this.onAddingSubject$.asObservable();

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.load();
    }
  }

  @TrackMethod({
    provide: () => ({
      name: 'compare_add',
      payload: {},
    }),
  })
  add(product: T): Observable<void> {
    // timer only for demo
    return of(undefined).pipe(
      map(() => {
        this.onAddingSubject$.next(product);

        const index = this.data.items.findIndex((item) => item.sArticleID === product.sArticleID);

        if (index === -1) {
          this.data.items.push(product);
          this.save();
        }
      }),
    );
  }

  remove(product: T): Observable<void> {
    // timer only for demo
    return of(undefined).pipe(
      map(() => {
        const index = this.data.items.findIndex((item) => item.sArticleID === product.sArticleID);

        if (index !== -1) {
          this.data.items.splice(index, 1);
          this.save();
        }
      }),
    );
  }

  removeAll() {
    this.data.items = [];
    this.save();
  }

  private save(): void {
    localStorage.setItem('compareItems', JSON.stringify(this.data.items));

    this.itemsSubject$.next(this.data.items);
  }

  private load(): void {
    const items = localStorage.getItem('compareItems');

    if (items) {
      this.data.items = JSON.parse(items);
      this.itemsSubject$.next(this.data.items);
    }
  }
}
