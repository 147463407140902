import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CartItemState, CartItemStore } from '../store/cart-item.store';
import { CartItem } from '../model/cart-item.model';

@Injectable({
  providedIn: 'root',
})
export class CartItemQuery<T extends CartItem> extends QueryEntity<CartItemState<T>> {
  constructor(protected override store: CartItemStore<T>) {
    super(store);
  }

  selectCartItems(cartId: number | ID): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ lngOrderID }) => lngOrderID === cartId,
    });
  }
}
