import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArticleService } from '../catalog';
import { SteelArticleService } from './services/steel-article.service';
import { CartService } from '../cart';
import { SteelCartService } from './services/steel-cart.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: ArticleService, useClass: SteelArticleService },
    {
      provide: CartService,
      useClass: SteelCartService,
    },
  ],
})
export class SteelModule {}
