import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { CatalogState, CatalogStore } from '../store/catalog.store';
import { Catalog } from '../model/catalog.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogQuery<T extends Catalog = Catalog> extends QueryEntity<CatalogState<T>> {
  selectCategoriesByParentGroupId$(parentGroupId: number | string) {
    return this.selectAll({
      filterBy: ({ lngParentGroup }) => lngParentGroup === parentGroupId,
      sortBy: (category1: Catalog, category2: Catalog) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category1.sTitle.toLowerCase().localeCompare(category2.sTitle.toLowerCase());
      },
    });
  }

  getCategoriesByParentGroupId(parentGroupId: number | string): T[] {
    return this.getAll({
      filterBy: ({ lngParentGroup }) => lngParentGroup === parentGroupId,
      sortBy: (category1: Catalog, category2: Catalog) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category2.sTitle.toLowerCase().localeCompare(category1.sTitle.toLowerCase());
      },
    });
  }

  getHasCategoriesByParentGroupId(parentGroupId: number | string): boolean {
    const categories = this.getAll({
      filterBy: ({ lngParentGroup }) => lngParentGroup === parentGroupId,
    });
    return !!(categories && categories.length);
  }

  selectCategoryByGroupId$(groupId: number | string): Observable<T> {
    return this.selectAll({
      filterBy: ({ lngGroup }) => lngGroup === groupId,
    }).pipe(map((groups) => groups[0]));
  }

  getHasCategoryByGroupId(groupId: number | string): boolean {
    return !!this.getAll({
      filterBy: ({ lngGroup }) => lngGroup === groupId,
    });
  }

  constructor(protected override store: CatalogStore<T>) {
    super(store);
  }
}
