import { Inject, Injectable } from '@angular/core';
import { CartHeader } from '../model/cart-header.model';
import { CartItem } from '../model/cart-item.model';
import { CreateCartItemInterface } from '../model/create-cart-item.interface';
import { CartAdapterInterface } from '../model/cart-adapter.interface';
import { Observable, of } from 'rxjs';
import { ID } from '@datorama/akita';
import { Cart } from '../model/cart.model';
import { User } from '../../user';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CART_CONFIG, CartConfig } from '../config/cart.config';

@Injectable({ providedIn: 'root' })
export class CartGuestService<T extends CartHeader, R extends CartItem, S extends CreateCartItemInterface>
  implements CartAdapterInterface<T, R, S>
{
  constructor(protected http: HttpClient, @Inject(CART_CONFIG) protected cartConfig: CartConfig) {}

  changeActiveCart(_: number | ID): Observable<Cart<T, R> | undefined> {
    return of(undefined);
  }

  createCartHeader(cartHeader: T): Observable<T> {
    return of(cartHeader);
  }

  createCartItem(cartItem: S, _: number | ID): Observable<Cart<T, R>> {
    return this.http.post<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/-1/items', cartItem).pipe(
      map((cart) => {
        const guestMaster = { ...cart.oSalesMaster, lngOrderID: -1 };
        const guestSalesItemList = cart.oSalesItemList.map((si) => {
          return { ...si, lngOrderID: -1 };
        });
        return { oSalesMaster: guestMaster, oSalesItemList: guestSalesItemList };
      }),
    );
  }

  deleteCart(_: number | ID): Observable<void> {
    return of(undefined);
  }

  deleteCartItem(cartItem: R): Observable<Cart<T, R>> {
    return this.http
      .delete<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartItem.lngOrderID + '/items/' + cartItem.shtFixedItemID)
      .pipe(
        map((cart) => {
          const guestMaster = { ...cart.oSalesMaster, lngOrderID: -1 };
          const guestSalesItemList = cart.oSalesItemList.map((si) => {
            return { ...si, lngOrderID: -1 };
          });
          return { oSalesMaster: guestMaster, oSalesItemList: guestSalesItemList };
        }),
      );
  }

  getCartById(id: number | ID): Observable<Cart<T, R>> {
    return this.http.get<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + id).pipe(
      map((cart) => {
        const guestMaster = { ...cart.oSalesMaster, lngOrderID: -1 };
        const guestSalesItemList = cart.oSalesItemList?.map((si) => {
          return { ...si, lngOrderID: -1 };
        });
        return { oSalesMaster: guestMaster, oSalesItemList: guestSalesItemList };
      }),
    );
  }

  getCartHeaders(): Observable<T[]> {
    return this.http.get<T[]>(this.cartConfig.apiUrl + '/so-carts').pipe(
      map((carts) => {
        return carts.map((cart) => {
          return { ...cart, lngOrderID: -1 };
        });
      }),
    );
  }

  setActiveId(_?: User): Observable<number | ID> {
    return of(-1);
  }

  submitCart(_: T): Observable<any> {
    return of(undefined);
  }

  updateCartHeader(cartHeader: T): Observable<Cart<T, R> | undefined> {
    return this.http
      .put<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartHeader.lngOrderID, {
        oSalesMaster: cartHeader,
        oSalesItemList: null,
      })
      .pipe(
        map((cart) => {
          const guestMaster = { ...cart.oSalesMaster, lngOrderID: -1 };
          const guestSalesItemList = cart.oSalesItemList.map((si) => {
            return { ...si, lngOrderID: -1 };
          });
          return { oSalesMaster: guestMaster, oSalesItemList: guestSalesItemList };
        }),
      );
  }

  updateCartItem(cartItem: R): Observable<Cart<T, R>> {
    return this.http.put<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/-1/items/' + cartItem.shtFixedItemID, cartItem).pipe(
      map((cart) => {
        const guestMaster = { ...cart.oSalesMaster, lngOrderID: -1 };
        const guestSalesItemList = cart.oSalesItemList.map((si) => {
          return { ...si, lngOrderID: -1 };
        });
        return { oSalesMaster: guestMaster, oSalesItemList: guestSalesItemList };
      }),
    );
  }

  printCart(cartHeader: T): Observable<any> {
    const acceptHeaders = new HttpHeaders();
    acceptHeaders.append('Accept', '*/*');
    acceptHeaders.append('Accept-Encoding', 'gzip, deflate, br');
    acceptHeaders.append('Connection', 'keep-alive');
    return this.http.get(
      this.cartConfig.apiUrl +
        `/sales-documents?sDocumentType=O&lngDocumentID=${cartHeader.lngOrderID}&sOrderType=${cartHeader.sOrderType}`,
      {
        headers: acceptHeaders,
        responseType: 'blob' as 'json',
      },
    );
  }
}
