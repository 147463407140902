import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { LocalSearchString } from '../model/local-search-string.model';
import { LocalSearchStringStore } from '../store/local-search-string.store';

@Injectable({ providedIn: 'root' })
export class LocalSearchStringService<T extends LocalSearchString> {
  constructor(private localSearchStringStore: LocalSearchStringStore<T>) {}

  add(localSearchString: T) {
    this.localSearchStringStore.add(localSearchString);
  }

  update(id: ID, localSearchString: Partial<T>) {
    this.localSearchStringStore.update(id, localSearchString);
  }

  remove(id: ID) {
    this.localSearchStringStore.remove(id);
  }
}
