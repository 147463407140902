import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '../model/category.model';
import { CategoryState, CategoryStore } from '../store/category.store';

@Injectable({
  providedIn: 'root',
})
export class CategoryQuery extends QueryEntity<CategoryState> {
  constructor(protected override store: CategoryStore) {
    super(store);
  }

  selectCategoriesByParentGroupId$(parentGroupId: number) {
    return this.selectAll({
      filterBy: ({ lngParentGroup }) => lngParentGroup === parentGroupId,
      sortBy: (category1: Category, category2: Category) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category1.sTitle.toLowerCase().localeCompare(category2.sTitle.toLowerCase());
      },
    });
  }

  selectCategoriesByParentUrlPath$(parentUrlPath: string) {
    return this.selectAll({
      filterBy: ({ sUrlPath }) => !!sUrlPath.match(`^${parentUrlPath}[^/]+/$`) && sUrlPath !== parentUrlPath,
      sortBy: (category1: Category, category2: Category) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category1.sTitle.toLowerCase().localeCompare(category2.sTitle.toLowerCase());
      },
    });
  }

  getHasCategoriesByParentGroupId(parentGroupId: number) {
    const categories = this.getAll({
      filterBy: ({ lngParentGroup }) => lngParentGroup === parentGroupId,
    });
    return !!(categories && categories.length);
  }

  public selectById(id: ID): Observable<Category> {
    return this.selectAll({
      filterBy: (category: Category) => category.Id === id,
    }).pipe(
      map((data) => {
        return data[0];
      }),
    );
  }
}
