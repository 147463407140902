import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { JsonLd } from '../model/json-ld.interface';

/**
 * Injects or updates a JSON-LD script tag
 *
 * @see https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
 * @see https://developers.google.com/search/docs/advanced/structured-data/product#json-ld
 * @see https://search.google.com/test/rich-results
 *
 * @example
 * This can be generated
 * {
 *   "@context": "https://schema.org/",
 *   "@type": "Product",
 *   "name": "Executive Anvil",
 *   "image": [
 *     "https://example.com/photos/1x1/photo.jpg",
 *     "https://example.com/photos/4x3/photo.jpg",
 *     "https://example.com/photos/16x9/photo.jpg"
 *   ],
 *   "description": "Sleeker than ACME's Classic Anvil, the Executive Anvil is perfect for the business traveler looking for something to drop from a height.",
 *   "sku": "0446310786",
 *   "mpn": "925872",
 *   "brand": {
 *     "@type": "Brand",
 *     "name": "ACME"
 *   },
 *   "review": {
 *     "@type": "Review",
 *     "reviewRating": {
 *       "@type": "Rating",
 *       "ratingValue": "4",
 *       "bestRating": "5"
 *     },
 *     "author": {
 *       "@type": "Person",
 *       "name": "Fred Benson"
 *     }
 *   },
 *   "aggregateRating": {
 *     "@type": "AggregateRating",
 *     "ratingValue": "4.4",
 *     "reviewCount": "89"
 *   },
 *   "offers": {
 *     "@type": "Offer",
 *     "url": "https://example.com/anvil",
 *     "priceCurrency": "USD",
 *     "price": "119.99",
 *     "priceValidUntil": "2020-11-20",
 *     "itemCondition": "https://schema.org/UsedCondition",
 *     "availability": "https://schema.org/InStock"
 *   }
 * }
 */
@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public set(data: JsonLd | JsonLd[]): void {
    this.inject(data);
  }

  public reset(): void {
    const ldJsonScriptTag: Element | null = this.document.head.querySelector(`script[type='application/ld+json']`);
    if (ldJsonScriptTag) {
      ldJsonScriptTag.remove();
    }
  }

  public getObject(type: string, rawData?: JsonLd, context = 'https://schema.org'): JsonLd {
    let data: JsonLd = {
      '@context': context,
      '@type': type,
    };
    if (rawData) {
      data = {
        ...data,
        ...rawData,
      };
    }
    return data;
  }

  private inject(data: JsonLd | JsonLd[]): void {
    let ldJsonScriptTag: Element | null = this.document.head.querySelector(`script[type='application/ld+json']`);
    if (ldJsonScriptTag) {
      ldJsonScriptTag.textContent = JSON.stringify(data);
    } else {
      ldJsonScriptTag = this.document.createElement('script');
      ldJsonScriptTag.setAttribute('type', 'application/ld+json');
      ldJsonScriptTag.textContent = JSON.stringify(data);
      this.document.head.appendChild(ldJsonScriptTag);
    }
  }
}
