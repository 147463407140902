import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { LinkTagDefinition } from '../model/link-tag.type';

// @dynamic
@Injectable({
  providedIn: 'root',
})
export class LinkTagService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  /**
   * Add a link tag without further checks
   */
  public add(linkTag: LinkTagDefinition): HTMLLinkElement {
    return this.createElement(linkTag);
  }

  /**
   * Creates or updates the canonical tag
   */
  public updateCanonicalTag(href: string): Element {
    return this.getOrCreateElement({ rel: 'canonical', href });
  }

  /**
   * Removes the canonical tag
   */
  public removeCanonicalTag(): Element | undefined {
    const element: Element | undefined = this.getSingleElement({ rel: 'canonical' });
    if (element) element.remove();
    return element;
  }

  private getSingleElement(linkTag: LinkTagDefinition): Element | undefined {
    return this.document.querySelector(`link[rel=${linkTag.rel}]`) ?? undefined;
  }

  private getOrCreateElement(linkTag: LinkTagDefinition): Element {
    const element: Element | undefined = this.getSingleElement(linkTag);
    if (element !== undefined) return element;

    return this.createElement(linkTag);
  }

  private createElement(linkTag: LinkTagDefinition): HTMLLinkElement {
    const element: HTMLLinkElement = this.document.head.appendChild(this.document.createElement('link'));
    this.setLinkElementAttributes(linkTag, element);
    return element;
  }

  private setLinkElementAttributes(linkTag: LinkTagDefinition, element: any): void {
    Object.keys(linkTag).forEach((prop: string) => {
      element[prop] = linkTag[prop];
    });
  }
}
