import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlHelperService } from '../url/url-helper.service';

@Pipe({
  name: 'localizeUrlPathAsync',
})
export class LocalizeUrlPathAsyncPipe implements PipeTransform {
  constructor(private urlHelper: UrlHelperService) {}

  public transform(path: string): Observable<string> {
    return this.urlHelper.localizeUrlAsync(path);
  }
}
