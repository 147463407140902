import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TicketPriority } from '../models/ticket-priority.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TicketPriorityState<T extends TicketPriority> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ticket-priorities', idKey: 'lngPriorityID' })
export class TicketPriorityStore<T extends TicketPriority> extends EntityStore<TicketPriorityState<T>> {
  constructor() {
    super();
  }
}
