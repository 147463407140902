import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CORE_CONFIG, CoreConfig } from '../../core.config';
import { StringHelper } from '../string/string.helper';

/**
 * A service for helping with common url operation.
 */
@Injectable({
  providedIn: 'root',
})
export class UrlHelperService {
  constructor(
    @Inject(CORE_CONFIG) private config: CoreConfig,
    private location: Location,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  public localizeUrl(url: string): string {
    const normalizedUrl: string = `/${StringHelper.trim(url, '/')}`;
    if (this.config.ignoreLanguageForUrls) {
      return normalizedUrl;
    }

    return `/${this.translocoService.getActiveLang()}${normalizedUrl}`;
  }

  public localizeUrlAsync(url: string): Observable<string> {
    const normalizedUrl: string = `/${StringHelper.trim(url, '/')}`;
    if (this.config.ignoreLanguageForUrls) {
      return of(normalizedUrl);
    }

    return this.translocoService.langChanges$.pipe(map((lang: string) => `/${lang}${normalizedUrl}`));
  }

  public normalize(path: string): string {
    path = path.toLowerCase();
    path = path.replace(/ä/g, 'ae');
    path = path.replace(/ö/g, 'oe');
    path = path.replace(/ü/g, 'ue');
    path = path.replace(/ß/g, 'ss');
    path = path.replace(/é/g, 'e');
    path = path.replace(/è/g, 'e');
    path = path.replace(/ê/g, 'e');
    path = path.replace(/â/g, 'a');
    path = path.replace(/à/g, 'a');
    path = path.replace(/[^a-z0-9-/]/gi, '-');
    path = path.replace(/-+/g, '-');
    return path;
  }

  public enforceAbsoluteUrl(url?: string): string {
    url = url ?? this.location.path();

    return url.match('^http') ? url : `${this.config.baseUrl}${url}`;
  }

  /**
   * Get current url path
   */
  public getPath(): string {
    return this.getPathWithoutParams();
  }

  /**
   * Get url query params as object
   */
  public getQueryParams(): Params {
    return this.router.parseUrl(this.location.path()).queryParams || {};
  }

  /**
   * Convert an object to query params
   */
  public objectToQueryParams(object = {}): string {
    return Object.entries(object)
      .map((p: [string, any]) => `${encodeURIComponent(p[0])}=${encodeURIComponent(Array.isArray(p[1]) ? p[1].join(';') : p[1])}`)
      .join('&');
  }

  /**
   * Get the url the Angular way and then strip of any params
   */
  private getPathWithoutParams(): string {
    const url: string = this.location.path();
    if (!url.match(/\?/)) {
      return url;
    }

    return url.substring(0, url.lastIndexOf('?'));
  }
}
