import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SALES_OFFER_CONFIG, SalesOfferConfig } from './config/sales-offer.config';

@NgModule({
  imports: [CommonModule],
})
export class SalesOfferModule {
  static forRoot(config: SalesOfferConfig): ModuleWithProviders<SalesOfferModule> {
    return {
      ngModule: SalesOfferModule,
      providers: [
        {
          provide: SALES_OFFER_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
