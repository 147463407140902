import { Injectable } from '@angular/core';
import { CountryStore } from '../store/country.store';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Country } from '../model/country.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountryService<T extends Country> {
  constructor(protected store: CountryStore<T>, protected http: HttpClient) {}

  get(): Observable<T[]> {
    return this.http.get<T[]>('p48apihost/countries').pipe(tap((countries: T[]) => this.store.set(countries)));
  }
}
