import { Inject, Injectable } from '@angular/core';
import { CartAdapterInterface } from '../model/cart-adapter.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ID } from '@datorama/akita';
import { User } from '../../user';
import { CartHeader } from '../model/cart-header.model';
import { CartItem } from '../model/cart-item.model';
import { CART_CONFIG, CartConfig } from '../config/cart.config';
import { Cart } from '../model/cart.model';
import { CreateCartItemInterface } from '../model/create-cart-item.interface';

@Injectable({ providedIn: 'root' })
export class CartHttpService<T extends CartHeader, R extends CartItem, S extends CreateCartItemInterface>
  implements CartAdapterInterface<T, R, S>
{
  constructor(protected http: HttpClient, @Inject(CART_CONFIG) protected cartConfig: CartConfig) {}

  getCartHeaders(): Observable<T[]> {
    return this.http.get<T[]>(this.cartConfig.apiUrl + '/so-carts');
  }

  getCartById(cartId: number | ID): Observable<Cart<T, R>> {
    return this.http.get<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartId);
  }

  createCartHeader(cartHeader: T): Observable<T> {
    return this.http.post<T>(this.cartConfig.apiUrl + '/so-carts/', cartHeader);
  }

  updateCartHeader(cartHeader: T): Observable<Cart<T, R>> {
    return this.http.put<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartHeader.lngOrderID, {
      oSalesMaster: cartHeader,
      oSalesItemList: null,
    });
  }

  deleteCart(cartId: number | ID): Observable<void> {
    return this.http.delete<void>(this.cartConfig.apiUrl + '/so-carts/' + cartId);
  }

  submitCart(header: T, _?: R[], __?: User, createOffer = false): Observable<T> {
    let params = new HttpParams();
    if (createOffer) params = params.set('submit-mode', 'offer');
    return this.http.put<T>(this.cartConfig.apiUrl + '/so-carts/' + header.lngOrderID + '/submit', {}, { params });
  }

  changeActiveCart(cartId: number | ID): Observable<Cart<T, R>> {
    return this.http.put<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartId + '/activate', {});
  }

  createCartItem(cartItem: S, cartId: number | ID): Observable<Cart<T, R>> {
    return this.http.post<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartId + '/items', cartItem);
  }

  updateCartItem(cartItem: R): Observable<Cart<T, R>> {
    return this.http.put<Cart<T, R>>(
      this.cartConfig.apiUrl + '/so-carts/' + cartItem.lngOrderID + '/items/' + cartItem.shtFixedItemID,
      cartItem,
    );
  }

  deleteCartItem(cartItem: R): Observable<Cart<T, R>> {
    return this.http.delete<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/' + cartItem.lngOrderID + '/items/' + cartItem.shtFixedItemID);
  }

  setActiveId(user?: User): Observable<number | ID> {
    return of(user ? user.lngActiveCartID : -1);
  }

  reorder(lngOrderID: number | ID, sOrderType: string): Observable<Cart<T, R>> {
    return this.http.post<Cart<T, R>>(this.cartConfig.apiUrl + '/so-carts/reorder', {
      lngOrderID,
      sOrderType,
    });
  }

  printCart(cartHeader: T): Observable<any> {
    const acceptHeaders = new HttpHeaders();
    acceptHeaders.append('Accept', '*/*');
    acceptHeaders.append('Accept-Encoding', 'gzip, deflate, br');
    acceptHeaders.append('Connection', 'keep-alive');
    return this.http.get(
      this.cartConfig.apiUrl +
        `/sales-documents?sDocumentType=O&lngDocumentID=${cartHeader.lngOrderID}&sOrderType=${cartHeader.sOrderType}`,
      {
        headers: acceptHeaders,
        responseType: 'blob' as 'json',
      },
    );
  }
}
