import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { SalesCreditNoteItem } from '../model/sales-credit-note-item.model';
import { SalesCreditNoteItemStore, SalesCreditNoteItemState } from '../store/sales-credit-note-item.store';

@Injectable({ providedIn: 'root' })
export class SalesCreditNoteItemQuery<T extends SalesCreditNoteItem> extends QueryEntity<SalesCreditNoteItemState<T>> {
  constructor(protected override store: SalesCreditNoteItemStore<T>) {
    super(store);
  }

  selectByCreditNoteId(id: number | ID): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ lngOrderID }) => lngOrderID === id,
    });
  }

  hasItemsByCreditNoteId(id: number | ID): boolean {
    return !!this.getAll({
      filterBy: ({ lngOrderID }) => lngOrderID === id,
    }).length;
  }
}
