import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LocalSearchString } from '../model/local-search-string.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LocalSearchStringState<T extends LocalSearchString> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'local-search-string' })
export class LocalSearchStringStore<T extends LocalSearchString> extends EntityStore<LocalSearchStringState<T>> {
  constructor() {
    super();
  }
}
