import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FilterState, FilterStore } from '../store/filter.store';

@Injectable({
  providedIn: 'root',
})
export class FilterQuery extends QueryEntity<FilterState, any> {
  constructor(protected override store: FilterStore) {
    super(store);
  }
}
