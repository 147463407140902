import { InjectionToken } from '@angular/core';

/**
 * Document module configuration
 */
export interface SalesDocumentConfig {
  apiUrl?: string;
  pageSizeOptions?: number[];
  useArchive?: boolean;
}

/** Creates a document module configuration injection token */
export const SALES_DOCUMENT_CONFIG = new InjectionToken<SalesDocumentConfig>('SalesDocumentConfig');
