import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_CONFIG, AuthConfig } from '../config/auth.config';
import { HttpEndpointsConstants } from '../constants/http-endpoints.constants';
import { AuthRegistrationInterface } from '../interfaces/auth-registration.interface';
import { AuthRequestInterface } from '../interfaces/auth-request.interface';
import { ResetPasswordInterface } from '../interfaces/auth-reset-password.interface';
import { AuthResponseInterface } from '../interfaces/auth-response.interface';
import { UnknownType } from '../interfaces/unknown.type';
import { User } from '../../user';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService<T extends User = User> {
  constructor(private http: HttpClient, @Inject(AUTH_CONFIG) private config: AuthConfig) {}

  public login(request: AuthRequestInterface): Observable<AuthResponseInterface<T>> {
    return this.http.post<AuthResponseInterface<T>>(`${this.config.apiUrl}/${HttpEndpointsConstants.login}`, request);
  }

  public refresh(): Observable<UnknownType> {
    return this.http.post(`${this.config.apiUrl}/${HttpEndpointsConstants.refresh}`, {});
  }

  public logout(): Observable<UnknownType> {
    return this.http.post(`${this.config.apiUrl}/${HttpEndpointsConstants.logout}`, {});
  }

  public register(registrationData: AuthRegistrationInterface): Observable<UnknownType> {
    return this.http.post(`${this.config.apiUrl}/${HttpEndpointsConstants.register}`, registrationData);
  }

  public checkVatRegNoExists(vatRegNo: string): Observable<UnknownType> {
    return this.http.get(`${this.config.apiUrl}/${HttpEndpointsConstants.register}/vat-reg-no-check/${vatRegNo}`);
  }

  public requestPassword(username: string): Observable<UnknownType> {
    return this.http.post(`${this.config.apiUrl}/${HttpEndpointsConstants.requestPassword}`, { sEmail: username });
  }

  public resetPassword(resetPasswordData: ResetPasswordInterface): Observable<UnknownType> {
    return this.http.post(`${this.config.apiUrl}/${HttpEndpointsConstants.resetPassword}`, resetPasswordData);
  }

  public changePassword(newPassword: string): Observable<UnknownType> {
    return this.http.post(`${this.config.apiUrl}/${HttpEndpointsConstants.changePassword}`, {
      sNewPassword: newPassword,
    });
  }
}
