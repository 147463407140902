import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ArticleAccessory } from '../model/article-accessories.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ArticleAccessoriesState extends EntityState<ArticleAccessory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'article-accessories', idKey: 'sArticleID' })
export class ArticleAccessoriesStore extends EntityStore<ArticleAccessoriesState> {
  constructor() {
    super();
  }
}
