import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SalesOrderItem } from '../model/sales-order-item.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesOrderItemState<T extends SalesOrderItem> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-order-item' })
export class SalesOrderItemStore<T extends SalesOrderItem> extends EntityStore<SalesOrderItemState<T>> {
  constructor() {
    super();
  }
}
