import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { defaultErrorConfig, ERROR_CONFIG, ErrorConfig } from './config/error.config';
import { ErrorHandlerService } from './services/error-handler.service';

@NgModule({
  imports: [CommonModule],
})
export class ErrorModule {
  static forRoot(config?: ErrorConfig): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [
        config?.disableErrorHandler ? [] : { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
          provide: ERROR_CONFIG,
          useValue: { ...defaultErrorConfig, ...config },
        },
      ],
    };
  }
}
