import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { LocalSearchStringState, LocalSearchStringStore } from '../store/local-search-string.store';
import { LocalSearchString } from '../model/local-search-string.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'entryDate', sortByOrder: Order.DESC })
export class LocalSearchStringQuery<T extends LocalSearchString> extends QueryEntity<LocalSearchStringState<T>> {
  constructor(protected override store: LocalSearchStringStore<T>) {
    super(store);
  }
}
