import { Injectable } from '@angular/core';
import { ElasticSearchSortInterface } from '../../../core/elasticsearch/model/elastic-search-sort.interface';
import { SortByType } from '../../model/sort-by.type';

type SortByElasticSearchMapping = { [key: SortByType]: ElasticSearchSortInterface[] };

@Injectable()
export abstract class FacetedSearchAbstract {
  protected defaultSort: SortByType = 'title';

  protected sortMapping: SortByElasticSearchMapping = {
    relevance: [],
    title: [{ 'sName.keyword': { order: 'asc' } }],
  };

  setDefaultSort(sort: SortByType): void {
    this.defaultSort = sort;
  }

  getDefaultSort(): SortByType {
    return this.defaultSort;
  }

  addSortMapping(key: SortByType, sort: ElasticSearchSortInterface[]): void {
    this.sortMapping[key] = sort;
  }

  getSortMapping(key: SortByType): ElasticSearchSortInterface[] {
    if (!this.sortMapping[key]) {
      return [];
    }

    return this.sortMapping[key];
  }
}
