import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ElasticSearchService, SearchResponse } from '../../core';
import { Article } from '../model/article.model';
import { ArticleStore } from '../store/article.store';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';

/**
 * A service to manage article entities
 */
@Injectable({ providedIn: 'root' })
export class ArticleService<T extends Article> {
  constructor(
    private store: ArticleStore<T>,
    private elasticSearch: ElasticSearchService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {}

  getArticleByEAN(sEAN: string, limit = 1): Observable<T[]> {
    const query = {
      size: limit,
      query: { terms: { sEAN: [sEAN] } },
    };
    return this.elasticSearch
      .execute<SearchResponse<T>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('article'),
        query: JSON.stringify(query),
      })
      .pipe(
        map((response: SearchResponse<T>) => {
          return response.hits?.hits?.map((esArticle) => esArticle._source) || [];
        }),
        tap((articles: T[]) => {
          this.store.upsertMany(articles);
        }),
      );
  }

  getArticleByID(sArticleID: string) {
    const query = {
      query: {
        bool: {
          filter: [
            {
              term: {
                'sArticleID.keyword': {
                  value: sArticleID,
                },
              },
            },
          ],
        },
      },
    };
    return this.elasticSearch
      .execute<SearchResponse<T>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('article'),
        query: JSON.stringify(query),
      })
      .pipe(
        tap((response: SearchResponse<T>) => {
          const oArticle = response.hits?.hits[0]?._source as T;
          if (oArticle) {
            this.store.upsert(sArticleID, oArticle);
          } else {
            //todo log error
          }
        }),
      );
  }

  getArticleReplaceBy(sArticleID: string): Observable<T[]> {
    const query = {
      query: {
        bool: {
          filter: [
            {
              term: {
                'sForerunner.keyword': {
                  value: sArticleID,
                },
              },
            },
          ],
        },
      },
    };
    return this.elasticSearch
      .execute<SearchResponse<T>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('article'),
        query: JSON.stringify(query),
      })
      .pipe(
        map((response: SearchResponse<T>) => response.hits?.hits?.map((esArticle) => esArticle._source) || []),
        tap((articles: T[]) => {
          if (articles) {
            this.store.upsertMany(articles);
          } else {
            //todo log error
          }
        }),
      );
  }

  getArticleByMultiMatchQuery(searchTerm: string, fields: string[], limit: number = 1) {
    const query = {
      query: {
        multi_match: {
          query: searchTerm,
          fields: fields,
        },
      },
      size: limit,
    };
    return this.elasticSearch
      .execute<SearchResponse<T>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('article'),
        query: JSON.stringify(query),
      })
      .pipe(
        map((response: SearchResponse<T>) => {
          return response.hits?.hits?.map((esArticle) => esArticle._source) || [];
        }),
        tap((articles: T[]) => {
          this.store.upsertMany(articles);
        }),
      );
  }

  getArticlesByIds(articleIds: string[]): Observable<T[]> {
    const query = {
      query: {
        bool: {
          must: [{ terms: { 'sArticleID.keyword': articleIds } }],
        },
      },
    };
    return this.elasticSearch
      .execute<SearchResponse<T>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('article'),
        query: JSON.stringify(query),
      })
      .pipe(
        filter((response: SearchResponse<T>) => {
          if (response?.hits?.hits && response.hits.hits.length > 0) {
            return true;
          }
          return false;
        }),
        map((response: SearchResponse<T>) => {
          return response.hits.hits.map((esArticle) => esArticle._source);
        }),
        tap((articles: T[]) => {
          this.store.upsertMany(articles);
        }),
      );
  }
}
