import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FavoriteList } from '../model/favorite-list.model';

export interface FavoriteListState<T extends FavoriteList> extends EntityState<T>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'favorites', idKey: 'gListID', resettable: true })
export class FavoriteListStore<T extends FavoriteList> extends EntityStore<FavoriteListState<T>> {
  constructor() {
    super();
  }
}
