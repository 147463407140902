import { inject, InjectionToken } from '@angular/core';
import { Paginator } from '@datorama/akita';
import { SalesOrderState } from '../store/sales-order.store';
import { SalesOrderQuery } from '../query/sales-order.query';
import { SalesOrder } from '../model/sales-order.model';

export const SALES_ORDER_PAGINATOR = new InjectionToken('SalesOrderPaginator', {
  providedIn: 'root',
  factory: () => {
    return new Paginator<SalesOrderState<SalesOrder>>(inject(SalesOrderQuery)).withControls().withRange();
  },
});
