import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ArticleAccessoriesState, ArticleAccessoriesStore } from '../store/article-accessories.store';

@Injectable({
  providedIn: 'root',
})
export class ArticleAccessoriesQuery extends QueryEntity<ArticleAccessoriesState> {
  constructor(protected override store: ArticleAccessoriesStore) {
    super(store);
  }
}
