import { SearchResponseHit, SearchResponseHits } from '../../core';
import { Article } from '../model/article.model';

export function buildSearchQuery(searchString: string) {
  return {
    multi_match: {
      query: searchString,
      fields: [
        'sArticleID^5',
        'sName^4',
        'sDescription^3',
        'sEAN^5',
        'oProductInfo.sTitle^2',
        'oProductInfo.sDescription^2',
        'oProductInfo.sDescriptionShort^2',
        'sCustomerArticleID^1',
        'sAlternativeArticleID^1',
        'sExternalArticleID^1',
        'sPurchOrderNumber^1',
        'oProductInfo.keywords^1',
        'sUniformNumber^3',
      ],
      fuzziness: 'AUTO',
      minimum_should_match: '66%',
    },
  };
}

export function buildCategorySearchQuery(searchString: string) {
  return {
    multi_match: {
      query: searchString,
      fields: ['sTitle^5', 'keywords^2'],
      fuzziness: 'AUTO',
      minimum_should_match: '66%',
    },
  };
}

export function buildMultiSearchQuery(searchString: string, size = 5, articleIndex: string, categoryIndex: string) {
  return [
    { index: articleIndex },
    {
      size: size,
      query: buildSearchQuery(searchString),
      highlight: {
        order: 'score',
        fields: {
          sArticleID: {},
          sName: {},
          sDescription: {},
          sEAN: {},
          'oProductInfo.sTitle': {},
          'oProductInfo.sDescription': {},
          'oProductInfo.sDescriptionShort': {},
          sCustomerArticleID: {},
          sAlternativeArticleID: {},
          sExternalArticleID: {},
          sPurchOrderNumber: {},
          'oProductInfo.keywords': {},
          sUniformNumber: {},
        },
      },
    },
    { index: categoryIndex },
    {
      size: size,
      query: buildCategorySearchQuery(searchString),
      highlight: {
        order: 'score',
        fields: {
          sTitle: {},
          keywords: {},
        },
      },
    },
  ];
}

export function parseHits(hits: SearchResponseHits<Article>) {
  const articles = hits.hits.map((article: SearchResponseHit<Article>) => {
    return { score: article._score, ...article._source };
  });
  return { total: hits.total.value, hits: articles };
}
