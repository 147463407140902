import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { filter, map, tap } from 'rxjs/operators';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { PasswordResetModalComponent } from './components/password-reset-modal/password-reset-modal.component';
import { fromEvent } from 'rxjs';
import { DomHelperService } from './services/dom/dom-helper.service';
import { register } from 'swiper/element/bundle';
import { PageMetaService } from '@lobos/library';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modal: SimpleModalService,
    private pageMetaService: PageMetaService, // call PageMetaService to ensure metadata is displayed correctly
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this._document.getElementById('appFavicon')!.setAttribute('href', `/assets/images/favicon_${environment.api.webshopId}.ico`);
    this._document.getElementById('appIcon')!.setAttribute('href', `/assets/images/webclip_${environment.api.webshopId}.jpeg`);
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        filter((params: ParamMap) => params.has('redirect') || params.has('reset-password') || params.has('request-password')),
        map((params: ParamMap) => {
          if (params.has('redirect')) {
            this.modal.addModal(LoginModalComponent).pipe(
              tap((result: string | null) => {
                if (result === 'success') {
                  this.router.navigate([decodeURIComponent(params.get('redirect')!)]);
                }
              }),
            );
          }
          if (params.has('reset-password')) {
            this.modal
              .addModal(PasswordResetModalComponent, {
                guiId: params.get('reset-password'),
              })
              .pipe(
                tap((result: string | null) => {
                  if (result === 'success') {
                    this.router.navigate([decodeURIComponent(params.get('redirect')!)]);
                  }
                }),
              );
          }
          if (params.has('request-password')) {
            this.modal.addModal(LoginModalComponent, {
              forgotPassword: true,
            });
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();

    if (isPlatformBrowser(this.platformId)) {
      DomHelperService.fallback100vh(this._document);
      DomHelperService.removeMobileNavHeight(this._document);

      fromEvent(window, 'resize')
        .pipe(
          tap(() => {
            DomHelperService.fallback100vh(this._document);
            DomHelperService.removeMobileNavHeight(this._document);
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  ngAfterViewInit(): void {
    register();
  }
}
