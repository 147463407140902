import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FavoriteItemLocalState, FavoriteItemLocalStore } from '../store/favorite-item-local.store';
import { FavoriteItem } from '../model/favorite-item.model';

@Injectable({ providedIn: 'root' })
export class FavoriteItemLocalQuery<T extends FavoriteItem> extends QueryEntity<FavoriteItemLocalState<T>> {
  constructor(protected override store: FavoriteItemLocalStore<T>) {
    super(store);
  }
}
