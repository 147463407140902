import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SalesRepCustomer } from '../model/sales-rep-customer.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesRepCustomerState<T extends SalesRepCustomer> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-rep-customers', idKey: 'lngCustomerID' })
export class SalesRepCustomerStore<T extends SalesRepCustomer> extends EntityStore<SalesRepCustomerState<T>> {
  constructor() {
    super();
  }
}
