import { Injectable } from '@angular/core';
import { Configuration } from '../model/configuration';
import { ConfigurationExtra } from '../model/configuration-extra';
import { ConfigurationStore } from '../store/configuration.store';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService<T extends ConfigurationExtra | void = void> {
  constructor(private store: ConfigurationStore<T>) {}

  public update(configuration: Configuration<T>): void {
    this.store.update((c: Configuration<T>) => ({ configuration: { ...c, ...configuration } }));
  }
}
