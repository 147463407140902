import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Project } from '../models/project.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProjectState<T extends Project> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'projects', idKey: 'lngProjectID' })
export class ProjectStore<T extends Project> extends EntityStore<ProjectState<T>> {
  constructor() {
    super();
  }
}
