import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { InjectorClass } from '../../util';
import { ConnectionHookTriggerService } from './services/connection-hook-trigger.service';

@NgModule({
  imports: [CommonModule],
})
export class ConnectionHookModule {
  constructor(injector: Injector) {
    InjectorClass.set(injector);
    injector.get<ConnectionHookTriggerService>(ConnectionHookTriggerService);
  }

  static forRoot(): ModuleWithProviders<ConnectionHookModule> {
    return {
      ngModule: ConnectionHookModule,
      providers: [],
    };
  }
}
