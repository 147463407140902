import { BacklogItem } from '../model/backlog-item.model';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BACKLOG_CONFIG, BacklogConfig } from '../config/backlog.config';
import { filter } from 'rxjs/operators';
import { PaginationResponseInterface, queryPaginated } from '../../core';

@Injectable({ providedIn: 'root' })
export class BacklogService<T extends BacklogItem> {
  constructor(protected http: HttpClient, @Inject(BACKLOG_CONFIG) protected backlogConfig: BacklogConfig) {}

  getAllBacklogItems(urlOrParams: string | object): Observable<PaginationResponseInterface<T>> {
    return queryPaginated<T>(this.http, this.backlogConfig.apiUrl + '/backlog', urlOrParams).pipe(filter((res: any) => res?.data));
  }
}
