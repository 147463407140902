import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SalesCreditNoteItem } from '../model/sales-credit-note-item.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesCreditNoteItemState<T extends SalesCreditNoteItem> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-credit-note-item' })
export class SalesCreditNoteItemStore<T extends SalesCreditNoteItem> extends EntityStore<SalesCreditNoteItemState<T>> {
  constructor() {
    super();
  }
}
