import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ErrorService } from '../../../../core';
import { GOOGLE_TAG_MANAGER_CONFIG, GoogleTagManagerConfig } from '../config/google-tag-manager.config';
import { GoogleTagManagerEvent } from '../models/google-tag-manager.event';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerLoaderService {
  public readonly loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(GOOGLE_TAG_MANAGER_CONFIG) private config: GoogleTagManagerConfig,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: any,
    private errorService: ErrorService,
  ) {}

  public pushTag(item: GoogleTagManagerEvent): Observable<void> {
    return this.addGtmToDom().pipe(
      filter((loaded: boolean) => loaded),
      map(() => this.pushOnDataLayer(item)),
      first(),
    );
  }

  private getDataLayer(): any[] {
    if (!this.isBrowser) {
      return [];
    }
    return ((window as any).dataLayer = (window as any).dataLayer || []);
  }

  private pushOnDataLayer(obj: GoogleTagManagerEvent): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private addGtmToDom(): Observable<boolean> {
    if (this.loaded$.value) {
      return this.loaded$;
    }

    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const gtmScript = this.document.createElement('script');
    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = this.applyGtmQueryParams('https://www.googletagmanager.com/gtm.js');
    gtmScript.addEventListener('load', () => this.loaded$.next(true));
    gtmScript.addEventListener('error', (error: Error) =>
      this.errorService.add({ label: 'GtmLoaderService.addGtmToDom()', ...error, error }),
    );
    this.document.head.insertBefore(gtmScript, this.document.head.firstChild);

    // stop here, if no SSR
    if (this.isBrowser) {
      return this.loaded$;
    }

    const iframe = this.document.createElement('iframe');
    iframe.setAttribute('src', this.applyGtmQueryParams('https://www.googletagmanager.com/ns.html'));
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const noscript = this.document.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(iframe);

    this.document.body.insertBefore(noscript, this.document.body.firstChild);

    return this.loaded$;
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter((k: string) => (this.config as any)[k])
        .map((k: string) => `${k}=${(this.config as any)[k]}`)
        .join('&')
    );
  }
}
