export function buildCategoriesByParentGroupIdQuery(parentId: number) {
  return {
    size: 10000,
    query: {
      bool: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  ['lngParentGroup']: {
                    value: parentId,
                  },
                },
              },
              {
                term: {
                  ['sType.keyword']: {
                    value: 'G',
                  },
                },
              },
            ],
          },
        },
      },
    },
  };
}

export function buildCategoriesByGroupIdQuery(groupId: number) {
  return {
    size: 10000,
    query: {
      bool: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  ['lngGroup']: {
                    value: groupId,
                  },
                },
              },
              {
                term: {
                  ['sType.keyword']: {
                    value: 'G',
                  },
                },
              },
            ],
          },
        },
      },
    },
  };
}

export function buildCategoryQueryByParentUrlPath(parentUrlPath: string) {
  return {
    size: 10000,
    query: {
      bool: {
        filter: {
          bool: {
            must: [
              {
                regexp: {
                  'sUrlPath.keyword': parentUrlPath + '([^/]+/)',
                },
              },
              {
                term: {
                  'sType.keyword': {
                    value: 'G',
                  },
                },
              },
            ],
            must_not: [
              {
                term: {
                  'sUrlPath.keyword': parentUrlPath,
                },
              },
            ],
          },
        },
      },
    },
  };
}
