import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SalesOrderStore, SalesOrderState } from '../store/sales-order.store';
import { SalesOrder } from '../model/sales-order.model';

@Injectable({ providedIn: 'root' })
export class SalesOrderQuery<T extends SalesOrder> extends QueryEntity<SalesOrderState<T>> {
  constructor(protected override store: SalesOrderStore<T>) {
    super(store);
  }
}
