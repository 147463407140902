import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorInterface } from '../../core';
import { ScannerAdapterInterface } from '../models/scanner-adapter.interface';

@Injectable()
export abstract class ScannerAdapterAbstract implements ScannerAdapterInterface {
  abstract isSupported(): Observable<boolean>;

  abstract askForPermission(): Observable<boolean>;

  abstract start(videoContainer: HTMLDivElement, camera?: MediaDeviceInfo): Observable<string>;

  abstract ready(): Observable<boolean>;

  abstract stop(): void;

  abstract handleError(error: Error, display?: boolean | string, ignore?: boolean): ErrorInterface;
}
