import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { UnknownType } from '../../../auth/interfaces/unknown.type';

// TODO replace with akita persist by locastorage
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private static readonly STORAGE_NAME: string = 'lobos-webapp';

  private localForage: LocalForage = localForage;

  private ready = false;

  public async set<T>(key: string, value: UnknownType): Promise<UnknownType> {
    await this.init();
    return this.localForage.setItem<T>(key, value);
  }

  public async get<T>(key: string): Promise<T | null> {
    await this.init();
    return this.localForage.getItem<T>(key);
  }

  public async remove(key: string): Promise<void> {
    await this.init();
    return this.localForage.removeItem(key);
  }

  private async init(): Promise<void> {
    if (this.ready) {
      return;
    }
    this.ready = true;

    // eslint-disable-next-line angular/module-getter
    this.localForage.config({
      name: StorageService.STORAGE_NAME,
      storeName: StorageService.STORAGE_NAME,
    } as LocalForageOptions);
  }
}
