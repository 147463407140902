import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ID } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class PriceListService {
  constructor(protected http: HttpClient) {}

  post(ecommGroups: ID[]): Observable<any> {
    return this.http.post<any>('p48apihost/pricelist', { ecommgroups: ecommGroups });
  }
}
