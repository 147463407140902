import { Injectable } from '@angular/core';
import { ImageCompression, ImageProperties, jsPDF, jsPDFOptions, TextOptionsLight } from 'jspdf';
import { Dimension } from '../model/dimension';
import { OrientationType } from '../model/orientation.type';
import { openSansBold } from './fonts/OpenSans-bold';
import { openSansNormal } from './fonts/OpenSans-normal';
import { LogService } from '@lobos/library';

@Injectable({
  providedIn: 'root',
})
export class JspdfService {
  private static readonly PX2MM: number = 0.264583;

  private doc: jsPDF | undefined;
  private config: jsPDFOptions | undefined;

  constructor(private log: LogService) {}

  public startDocument(
    orientation: OrientationType,
    format: Dimension,
    config: jsPDFOptions = {
      unit: 'mm',
      putOnlyUsedFonts: true,
      floatPrecision: 'smart',
    },
  ): jsPDF {
    this.config = {
      ...config,
      format: [format.height, format.width],
      orientation,
    };
    this.doc = new jsPDF(this.config);

    this.doc!.addFileToVFS('OpenSans-normal.ttf', openSansNormal);
    this.doc!.addFont('OpenSans-normal.ttf', 'OpenSans', 'normal');

    this.doc!.addFileToVFS('OpenSans-bold.ttf', openSansBold);
    this.doc!.addFont('OpenSans-bold.ttf', 'OpenSans', 'bold');

    this.doc!.setFont('OpenSans');

    return this.doc;
  }

  public addPage(): void {
    this.doc!.addPage(this.config!.format, this.config!.orientation);
  }

  /**
   * The border is drawn as a cross
   */
  public drawBorder(x: number, y: number, width: number, height: number, lineLength = 6, lineWidth = 0.1): void {
    this.doc!.setLineWidth(lineWidth);
    const lineLengthVertically: number = Math.min(lineLength, height);
    const lineLengthHorizontally: number = Math.min(lineLength, width);

    // top left
    this.doc!.line(x, y, x, y + lineLengthVertically);
    this.doc!.line(x, y, x + lineLengthHorizontally, y);

    // top right
    this.doc!.line(x + width, y, x + width, y + lineLengthVertically);
    this.doc!.line(x + width - lineLengthHorizontally, y, x + width, y);

    // bottom left
    this.doc!.line(x, y + height - lineLengthVertically, x, y + height);
    this.doc!.line(x, y + height, x + lineLengthHorizontally, y + height);

    // bottom right
    this.doc!.line(x + width, y + height - lineLengthVertically, x + width, y + height);
    this.doc!.line(x + width - lineLengthHorizontally, y + height, x + width, y + height);
  }

  public drawText(
    text: string,
    x: number,
    y: number,
    maxWidth = 0,
    fontSize = 10,
    maxLines = 2,
    fontWeight: 'normal' | 'bold' = 'normal',
    textOptions: TextOptionsLight = {},
    strikeThrough = false,
  ): void {
    this.doc!.setFontSize(fontSize);
    this.doc!.setFont('OpenSans', fontWeight);

    const splitText: string[] = this.doc!.splitTextToSize(text, maxWidth).slice(0, maxLines);

    this.doc!.text(splitText, x, y, { baseline: 'top', ...textOptions });

    if (strikeThrough) {
      if (splitText.length !== 1) {
        this.log.warn('`strikeThrough` is only working with single line text');
      }

      const dimensions = this.getTextDimensions(splitText[0]);
      this.doc!.setLineWidth(0.2);
      this.doc!.line(
        x,
        y + (dimensions.h / 2) * (textOptions.baseline === 'bottom' ? -1 : 1),
        x + this.getTextDimensions(splitText[0]).w * (textOptions.align === 'right' ? -1 : 1),
        y + (dimensions.h / 2) * (textOptions.baseline === 'bottom' ? -1 : 1),
      );
    }
  }

  public getTextDimensions(text: string) {
    return this.doc!.getTextDimensions(text);
  }

  /**
   * Scales, centers and draws a image into the given dimensions
   */
  public async drawImage(
    path: string,
    x: number,
    y: number,
    maxWidth = 0,
    maxHeight = 0,
    compression: ImageCompression = 'FAST',
    align: 'center' | 'right' = 'center',
  ): Promise<void> {
    return new Promise<void>((success, failed) => {
      const image: HTMLImageElement = new Image();
      image.onload = () => {
        const imgProps: ImageProperties = this.doc!.getImageProperties(image);

        let imageWidth = this.px2mm(imgProps.width);
        let imageHeight = this.px2mm(imgProps.height);

        const widthScaleFactor = imageWidth / maxWidth;
        const heightScaleFactor = imageHeight / maxHeight;

        // scale by height or by width
        if (heightScaleFactor > widthScaleFactor) {
          imageWidth = imageWidth / heightScaleFactor;
          imageHeight = imageHeight / heightScaleFactor;
        } else {
          imageWidth = imageWidth / widthScaleFactor;
          imageHeight = imageHeight / widthScaleFactor;
        }

        let centerX: number;
        let centerY: number;
        if (align === 'right') {
          centerX = x + maxWidth - imageWidth;
          centerY = y;
        } else {
          centerX = x + maxWidth / 2 - imageWidth / 2;
          centerY = y + maxHeight / 2 - imageHeight / 2;
        }

        this.doc!.addImage(image, centerX, centerY, imageWidth, imageHeight, path, compression);
        success();
      };
      image.onerror = (error) => failed(error);
      image.src = path;
    });
  }

  public save(filename: string) {
    this.doc!.save(filename);
  }

  public getUrl(): URL {
    return this.doc!.output('bloburl');
  }

  private px2mm(px: number): number {
    return px * JspdfService.PX2MM;
  }
}
