import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ErrorService, InjectorClass } from '../../core';
import { EventTracking } from '../models/event-tracking';
import { TrackingFactory } from '../services/tracking.factory';

interface DecoratorOptions<T, R extends EventTracking> {
  initializer?: 'ngOnInit' | 'ngOnChanges';
  provide: (caller: T) => Observable<R>;
}

/**
 * Event tracking for page based events.
 */
export function TrackClass<T, R extends EventTracking>(options: DecoratorOptions<T, R>): ClassDecorator {
  return (constructor: any): void => {
    /**
     * Subject to manage unsubscription
     */
    let destroyed: Subject<void> = new Subject<void>();

    const initializer: 'ngOnInit' | 'ngOnChanges' = options.initializer || 'ngOnInit';
    const originalPageEnterEvent: () => any = constructor.prototype[initializer];
    const originalPageExitEvent: () => any = constructor.prototype.ngOnDestroy;

    constructor.prototype[initializer] = function (...args: any[]): void {
      destroyed = new Subject<void>();
      const tracking: TrackingFactory | undefined = InjectorClass.inject<TrackingFactory>(TrackingFactory);
      const errorService: ErrorService | undefined = InjectorClass.inject<ErrorService>(ErrorService);

      if (!tracking || !errorService) {
        return;
      }

      options
        .provide(this)
        .pipe(
          takeUntil(destroyed),
          catchError((error: Error) =>
            throwError(() =>
              errorService.add({
                label: 'TrackClass.ngOnInit()',
                ...error,
                error: error,
              }),
            ),
          ),
        )
        .subscribe((event: R) => tracking.trackEvent<R>(event));

      originalPageEnterEvent && typeof originalPageEnterEvent === 'function' && originalPageEnterEvent.apply(this, args as any);
    };

    constructor.prototype.ngOnDestroy = function (...args: any[]): void {
      destroyed.next();
      destroyed.complete();

      originalPageExitEvent && typeof originalPageExitEvent === 'function' && originalPageExitEvent.apply(this, args as any);
    };
  };
}
