import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AUTH_CONFIG, AuthConfig } from './config/auth.config';
import { BearerTokenAuthInterceptor } from './interceptors/bearer-token.interceptor';
import { HasAccessPipe } from './pipes/has-access.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HasAccessPipe],
  exports: [HasAccessPipe],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: BearerTokenAuthInterceptor, multi: true }],
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
