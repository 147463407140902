import { Inject, Injectable } from '@angular/core';
import { SalesOrderStore } from '../store/sales-order.store';
import { SalesOrder } from '../model/sales-order.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec, PaginationResponseInterface, queryPaginated } from '../../core';
import { filter, finalize, tap } from 'rxjs/operators';
import { SALES_ORDER_CONFIG, SalesOrderConfig } from '../config/sales-order.config';

/**
 * A service that manages order entities
 */
@Injectable({ providedIn: 'root' })
export class SalesOrderService<T extends SalesOrder> {
  constructor(private http: HttpClient, private store: SalesOrderStore<T>, @Inject(SALES_ORDER_CONFIG) private config: SalesOrderConfig) {}

  getByFilters(filters: any[]) {
    this.store.setLoading(true);
    let params = new HttpParams({ encoder: new CustomHttpParameterCodec() });
    filters.forEach((filter) => {
      params = params.append(filter.id, filter.value);
    });
    return this.http.get(this.config.apiUrl + '/sales-orders', { params }).pipe(
      filter((res: any) => res.data),
      tap((res: any) => {
        this.store.upsertMany(res.data);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  get(urlOrParams: string | object): Observable<PaginationResponseInterface<T>> {
    return queryPaginated<T>(this.http, this.config.apiUrl + '/sales-orders', urlOrParams).pipe(
      tap((response: PaginationResponseInterface<T>) => this.store.upsertMany(response.data)),
    );
  }

  getById(lngOrderId: number) {
    return this.http
      .get<T>(this.config.apiUrl + '/sales-orders/' + lngOrderId)
      .pipe(tap((salesOrder: T) => this.store.upsert(salesOrder.lngOrderID, salesOrder)));
  }
}
