import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { FavoriteItem } from '../model/favorite-item.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FavoriteItemState<T extends FavoriteItem> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'favorite-item', idKey: 'fakeId', resettable: true })
export class FavoriteItemStore<T extends FavoriteItem> extends EntityStore<FavoriteItemState<T>> {
  constructor() {
    super();
  }

  override akitaPreAddEntity(x: Readonly<T>): T {
    return {
      ...x,
      fakeId: [x.gListID, x.shtItemID].join(','),
    };
  }
}
