import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec, RequestDebounceService } from '../../core';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';
import { Price } from '../model/price.model';
import { PriceQuery } from '../query/price.query';
import { PriceStore } from '../store/price.store';

@Injectable({ providedIn: 'root' })
export class PriceService<T extends Price = Price> {
  constructor(
    protected store: PriceStore<T>,
    private http: HttpClient,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
    private query: PriceQuery<T>,
    private debouncer: RequestDebounceService,
  ) {}

  get(articleId: ID | string, unit: string, currency: string, qty = 0, factor1 = 0, factor2 = 0, factor3 = 0): Observable<T | undefined> {
    const params = new HttpParams({ encoder: new CustomHttpParameterCodec() })
      .set('articleId', articleId.toString())
      .set('unit', unit)
      .set('currency', currency)
      .set('qty', qty.toString())
      .set('factor1', factor1.toString())
      .set('factor2', factor2.toString())
      .set('factor3', factor3.toString());

    return this.debouncer.debounce<T, PriceStore<T>, PriceQuery<T>>(
      articleId,
      this.store,
      this.query,
      this.http.get<T>(this.catalogConfig.apiUrl + `/price`, { params: params }),
    );
  }
}
