import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FileTree } from '../models/file-tree.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FileTreeState<T extends FileTree> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'download-tree', idKey: 'sName', resettable: true })
export class FileTreeStore<T extends FileTree> extends EntityStore<FileTreeState<T>> {
  constructor() {
    super();
  }
}
