import { FocusKeyManager } from '@angular/cdk/a11y';
import { AfterViewInit, ContentChildren, Directive, HostBinding, HostListener, Input, QueryList } from '@angular/core';
import { FocusListOptionsInterface } from './focus-list-options.interface';
import { FocusableDirective } from './focusable.directive';

/**
 * Example:
 *
 * ```
 * <ul [focusList]={focusFirst: true, withTypeAhead: true}>
 *   <li><a focusable href="#">1. Link</a></li>
 *   <li><a focusable href="#">2. Link</a></li>
 *   <li><a focusable href="#">3. Link</a></li>
 *   <li><a focusable href="#">4. Link</a></li>
 *   <li><a focusable href="#">5. Link</a></li>
 * </ul>
 * ```
 *
 * @see https://indepth.dev/posts/1147/doing-a11y-easily-with-angular-cdk-keyboard-navigable-lists
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusList]',
})
export class FocusListDirective implements AfterViewInit {
  private options: FocusListOptionsInterface = {};
  private keyManager: FocusKeyManager<any> | undefined;

  @Input() set focusList(options: FocusListOptionsInterface) {
    this.options = { ...this.options, ...options };
  }

  @ContentChildren(FocusableDirective, { descendants: true })
  private items!: QueryList<FocusableDirective>;

  @HostBinding('tabindex') tabindex = 0;
  @HostBinding('role') role = 'list';

  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent) {
    if (!this.keyManager) {
      return;
    }
    this.keyManager.onKeydown(event);
  }

  public ngAfterViewInit(): void {
    if (!this.items) {
      return;
    }

    this.keyManager = new FocusKeyManager(this.items).withWrap();
    if (this.options.withTypeAhead) {
      this.keyManager.withTypeAhead();
    }
    if (this.options.focusFirst) {
      this.keyManager.setFirstItemActive();
    }
  }
}
