import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Parameter } from '../model/parameter.model';

export interface ParameterState {
  params: Parameter;
}

function createInitialState(): ParameterState {
  return {} as ParameterState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'parameters' })
export class ParameterStore extends Store<ParameterState> {
  constructor() {
    super(createInitialState());
  }
}
