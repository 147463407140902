import { Injectable } from '@angular/core';
import { ID, Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { PriceState, PriceStore } from '../store/price.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Price } from '../model/price.model';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'sArticleID',
  sortByOrder: Order.ASC, // Order.DESC
})
export class PriceQuery<T extends Price = Price> extends QueryEntity<PriceState<T>> {
  constructor(protected override store: PriceStore<T>) {
    super(store);
  }

  selectHasEntity(id: string | ID): Observable<boolean> {
    return this.selectEntity(id).pipe(map((entity) => !!entity));
  }
}
