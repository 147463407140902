import { Inject, Injectable } from '@angular/core';
import { SalesOfferStore } from '../store/sales-offer.store';
import { SalesOffer } from '../model/sales-offer.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec, PaginationResponseInterface, queryPaginated } from '../../core';
import { filter, finalize, tap } from 'rxjs/operators';
import { SALES_OFFER_CONFIG, SalesOfferConfig } from '../config/sales-offer.config';

/**
 * A service that manages offer entities
 */
@Injectable({ providedIn: 'root' })
export class SalesOfferService<T extends SalesOffer> {
  constructor(private http: HttpClient, private store: SalesOfferStore<T>, @Inject(SALES_OFFER_CONFIG) private config: SalesOfferConfig) {}

  getByFilters(filters: any[]) {
    this.store.setLoading(true);
    let params = new HttpParams({ encoder: new CustomHttpParameterCodec() });
    filters.forEach((filter) => {
      params = params.append(filter.id, filter.value);
    });
    return this.http.get(this.config.apiUrl + '/sales-offers', { params }).pipe(
      filter((res: any) => res.data),
      tap((res: any) => {
        this.store.upsertMany(res.data);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  get(urlOrParams: string | object): Observable<PaginationResponseInterface<T>> {
    return queryPaginated<T>(this.http, this.config.apiUrl + '/sales-offers', urlOrParams).pipe(
      tap((response: PaginationResponseInterface<T>) => this.store.upsertMany(response.data)),
    );
  }

  getById(lngOrderId: number) {
    return this.http
      .get<T>(this.config.apiUrl + '/sales-offers/' + lngOrderId)
      .pipe(tap((salesOrder: T) => this.store.upsert(salesOrder.lngOrderID, salesOrder)));
  }
}
