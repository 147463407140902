import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SalesOfferItem } from '../model/sales-offer-item.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesOfferItemState<T extends SalesOfferItem> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-offer-item' })
export class SalesOfferItemStore<T extends SalesOfferItem> extends EntityStore<SalesOfferItemState<T>> {
  constructor() {
    super();
  }
}
