import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ConnectionService, ConnectionStatusType } from '../../connection';
import { InjectorClass } from '../../util';
import { ErrorFilterInterface } from '../models/error-filter.interface';
import { ErrorIgnoreInterface, ErrorInterface } from '../models/error.interface';

export class OfflineErrorFilter implements ErrorFilterInterface {
  filter(error: ErrorInterface): Observable<ErrorInterface> {
    const connection: ConnectionService | undefined = InjectorClass.inject<ConnectionService>(ConnectionService);
    if (!connection) {
      console.warn('Could not get connection status.');
      return of(error);
    }

    return connection.getStatus().pipe(
      first(),
      map((status: ConnectionStatusType) =>
        status !== ConnectionStatusType.ONLINE
          ? {
              ...error,
              ignore: { reasons: [...((error.ignore as ErrorIgnoreInterface)?.reasons ?? []), `OfflineError`] },
            }
          : error,
      ),
    );
  }
}
