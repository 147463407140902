import { Inject, Injectable } from '@angular/core';
import {
  Article,
  CloudinaryConfig,
  FavoriteItem,
  FavoriteList,
  FavoriteService,
  ImagePathHelperService,
  PriceInfo,
  Product,
} from '@lobos/library';
import { TranslocoDatePipe, TranslocoDecimalPipe } from '@ngneat/transloco-locale';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LABEL_PRINT_CONFIG, LabelPrintConfig } from '../../config/label-print.config';
import { Label } from '../../model/label';
import { LabelMapperInterface } from '../../model/label-mapper.interface';
import { MapperAbstract } from './mapper.abstract';

@Injectable()
export class FavoriteMapper extends MapperAbstract implements LabelMapperInterface {
  constructor(
    @Inject(LABEL_PRINT_CONFIG) private config: LabelPrintConfig,
    private date: TranslocoDatePipe,
    private decimal: TranslocoDecimalPipe,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
  ) {
    super();
  }

  public filename(id: string): Observable<string> {
    return this.favoriteService.getFavoriteListById(id).pipe(map((list: FavoriteList | undefined) => list!.sListname || 'print'));
  }

  public map(id: string): Observable<Label[]> {
    return this.favoriteService
      .getFavoriteItems(id)
      .pipe(map((favorites: FavoriteItem[]) => favorites.map((favorite: FavoriteItem) => this.mapFavoriteToLabel(favorite))));
  }

  private mapFavoriteToLabel(favorite: FavoriteItem): Label {
    const article: Article = favorite.oArticle!;
    const priceInfo: PriceInfo | undefined = article.oPriceInfo;
    const price = this.decimal.transform(priceInfo?.decSpecialPriceNet || priceInfo?.decBasePriceNet || 0);
    const discountedFrom = priceInfo?.decSpecialPriceNet ? this.decimal.transform(priceInfo.decBasePriceNet) : undefined;

    return {
      data: {
        code: article.sEAN,
        sku: article.sArticleID.toString(),
        title: article.sName,
        description: article.sDescription,
        price,
        discountedFrom,
        date: this.date.transform(Date.now()),
        unit: `${priceInfo?.lngSalesPriceUnit} ${priceInfo?.sQuantityUnit}`,
        currency: priceInfo?.sCurrencyCode,
        logoPath: this.config.logoPath,
        imgPath: this.getProductImagePath(article.oProductInfo[0]),
      },
      amount: 1,
      print: false,
      type: 'data',
    };
  }

  private getProductImagePath(product: Product) {
    const imageRessource = product?.oResources.find((res) => res.shtResourceGroupIDInternal === this.config.images.categoryImage);
    return ImagePathHelperService.getImagePath(imageRessource!.sResourcePath, this.config.images.cloudinary as CloudinaryConfig);
  }
}
