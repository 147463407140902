import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationResponseInterface } from './pagination-response.interface';

export function queryPaginated<T>(http: HttpClient, baseUrl: string, urlOrFilter?: any): Observable<PaginationResponseInterface<T>> {
  let params = new HttpParams();
  let url = baseUrl;

  if (typeof urlOrFilter === 'string') {
    // we were given a page URL, use it
    url = urlOrFilter;
  } else if (typeof urlOrFilter === 'object') {
    // we were given filtering criteria, build the query string
    Object.keys(urlOrFilter)
      .sort()
      .forEach((key: string) => {
        const value = urlOrFilter[key];
        if (value !== null) {
          params = params.set(key, value.toString());
        }
      });
  }

  return http.get<PaginationResponseInterface<T>>(url, {
    params: params,
  });
}
