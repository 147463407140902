import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SalesOrder } from '../model/sales-order.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesOrderState<T extends SalesOrder> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-orders', idKey: 'lngOrderID', resettable: true })
export class SalesOrderStore<T extends SalesOrder> extends EntityStore<SalesOrderState<T>> {
  constructor() {
    super();
  }
}
