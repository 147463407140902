import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PLAUSIBLE_CONFIG, PlausibleConfig } from './plausible.config';

@Injectable({ providedIn: 'root' })
export class PlausibleLoaderService {
  isLoaded = false;
  document = inject(DOCUMENT);
  config: PlausibleConfig = inject(PLAUSIBLE_CONFIG);
  platform = inject(PLATFORM_ID);

  addPlausibleToDom(): void {
    if (!isPlatformBrowser(this.platform) || this.isLoaded) {
      return;
    }

    const script = this.document.createElement('script');
    script.defer = true;
    script.setAttribute('data-domain', this.config.id);
    script.src = 'https://plausible.io/js/script.revenue.js'; // for testing and local development use 'https://plausible.io/js/script.local.js'
    this.document.head.insertBefore(script, this.document.head.firstChild);
    this.isLoaded = true;
  }
}
