import { ID } from '@datorama/akita';
import { ShippingAddress } from '../../shipping-address';
import { ShippingType } from './shipping-type.model';
import { Contact } from '../../contact';
import { TaxSplitting } from './tax-splitting.model';

export interface CartHeader {
  bCollectiveInvoice: boolean;
  decTotalOrderGrossAmount: number;
  decTotalOrderNetAmount: number;
  decTotalOrderTax: number;
  dtDeliveryDate: number;
  decPostage: number;
  dtEntryDate: number;
  dtOrderDate: number;
  lngCampaignID: number;
  lngContactID: number | ID;
  oContact: Contact;
  sIContact: string;
  lngCustomerID: number;
  lngOrderID: number | ID;
  lngPayConnectionID: number;
  lngProjectID: number;
  oDeliveryAddress: ShippingAddress;
  oInvoiceAddress: ShippingAddress;
  oShippingCondition: ShippingType;
  oTaxSplittingList: TaxSplitting[];
  sCartName: string;
  sContactName: string;
  sMemo: string;
  sShippingMemo: string;
  sOrderKind: string;
  sOrderText: string;
  sOrderType: string;
  sPaymentID: string;
  sPaymentIDType: string;
  shtBlocked: number;
  shtOfferStatus: number;
  shtPaymentCondition: number;
  shtShippingCondition: number | ID;
  shtStatus: number;
}

export function createCartHeader<T extends CartHeader>({ lngCustomerID = 0, lngContactID = 0, sCartName = 'Cart', sIContact = '' }) {
  return {
    lngCustomerID,
    lngContactID,
    sCartName,
    sIContact,
  } as T;
}
