import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SalesCreditNoteStore, SalesCreditNoteState } from '../store/sales-credit-note.store';
import { SalesCreditNote } from '../model/sales-credit-note.model';

@Injectable({ providedIn: 'root' })
export class SalesCreditNoteQuery<T extends SalesCreditNote> extends QueryEntity<SalesCreditNoteState<T>> {
  constructor(protected override store: SalesCreditNoteStore<T>) {
    super(store);
  }
}
