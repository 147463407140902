import { Injectable } from '@angular/core';
import { ID, Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { SalesDocumentStore, SalesDocumentState } from '../store/sales-document.store';
import { Observable } from 'rxjs';
import { SalesDocument } from '../model/sales-document.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'dtPrintDate', sortByOrder: Order.DESC })
export class SalesDocumentQuery<T extends SalesDocument> extends QueryEntity<SalesDocumentState<T>> {
  constructor(protected override store: SalesDocumentStore<T>) {
    super(store);
  }

  selectDocumentsByOrderId(lngOrderIDP: number | ID): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ lngOrderID }) => lngOrderID === lngOrderIDP,
    });
  }

  selectDocumentsByOrderIdAndOrderType(lngOrderIDP: number | ID, sOrderTypeP: string): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ lngOrderID, sOrderType }) => lngOrderID === lngOrderIDP && sOrderType === sOrderTypeP,
    });
  }
}
