import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { TicketStatisticStore, TicketStatisticState } from '../store/ticket-statistic.store';
import { TicketStatistic } from '../models/ticket-statistic.model';

@QueryConfig({
  sortBy: 'id',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class TicketStatisticQuery<T extends TicketStatistic> extends QueryEntity<TicketStatisticState<T>> {
  constructor(protected override store: TicketStatisticStore<T>) {
    super(store);
  }
}
