import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SALES_DOCUMENT_CONFIG, SalesDocumentConfig } from './config/sales-document.config';

@NgModule({
  imports: [CommonModule],
})
export class SalesDocumentModule {
  static forRoot(config: SalesDocumentConfig): ModuleWithProviders<SalesDocumentModule> {
    return {
      ngModule: SalesDocumentModule,
      providers: [
        {
          provide: SALES_DOCUMENT_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
