import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { FavoriteItem } from '../model/favorite-item.model';
import { User } from '../../user';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FavoriteAdapterInterface } from '../model/favorite-adapter.interface';
import { FavoriteItemLocalQuery } from '../query/favorite-item-local.query';
import { FavoriteItemLocalStore } from '../store/favorite-item-local.store';
import { FavoriteList } from '../model/favorite-list.model';

@Injectable({ providedIn: 'root' })
export class FavoriteLocalService<T extends FavoriteList, R extends FavoriteItem> implements FavoriteAdapterInterface<T, R> {
  private static readonly ACTIVE_FAVORITE_ID = '1';

  constructor(private favoriteItemLocalQuery: FavoriteItemLocalQuery<R>, private favoriteItemLocalStore: FavoriteItemLocalStore<R>) {}

  createFavoriteItem(favoriteItem: R): Observable<R> {
    const list = (this.favoriteItemLocalQuery.getAll() || []).sort((f1: R, f2: R) => (f2.shtItemID as number) - (f1.shtItemID as number));
    const itemId = ((list[0]?.shtItemID as number) ?? 0) + 1;
    const newFavoriteItem = {
      ...favoriteItem,
      gListID: FavoriteLocalService.ACTIVE_FAVORITE_ID,
      shtItemID: itemId,
    } as R;

    return of({
      ...newFavoriteItem,
      fakeId: [newFavoriteItem.gListID, newFavoriteItem.shtItemID].join(',') as ID,
    }).pipe(tap(() => this.favoriteItemLocalStore.add(newFavoriteItem)));
  }

  createFavoriteList(favoriteList: T): Observable<T> {
    return of(favoriteList);
  }

  deleteFavoriteItem(favoriteItem: R): Observable<void> {
    return of(undefined).pipe(tap(this.favoriteItemLocalStore.remove(favoriteItem.fakeId)));
  }

  deleteFavoriteList(_: T): Observable<void> {
    return of(undefined);
  }

  getFavoriteItems(_: ID | string | undefined): Observable<R[]> {
    return this.favoriteItemLocalQuery.selectAll();
  }

  getFavoriteLists(): Observable<T[]> {
    return of([]);
  }

  getFavoriteListById(_: ID): Observable<T | undefined> {
    return of(undefined);
  }

  updateFavoriteItem(favoriteItem: R): Observable<R | undefined> {
    return of(undefined).pipe(
      tap(() => this.favoriteItemLocalStore.update(favoriteItem.fakeId, favoriteItem)),
      switchMap(() => this.favoriteItemLocalQuery.selectEntity<R>(favoriteItem.fakeId)),
    );
  }

  updateFavoriteList(favoriteList: T): Observable<T> {
    return of(favoriteList);
  }

  setActiveFavorite(_?: User): Observable<ID> {
    return of(FavoriteLocalService.ACTIVE_FAVORITE_ID);
  }
}
