import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Role } from '../model/role.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RoleState<T extends Role> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'roles', idKey: 'lngAPIAuthorizationGroupID' })
export class RoleStore<T extends Role> extends EntityStore<RoleState<T>> {
  constructor() {
    super();
  }
}
