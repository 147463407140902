import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Price } from '../model/price.model';

export type PriceState<T extends Price = Price> = EntityState<T>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'prices',
  idKey: 'sArticleID',
  resettable: true,
})
export class PriceStore<T extends Price = Price> extends EntityStore<PriceState<T>> {
  constructor() {
    super();
  }
}
