import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CORE_CONFIG, CoreConfig } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private consent$ = new BehaviorSubject<boolean>(!this.coreConfig.useConsent);
  public consentAccepted$: Observable<boolean> = this.consent$.asObservable();

  constructor(@Inject(CORE_CONFIG) private coreConfig: CoreConfig) {}

  updateConsent(value: boolean) {
    this.consent$.next(value);
  }
}
