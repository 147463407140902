import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { LABEL_PRINT_CONFIG, LabelPrintConfig } from '../config/label-print.config';
import { Label } from '../model/label';
import { LabelStore } from '../store/label.store';
import { MapperAbstract } from './mapper/mapper.abstract';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LabelService {
  constructor(
    @Inject(LABEL_PRINT_CONFIG) private config: LabelPrintConfig,
    private labelStore: LabelStore,
    private mapper: MapperAbstract,
  ) {}

  public initialize(id: string): void {
    this.mapper
      .map(id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((labels: Label[]) => this.update(labels));

    this.mapper
      .filename(id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((filename: string) => this.labelStore.update({ filename }));
  }

  public update(labels: Label[]): void {
    this.labelStore.update({ labels });
  }
}
