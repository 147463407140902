import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { FAVORITE_CONFIG, FavoriteConfig } from './config/favorite.config';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

@NgModule({
  imports: [CommonModule, TranslocoModule, TranslocoLocaleModule],
})
export class FavoriteModule {
  static forRoot(config: FavoriteConfig): ModuleWithProviders<FavoriteModule> {
    return {
      ngModule: FavoriteModule,
      providers: [
        {
          provide: FAVORITE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
