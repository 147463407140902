import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { ErrorService, LogService, UrlHelperService } from '../../core';
import { AUTH_CONFIG, AuthConfig } from '../config/auth.config';
import { HttpEndpointsConstants } from '../constants/http-endpoints.constants';
import { AuthResponseInterface } from '../interfaces/auth-response.interface';
import { UnknownType } from '../interfaces/unknown.type';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BearerTokenAuthInterceptor implements HttpInterceptor {
  public static readonly RETRY_FAILED_STATUS_CODE: number = 429;

  // TODO refactor use environment for blacklist
  private endpointBlacklist: (HttpEndpointsConstants | string)[] = [
    HttpEndpointsConstants.login,
    '/assets/',
    '/es/',
    '/translation/',
    '/cms/',
  ];

  private endpointRefreshBlacklist: HttpEndpointsConstants[] = [HttpEndpointsConstants.refresh, HttpEndpointsConstants.logout];

  constructor(
    @Inject(AUTH_CONFIG) private config: AuthConfig,
    private authService: AuthService,
    private errorService: ErrorService,
    private router: Router,
    private log: LogService,
    private urlHelper: UrlHelperService,
  ) {}

  public intercept(request: HttpRequest<UnknownType>, next: HttpHandler): Observable<HttpEvent<UnknownType>> {
    const isNotBlacklisted: boolean = request.url.match(this.endpointBlacklist.join('|')) === null;
    if (!isNotBlacklisted || request.method.toLowerCase() === 'options') {
      return next.handle(request);
    }

    const isNotRefreshBlacklisted: boolean = request.url.match(this.endpointRefreshBlacklist.join('|')) === null;
    return this.addAuth(request).pipe(
      first(),
      mergeMap((authRequest: HttpRequest<UnknownType>) => next.handle(authRequest)),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.warn('BearerTokenAuthInterceptor.intercept()', errorResponse);

        if (errorResponse.status === 401 && isNotRefreshBlacklisted) {
          return this.refreshTokenRequest(request, next);
        }

        throw errorResponse;
      }),
    );
  }

  private addAuth(request: HttpRequest<UnknownType>): Observable<HttpRequest<UnknownType>> {
    const headers: HttpHeaders = request.headers;

    return this.authService.getToken().pipe(
      // tap((token: AuthResponseInterface | undefined) => this.log.warn('addAuth', token)),
      first(),
      map((token: AuthResponseInterface | undefined) =>
        request.clone(token?.token ? { headers: headers.set('Authorization', `Bearer ${token.token}`) } : { headers }),
      ),
    );
  }

  private refreshTokenRequest(request: HttpRequest<UnknownType>, next: HttpHandler): Observable<HttpEvent<UnknownType>> {
    return this.refreshToken(request).pipe(
      first(),
      mergeMap((authRequest: HttpRequest<UnknownType>) => next.handle(authRequest)),
      catchError((refreshErrorResponse: HttpErrorResponse) => {
        // logout when error occurs while refreshing
        this.authService
          .logout(true)
          .then(() => this.router.navigate([this.urlHelper.localizeUrl(this.config.unauthorizedRoute)]))
          .catch((error: Error) =>
            this.errorService.add({
              label: 'BearerTokenAuthInterceptor.refreshTokenRequest()',
              ...error,
              error,
            }),
          );
        throw { ...refreshErrorResponse, status: BearerTokenAuthInterceptor.RETRY_FAILED_STATUS_CODE };
      }),
    );
  }

  private refreshToken(request: HttpRequest<UnknownType>): Observable<HttpRequest<UnknownType>> {
    this.log.warn('refresh', request);
    return from(this.authService.refresh()).pipe(mergeMap(() => this.addAuth(request)));
  }
}
