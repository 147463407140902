import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { UserState, UserStore } from '../store/user.store';
import { User } from '../model/user.model';
import { PaginationResponseInterface } from '../../core';

@Injectable({ providedIn: 'root' })
export class UserService<T extends User = User> extends NgEntityService<UserState<T>> {
  constructor(protected override store: UserStore<T>) {
    super(store);
  }

  getAll() {
    return super.get({ mapResponseFn: (res: PaginationResponseInterface<T>) => res.data });
  }
}
