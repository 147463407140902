import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RequestDebounceState, RequestDebounceStore } from './request-debounce.store';

@Injectable({ providedIn: 'root' })
export class RequestDebounceQuery extends QueryEntity<RequestDebounceState> {
  constructor(store: RequestDebounceStore) {
    super(store);
  }
}
