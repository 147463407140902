import { Injectable } from '@angular/core';
import { ShippingTypeStore } from '../store/shipping-type.store';
import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ShippingType } from '../model/shipping-type.model';

@Injectable({ providedIn: 'root' })
export class ShippingTypeService<T extends ShippingType> {
  constructor(private store: ShippingTypeStore<T>, private http: HttpClient) {}

  getShippingTypes(lngCartID: number | ID) {
    return this.http.get<T[]>(`p48apihost/shipping-types/${lngCartID}`).pipe(
      tap((response: T[]) => {
        this.store.set(response);
      }),
    );
  }
}
