import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { InjectorClass } from '../../util';
import { AuthHookTriggerService } from './services/auth-hook-trigger.service';

@NgModule({
  imports: [CommonModule],
})
export class AuthHookModule {
  constructor(injector: Injector) {
    InjectorClass.set(injector);
    injector.get<AuthHookTriggerService>(AuthHookTriggerService);
  }

  static forRoot(): ModuleWithProviders<AuthHookModule> {
    return {
      ngModule: AuthHookModule,
      providers: [],
    };
  }
}
