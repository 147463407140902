import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SteelFigureImageState, SteelFigureImageStore } from '../store/steel-figure-image.store';

@Injectable({ providedIn: 'root' })
export class SteelFigureImageQuery extends QueryEntity<SteelFigureImageState> {
  constructor(protected override store: SteelFigureImageStore) {
    super(store);
  }
}
