import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestDebounceService } from '../../core';
import { Language } from '../model/language.model';
import { LanguageQuery } from '../query/language.query';
import { LanguageStore } from '../store/language.store';

@Injectable({ providedIn: 'root' })
export class LanguageService<T extends Language> {
  constructor(
    protected query: LanguageQuery<T>,
    protected store: LanguageStore<T>,
    private http: HttpClient,
    private debouncer: RequestDebounceService,
  ) {}

  get(): Observable<T[]> {
    return this.debouncer.debounceAll<T, LanguageStore<T>>(this.store, this.http.get<T[]>('p48apihost/languages'));
  }
}
