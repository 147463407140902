import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { StockAvailabilityState, StockAvailabilityStore } from '../store/stock-availability.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'sArticleID',
  sortByOrder: Order.ASC, // Order.DESC
})
export class StockAvailabilityQuery extends QueryEntity<StockAvailabilityState> {
  constructor(protected override store: StockAvailabilityStore) {
    super(store);
  }
}
