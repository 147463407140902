import { Injectable } from '@angular/core';
import { Label } from '../../model/label';
import { LabelData } from '../../model/label-data';
import { PaddingBoxInterface } from '../../model/padding-box.interface';
import { Template } from '../../model/template';
import { TemplateRendererInterface } from '../../model/template-renderer.interface';
import { RendererAbstract } from './renderer.abstract';

@Injectable({
  providedIn: 'root',
})
export class DefaultRenderer extends RendererAbstract implements TemplateRendererInterface {
  public template(): Template {
    return {
      id: 'default',
      title: 'label-print.domain.template.default.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 36 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 8 },
    };
  }

  /**
   * B2B default renderer
   * @see https://www.figma.com/file/jeKilh5LVJyfR3cw6FHVm9/Label-Print?node-id=12%3A18
   *
   * We try to layout the following:
   * ┌───────────────────────────────────────────────────────────────────────────────────────────────────────────┐
   * │ ┌─────────────────────────────────────────────────────────────────────────┐ ┌───────────────────────────┐ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                            1/3V; 2/3H; Title                            │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ └─────────────────────────────────────────────────────────────────────────┘ │     2/3V; 1/3H; Image     │ │
   * │ ┌─────────────────────────────────────────────────────────────────────────┐ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                         1/3V; 2/3H; Description                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ └─────────────────────────────────────────────────────────────────────────┘ └───────────────────────────┘ │
   * │ ┌────────────────────────────────┐ ┌────────────────────────────────────────────────────────────────────┐ │
   * │ │                                │ │                                                                    │ │
   * │ │                                │ │                                                                    │ │
   * │ │       1/3V; 1/3H; Logo         │ │                          1/3V; 2/3H; Barcode                       │ │
   * │ │                                │ │                                                                    │ │
   * │ │                                │ │                                                                    │ │
   * │ └────────────────────────────────┘ └────────────────────────────────────────────────────────────────────┘ │
   * └───────────────────────────────────────────────────────────────────────────────────────────────────────────┘
   */
  protected async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data;
    let paddingBox: PaddingBoxInterface;

    // Title
    paddingBox = this.addPadding(x, y, this.boxWidth * (2 / 3), this.boxHeight * (1 / 3));
    this.jspdf.drawText(labelData.title, paddingBox.x, paddingBox.y, paddingBox.width, 8, 2, 'bold');

    // Description
    paddingBox = this.addPadding(x, y + this.boxHeight * (1 / 3), this.boxWidth * (2 / 3), this.boxHeight * (1 / 3));
    this.jspdf.drawText(labelData.description, paddingBox.x, paddingBox.y, paddingBox.width, 8, 2);

    // Date / Amount
    paddingBox = this.addPadding(x + this.boxWidth * (1 / 3), y, this.boxWidth * (2 / 3), this.boxHeight);
    this.jspdf.drawText(
      `${labelData.date} / ${labelData.unit}`,
      paddingBox.x,
      paddingBox.y + paddingBox.height,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        baseline: 'bottom',
      },
    );

    // Code
    paddingBox = this.addPadding(x, y, this.boxWidth, this.boxHeight);
    this.jspdf.drawText(
      labelData.code,
      paddingBox.x + paddingBox.width,
      paddingBox.y + paddingBox.height,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        align: 'right',
        baseline: 'bottom',
      },
    );

    // BarCode (EAN13)
    paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 3),
      y + this.boxHeight * (2 / 3) - 0.5,
      this.boxWidth * (2 / 3),
      this.boxHeight * (1 / 3) - this.jspdf.getTextDimensions(labelData.code).h,
    );
    await this.addEan13(labelData.code, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);

    // Image
    paddingBox = this.addPadding(x + this.boxWidth * (2 / 3), y, this.boxWidth * (1 / 3), this.boxHeight * (2 / 3));
    await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);

    // Logo
    paddingBox = this.addPadding(x, y + this.boxHeight * (2 / 3), this.boxWidth * (1 / 3), this.boxHeight * (1 / 3));
    await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
  }
}
