import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SteelConfiguration } from '../models/steel-configuration.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SteelConfigState<T extends SteelConfiguration> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'steel-configuration', idKey: 'sArticleID' })
export class SteelConfigStore<T extends SteelConfiguration> extends EntityStore<SteelConfigState<T>> {
  constructor() {
    super();
  }
}
