import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SCANNER_CONFIG, ScannerConfig } from './config/scanner.config';

@NgModule({
  imports: [CommonModule],
})
export class ScannerModule {
  static forRoot<T>(config: ScannerConfig<T>): ModuleWithProviders<ScannerModule> {
    return {
      ngModule: ScannerModule,
      providers: [
        config.adapter,
        {
          provide: SCANNER_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
