import { Injectable } from '@angular/core';
import { UnknownType } from '../../../auth/interfaces/unknown.type';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class MemoryStorageService extends StorageService {
  private storage: { [key: string]: any } = {};

  public override async set<T>(key: string, value: UnknownType): Promise<UnknownType> {
    return (this.storage[key] = value as T);
  }

  public override async get<T>(key: string): Promise<T | null> {
    return this.storage[key];
  }

  public override async remove(key: string): Promise<void> {
    const { [key]: _, ...rest } = this.storage;
    this.storage = rest;
  }
}
