import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { InjectorClass } from '../../util';
import { ErrorHookTriggerService } from './services/error-hook-trigger.service';

@NgModule({
  imports: [CommonModule],
})
export class ErrorHookModule {
  constructor(injector: Injector) {
    InjectorClass.set(injector);
    injector.get<ErrorHookTriggerService>(ErrorHookTriggerService);
  }

  static forRoot(): ModuleWithProviders<ErrorHookModule> {
    return {
      ngModule: ErrorHookModule,
      providers: [],
    };
  }
}
