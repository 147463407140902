import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BlogPost } from '../model/blog-post';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentPage } from '../model/cms.models';
import { Footer } from '../model/footer';

export interface CmsUiDynamicContentState extends EntityState<CmsUiDynamicContentPage> {
  cmsMainMenu: CmsUiDynamicContentMenu;
  homePage: CmsUiDynamicContentPage;
  errorPage: CmsUiDynamicContentPage;
  blogPosts: BlogPost[];
  blogSearch: BlogPost[];
  pageSearch: CmsUiDynamicContentPage[];
  footer: Footer;

  page(id: number): CmsUiDynamicContentPage;
}

/**
 * A store to save article entities
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cms-pages', resettable: true })
export class CmsUiDynamicContentStore extends EntityStore<CmsUiDynamicContentState> {
  /**
   * Creates an instance of article store.
   */
  constructor() {
    super({
      cmsMainMenu: undefined,
      homePage: undefined,
      errorPage: undefined,
      footer: undefined,
      blogPosts: undefined,
      blogSearch: undefined,
      pageSearch: undefined,
    });
  }
}
