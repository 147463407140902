import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbsResolver } from '../resolvers/breadcrumbs.resolver';

export interface Breadcrumb {
  label: string;
  translate: boolean;
  url: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  private defaultResolver = new BreadcrumbsResolver();
  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router, private injector: Injector, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        switchMap((route) => this.resolveBreadcrumb(route)),
        tap((breadcrumbs: Breadcrumb[]) => this._breadcrumbs$.next(breadcrumbs)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private resolveBreadcrumb(route: ActivatedRoute): Observable<Breadcrumb[]> {
    let result$: Observable<Breadcrumb[]> = of([]);
    const data = route.snapshot.data;
    if (data && data['breadcrumbs']) {
      let resolver: BreadcrumbsResolver;

      if (data['breadcrumbs'].prototype instanceof BreadcrumbsResolver) {
        resolver = this.injector.get(data['breadcrumbs']);
      } else {
        resolver = this.defaultResolver;
      }

      result$ = resolver.resolve(route.snapshot, this.router.routerState.snapshot);
    }

    if (route.firstChild) {
      result$ = concat(result$, this.resolveBreadcrumb(route.firstChild));
    }

    return result$;
  }
}
