import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order, ID } from '@datorama/akita';
import { TicketMessageStore, TicketMessageState } from '../store/ticket-message.store';
import { TicketMessage } from '../models/ticket-message.model';
import { Observable } from 'rxjs';

@QueryConfig({
  sortBy: 'dtEntryDate',
  sortByOrder: Order.ASC, // Order.DESC
})
@Injectable({ providedIn: 'root' })
export class TicketMessageQuery<T extends TicketMessage> extends QueryEntity<TicketMessageState<T>> {
  constructor(protected override store: TicketMessageStore<T>) {
    super(store);
  }

  selectByTicketId(ticketId: ID): Observable<T[]> {
    return this.selectAll({
      filterBy: (ticket) => ticket.lngTicketId === ticketId,
    });
  }
}
