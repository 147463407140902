import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { UrlHelperService } from '../../core';
import { AUTH_CONFIG, AuthConfig } from '../config/auth.config';
import { AuthStateEnum } from '../interfaces/auth-state.enum';
import { AuthService } from '../services/auth.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AnonymousGuard implements CanActivate, CanActivateChild {
  constructor(
    @Inject(AUTH_CONFIG) private config: AuthConfig,
    private authService: AuthService,
    private router: Router,
    private urlHelper: UrlHelperService,
  ) {}

  /**
   * Check navigation
   * Disallow: Authorized/Logged In
   * Allow: Anonymous
   * Allow: On error
   */
  public canActivate(): Observable<boolean | UrlTree> {
    return this.check();
  }

  public canActivateChild(): Observable<boolean | UrlTree> {
    return this.check();
  }

  private check(): Observable<boolean | UrlTree> {
    return this.authService.listen().pipe(
      filter(() => !this.authService.isLoadingState()),
      first(),
      untilDestroyed(this),
      map((state: AuthStateEnum) => state === AuthStateEnum.ANONYMOUS),
      map((allowed: boolean) => allowed || this.router.parseUrl(this.urlHelper.localizeUrl(this.config.authorizedRoute))),
      catchError(() => of(true)),
    );
  }
}
