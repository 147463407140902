import { Injectable } from '@angular/core';
import { StringHelper } from '../string/string.helper';

/**
 * A service for building the url path
 * Should be used statically
 */
@Injectable()
export class UrlPathHelperService {
  public static getUrlPath(sUrlPath = '', defaultLangCode: string) {
    let path = '/' + defaultLangCode;
    path += sUrlPath.replace(/^\/[\w'-]*/, '');
    return StringHelper.rtrim(path, '/');
  }
}
