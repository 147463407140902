import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { UrlHelperService } from '../../core';
import { AUTH_CONFIG, AuthConfig } from '../config/auth.config';
import { AccessService } from '../services/access.service';

/**
 * To use this guard, define the accessId within the data attribute of the route
 *
 * Example:
 *
 * ```
 *       {
 *         path: 'carts',
 *         canActivate: [AuthorizedGuard, HasAccessGuard],
 *         data: { accessId: 'all-carts' },
 *         loadChildren: () => import('./profile-features/carts/carts.module').then((m) => m.CartsModule),
 *       },
 * ```
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HasAccessGuard implements CanActivate, CanActivateChild {
  constructor(
    @Inject(AUTH_CONFIG) private config: AuthConfig,
    private accessService: AccessService,
    private router: Router,
    private urlHelper: UrlHelperService,
  ) {}

  /**
   * Check navigation
   * Disallow: User has no access rights
   * Allow: User has access rights
   * Allow: On error
   */
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.check(route);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.check(route);
  }

  private check(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const id: string = route.data['accessId'];

    return this.accessService.hasAccess(id).pipe(
      first(),
      map((allowed: boolean) => allowed || this.router.parseUrl(this.urlHelper.localizeUrl(this.config.authorizedRoute))),
      untilDestroyed(this),
      catchError(() => of(true)),
    );
  }
}
