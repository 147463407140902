import { FactoryAttestation } from '../model/factory-attestation.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { FACTORY_ATTESTATION_CONFIG, FactoryAttestationConfig } from '../config/factory-attestation.config';
import { saveAs } from 'file-saver';
import { take, tap } from 'rxjs/operators';
import { getExtension } from 'mime/lite';

@Injectable({ providedIn: 'root' })
export class FactoryAttestationService<T extends FactoryAttestation = FactoryAttestation> {
  constructor(
    protected http: HttpClient,
    @Inject(FACTORY_ATTESTATION_CONFIG) protected factoryAttestationConfig: FactoryAttestationConfig,
  ) {}

  searchFactoryAttestations(searchText: string): Observable<T[]> {
    return this.http.get<T[]>(this.factoryAttestationConfig.apiUrl + '/factory-attestation', { params: { searchtext: searchText } });
  }

  downloadFactoryAttestationDocument(oFactoryAttestation: T): Observable<any> {
    return this.http
      .post(
        this.factoryAttestationConfig.apiUrl + '/factory-attestation',
        {
          sReference: oFactoryAttestation.sReference,
          sReferenceDetail: oFactoryAttestation.sReferenceDetail,
        },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        take(1),
        tap((res) => {
          const contentType = res.headers.get('content-type') || '';
          this.saveFileAs(
            res.body,
            `${oFactoryAttestation.sReference}_${oFactoryAttestation.sReferenceDetail}.${getExtension(contentType)}`,
            contentType,
          );
        }),
      );
  }

  saveFileAs(file: any, fileName: string, type: string) {
    const blob = new Blob([file], { type });
    saveAs(blob, fileName);
  }
}
