import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { UserStore, UserState } from '../store/user.store';
import { User } from '../model/user.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'sFirstName', sortByOrder: Order.ASC })
export class UserQuery<T extends User> extends QueryEntity<UserState<T>> {
  constructor(protected override store: UserStore<T>) {
    super(store);
  }
}
