import { Inject, Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Article } from '../model/article.model';
import { Catalog } from '../model/catalog.model';
import { Facet } from '../model/facet.model';
import { SortByType } from '../model/sort-by.type';
import { ViewModeType } from '../model/view-mode.type';
import { CATALOG_CONFIG, CatalogConfig, PageViewConfig } from '../config/catalog.config';

export interface CachedSearchHits<H, C> {
  id: string;
  totalHits: number;
  hits: H[];
  config?: C;
  queryFilters?: any;
}

export interface ArticleFacetedSearchState {
  categorySuggestions: any[];
  cmsSuggestions: any[];
  facets: Facet[];
  hits: Article[];
  cachedHits: CachedSearchHits<Article, PageViewConfig>[];
  pageIndex: number;
  pageSize: number;
  sortBy: SortByType;
  viewMode: ViewModeType;
  subLevelCategories: Catalog[];
  totalHits: number;
}

function createInitialState(catalogConfig: CatalogConfig): ArticleFacetedSearchState {
  return {
    categorySuggestions: [],
    cmsSuggestions: [],
    facets: [],
    hits: [],
    cachedHits: [],
    subLevelCategories: [],
    totalHits: 0,
    ...catalogConfig,
  };
}

@Injectable()
@StoreConfig({ name: 'faceted-search-result' })
export class FacetedSearchResultStore extends Store<ArticleFacetedSearchState> {
  constructor(@Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig) {
    super(createInitialState(catalogConfig));
  }
}
