import { Inject, Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ElasticSearchService, SearchResponse } from '../../core';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';
import { SearchStore } from '../store/search.store';
import { buildMultiSearchQuery } from '../utils/search.util';
import { HighlightInterface } from './highlight.interface';

@Injectable({ providedIn: 'root' })
export class SearchService {
  /**
   * Creates an instance of search service.
   */
  constructor(
    protected store: SearchStore,
    private elasticSearch: ElasticSearchService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {}

  search(searchTerm: string): Observable<SearchResponse<any>> {
    const query = buildMultiSearchQuery(
      searchTerm,
      undefined,
      this.elasticSearch.localizedIndex('article'),
      this.elasticSearch.localizedIndex('category'),
    );
    this.store.setLoading(true);
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        tap((response: any) => {
          const articles = response.responses[0].hits.hits.map((article: any) => {
            return article._source;
          });
          const articleHighlights = response.responses[0].hits.hits.map((article: any): HighlightInterface => {
            return {
              id: article._id,
              highlights: article.highlight,
            };
          });
          const categories = response.responses[1].hits.hits.map((category: any) => category._source);
          const categoryHighlights = response.responses[1].hits.hits.map((category: any): HighlightInterface => {
            return {
              id: category._id,
              highlights: category.highlight,
            };
          });
          this.store.update({
            articles: articles,
            totalArticles: response.responses[0].hits.total.value,
            categories: categories,
            searchTerm: searchTerm,
            articleHighlights,
            categoryHighlights,
          });
          this.store.setLoading(false);
        }),
        catchError((error: Error) => {
          this.store.setLoading(false);
          return throwError(() => error);
        }),
      );
  }

  getHighlightKeys(id: ID, highlights: HighlightInterface[] = []): string[] {
    const hit: HighlightInterface | undefined = highlights.find((highlight: HighlightInterface) => highlight.id === id);

    return hit ? Object.keys(hit.highlights) : [];
  }

  resetStore() {
    this.store.reset();
  }
}
