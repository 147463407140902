import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SalesOffer } from '../model/sales-offer.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesOfferState<T extends SalesOffer> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-offers', idKey: 'lngOrderID', resettable: true })
export class SalesOfferStore<T extends SalesOffer> extends EntityStore<SalesOfferState<T>> {
  constructor() {
    super();
  }
}
