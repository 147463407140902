import { groupBy } from 'lodash';
import { ElasticSearchSortInterface } from '../../core/elasticsearch/model/elastic-search-sort.interface';
import { SortByType } from '../model/sort-by.type';
import { buildSearchQuery } from './search.util';

export function filtersToSearchQuery(activeFilters: any[], nestedPath: string): any[] {
  const queryFilters: any[] = [];
  const groupedFilters = groupBy(activeFilters, 'name');

  Object.entries(groupedFilters).forEach((filterGroup: any) => {
    const filters = filterGroup[1].map((filter: any) => {
      return {
        nested: {
          query: {
            bool: {
              filter: [
                {
                  term: {
                    [`${nestedPath}.sName.keyword`]: {
                      value: filter.name,
                    },
                  },
                },
                {
                  terms: {
                    [`${nestedPath}.sValue.keyword`]: [filter.value],
                  },
                },
              ],
            },
          },
          path: nestedPath,
        },
      };
    });
    queryFilters.push({
      bool: {
        should: [...filters],
      },
    });
  });
  return queryFilters;
}

export function buildFacetedMultiSearchQuery(
  searchString: string,
  queryFilters: any[],
  size = 12,
  page = 1,
  sortBy: SortByType | ElasticSearchSortInterface[] = 'relevance',
  articleIndex: string,
  categoryIndex: string,
) {
  const sort = Array.isArray(sortBy) ? sortBy : sortBy === 'title' ? [{ 'sName.keyword': 'asc' }] : [];

  return [
    { index: articleIndex },
    {
      from: page,
      size: size,
      sort: sort,
      query: buildSearchQuery(searchString),
      post_filter: {
        bool: {
          must: [...filtersToSearchQuery(queryFilters, 'oFeatures')],
        },
      },
      aggs: {
        agg_full: {
          nested: {
            path: 'oFeatures',
          },
          aggs: {
            attr_name: {
              terms: {
                field: 'oFeatures.sName.keyword',
              },
              aggs: {
                feature: {
                  top_hits: {
                    size: 1,
                    _source: {
                      include: ['oFeatures.shtRepresentationType', 'oFeatures.shtWebshopFilter'],
                    },
                  },
                },
                attr_value: {
                  terms: {
                    field: 'oFeatures.sValue.keyword',
                  },
                  aggs: {
                    option: {
                      top_hits: {
                        size: 1,
                        _source: {
                          include: ['oFeatures.shtPos'],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        agg_filtered: {
          filter: {
            bool: {
              must: [buildSearchQuery(searchString), ...filtersToSearchQuery(queryFilters, 'oFeatures')],
            },
          },
          aggs: {
            filtered: {
              nested: {
                path: 'oFeatures',
              },
              aggs: {
                attr_name: {
                  terms: {
                    field: 'oFeatures.sName.keyword',
                  },
                  aggs: {
                    attr_value: {
                      terms: {
                        field: 'oFeatures.sValue.keyword',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    { index: categoryIndex },
    {
      size: 10,
      query: {
        query_string: { query: `*${searchString}*`, default_field: 'sTitle' },
      },
    },
  ];
}
