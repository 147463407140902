import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ArchiveDocument } from '../model/archive-document.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ArchiveDocumentState<T extends ArchiveDocument> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'archive-documents', idKey: 'sDocumentID' }) // TODO ist sDocumentID the correct idKey?
export class ArchiveDocumentStore<T extends ArchiveDocument> extends EntityStore<ArchiveDocumentState<T>> {
  constructor() {
    super();
  }
}
