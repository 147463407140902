import { Article, Unit } from '../model/article.model';

export class ArticleFactory {
  static getArticleUnitSelected(article: Article) {
    let oUnitSelected: Unit | undefined = undefined;

    article.oUnitColl.forEach((oUnit: Unit) => {
      if (oUnit.sQuantityUnitInput === article.sQuantityUnitSales) {
        oUnitSelected = oUnit;
      }
    });

    if (oUnitSelected == undefined) {
      article.oUnitColl.forEach((oUnit: Unit) => {
        if (oUnit.shtStandard === 1) {
          oUnitSelected = oUnit;
        }
      });
    }

    if (oUnitSelected == undefined) {
      if (article.oUnitColl.length > 0) {
        oUnitSelected = article.oUnitColl[0];
      }
    }

    article.oUnitSelected = oUnitSelected as Unit;

    return article;
  }

  static create(article: Article): Article {
    return ArticleFactory.getArticleUnitSelected({
      ...article,
      decQty: article.decQuantityPackage > 0 ? article.decQuantityPackage : 1,
      decFactor1: 0,
      decFactor2: 0,
      decFactor3: 0,
    });
  }
}
