import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EventTrackingInterface, PageTracking, TrackingAdapterInterface } from '@lobos/common';
import { PlausibleEvent, PlausibleOptions } from './plausible.types';
import { PlausibleLoaderService } from './plausible-loader.service';
import { isPlatformBrowser } from '@angular/common';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    plausible: Function;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlausibleAdapter implements TrackingAdapterInterface {
  //logService = inject(LogService);
  plausibleLoaderService = inject(PlausibleLoaderService);
  platform = inject(PLATFORM_ID);

  constructor() {
    if (isPlatformBrowser(this.platform) && !window.plausible) {
      this.plausibleLoaderService.addPlausibleToDom();
    }
  }

  public async trackEvent<T extends EventTrackingInterface>(event: T): Promise<void> {
    //this.logService.log('GTM trackEvent', event);
    if (event.name === 'purchase') {
      this.event(event.name, {
        revenue: {
          currency: 'USD',
          amount: (event.payload as any).ecommerce.value,
        },
      });
      return;
    }

    this.event(event.name);
  }

  public async trackPage<T extends PageTracking>(_: T): Promise<void> {
    //this.logService.log('GTM trackPage', page);
    // Page tracking is automatically done by Plausible
  }

  private event(event: PlausibleEvent | null, options?: PlausibleOptions) {
    if (isPlatformBrowser(this.platform) && window.plausible && event) {
      window.plausible(event, options);
    }
  }
}
