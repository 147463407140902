import { Injectable } from '@angular/core';
import { ProjectState, ProjectStore } from '../store/project.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { Project } from '../models/project.model';

@Injectable({ providedIn: 'root' })
export class ProjectService<T extends Project> extends NgEntityService<ProjectState<T>> {
  constructor(store: ProjectStore<T>) {
    super(store);
  }
}
