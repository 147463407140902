/**
 * A service for helping with common string operation.
 * Should be used statically
 */
export class StringHelper {
  /**
   * Check if given value is of type "string"
   */
  public static isString(value: unknown) {
    return typeof value === 'string' || value instanceof String;
  }

  /**
   * Trims characters of a given string on the right
   */
  public static rtrim(input: string, characters = ' \f\n\r\t'): string {
    let k = input.length - 1;
    while (k >= 0) {
      if (characters.indexOf(input.charAt(k)) === -1) {
        return input.substring(0, k + 1);
      }
      k--;
    }

    return input;
  }

  /**
   * Trims characters of a given string on the left
   */
  public static ltrim(input: string, characters = ' \f\n\r\t'): string {
    let k = 0;
    while (k < input.length) {
      if (characters.indexOf(input.charAt(k)) === -1) {
        return input.substring(k, input.length);
      }
      k++;
    }

    return input;
  }

  /**
   * Trims characters of a given string on both sides
   */
  public static trim(input: string, characters = ' \f\n\r\t'): string {
    return StringHelper.rtrim(StringHelper.ltrim(input, characters), characters);
  }
}
