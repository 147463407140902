import { Inject, Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { ERROR_CONFIG, ErrorConfig } from '../config/error.config';
import { ErrorFilterInterface } from '../models/error-filter.interface';
import { ConfigurableError, ErrorInterface } from '../models/error.interface';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private errors: ReplaySubject<ErrorInterface> = new ReplaySubject<ErrorInterface>(1);
  private filters: ErrorFilterInterface[] = [];

  constructor(@Inject(ERROR_CONFIG) private config: ErrorConfig) {
    this.config.filters?.forEach((errorFilter: ErrorFilterInterface) => this.addFilter(errorFilter));
  }

  public static mapError(error: Error, options: ConfigurableError, params: any): ErrorInterface {
    return {
      ...error,
      error,
      label: options.label,
      display: options.display || false,
      translate: options.translate || false,
      translateScope: options.translateScope || undefined,
      ignore: options.ignore || false,
      params,
    };
  }

  public addFilter(filter: ErrorFilterInterface): void {
    this.filters.push(filter);
  }

  public add(error: ErrorInterface): ErrorInterface {
    this.errors.next(error);

    return error;
  }

  public listen(): Observable<ErrorInterface> {
    return this.errors
      .asObservable()
      .pipe(
        switchMap((error: ErrorInterface) =>
          this.filters.reduce(
            (before: Observable<ErrorInterface>, errorFilter: ErrorFilterInterface) =>
              before.pipe(switchMap((newError: ErrorInterface) => errorFilter.filter(newError))),
            of(error),
          ),
        ),
      );
  }
}
