import { Inject, Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { Observable } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { ElasticSearchService, SearchResponse, SearchResponseHit } from '../../core';
import { Category } from '../model/category.model';
import { CategoryState, CategoryStore } from '../store/category.store';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';
import {
  buildCategoriesByGroupIdQuery,
  buildCategoriesByParentGroupIdQuery,
  buildCategoryQueryByParentUrlPath,
} from '../utils/category.util';

@Injectable({ providedIn: 'root' })
export class CategoryService extends NgEntityService<CategoryState> {
  /**
   * Creates an instance of catalog service.
   */
  constructor(
    private elasticSearch: ElasticSearchService,
    store: CategoryStore,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super(store);
  }

  public getByGroupId(id: number): Observable<Category> {
    const query = buildCategoriesByGroupIdQuery(id);
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        share(),
        map((response: SearchResponse<Category>) => response.hits.hits[0]._source),
        tap((category: Category) => this.store.upsert(category.Id, category)),
      );
  }

  public getById(id: string): Observable<Category> {
    const query = {
      query: {
        ids: {
          values: [id],
        },
      },
    };
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        share(),
        map((response: SearchResponse<Category>) => response.hits.hits[0]._source),
        tap((category: Category) => this.store.upsert(category.Id, category)),
      );
  }

  public getByParentGroupId(id: number): Observable<Category[]> {
    const query = buildCategoriesByParentGroupIdQuery(id);
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        share(),
        filter((response: SearchResponse<any>) => response.hits.hits.length > 0),
        map((response: SearchResponse<any>) => {
          return response.hits.hits.map((category: SearchResponseHit<any>) => ({ id: category._id, ...category._source }));
        }),
        tap((categories: Category[]) => this.store.upsertMany(categories)),
      );
  }

  public getByParentUrlPath(parentUrlPath: string): Observable<Category[]> {
    const query = buildCategoryQueryByParentUrlPath(parentUrlPath);
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        share(),
        filter((response: SearchResponse<any>) => response.hits.hits.length > 0),
        map((response: SearchResponse<any>) => {
          return response.hits.hits.map((category: SearchResponseHit<any>) => ({ id: category._id, ...category._source }));
        }),
        tap((categories: Category[]) => this.store.upsertMany(categories)),
      );
  }
}
