import { Injectable } from '@angular/core';
import { ErrorService } from '../../core';
import { EventTracking } from '../models/event-tracking';
import { PageTracking } from '../models/page-tracking';
import { TrackingAdapterInterface } from '../models/tracking-adapter.interface';
import { ConsentService } from './consent.service';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TrackingFactory implements TrackingAdapterInterface {
  private adapters: TrackingAdapterInterface[] = [];
  private eventCache: EventTracking[] = [];
  private pageCache: PageTracking[] = [];
  private consentAccepted: boolean = false;

  constructor(private errorService: ErrorService, private consentService: ConsentService) {
    consentService.consentAccepted$
      .pipe(
        tap((accepted: boolean) => {
          if (accepted) {
            this.consentAccepted = accepted;
            this.pushEventCache();
            this.pushPageCache();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public register(adapter: TrackingAdapterInterface): void {
    this.adapters.push(adapter);
  }

  public async trackEvent<T extends EventTracking>(event: T): Promise<void> {
    if (!this.consentAccepted) {
      this.eventCache.push(event);
      return;
    }

    for (const adapter of this.adapters) {
      try {
        await adapter.trackEvent(event);
      } catch (error: any) {
        this.errorService.add({ label: 'TrackingFactory.trackEvent()', ...error, error });
      }
    }
  }

  public async trackPage<T extends PageTracking>(page: T): Promise<void> {
    if (!this.consentAccepted) {
      this.pageCache.push(page);
      return;
    }

    for (const adapter of this.adapters) {
      try {
        await adapter.trackPage(page);
      } catch (error: any) {
        this.errorService.add({ label: 'TrackingFactory.trackPage()', ...error, error });
      }
    }
  }

  private pushEventCache() {
    this.eventCache.forEach((item) => {
      this.trackEvent(item);
    });
    this.eventCache = [];
  }

  private pushPageCache() {
    this.pageCache.forEach((item) => {
      this.trackPage(item);
    });
    this.pageCache = [];
  }
}
