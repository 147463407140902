import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { CART_CONFIG, CartConfig } from './config/cart.config';
import { ShippingConditionPipe } from './pipes/shipping-condition.pipe';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [ShippingConditionPipe],
  exports: [ShippingConditionPipe],
})
export class CartModule {
  static forRoot(config: CartConfig): ModuleWithProviders<CartModule> {
    return {
      ngModule: CartModule,
      providers: [
        {
          provide: CART_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
