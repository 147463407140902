import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { TicketCategoryState, TicketCategoryStore } from '../store/ticket-category.store';
import { TicketCategory } from '../models/ticket-category.model';

@QueryConfig({ sortBy: 'id', sortByOrder: Order.ASC })
@Injectable({ providedIn: 'root' })
export class TicketCategoryQuery<T extends TicketCategory> extends QueryEntity<TicketCategoryState<T>> {
  constructor(protected override store: TicketCategoryStore<T>) {
    super(store);
  }
}
