import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { CustomerState, CustomerStore } from '../store/customer.store';
import { Customer } from '../model/customer.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'sCompany1', sortByOrder: Order.ASC })
export class CustomerQuery<T extends Customer> extends QueryEntity<CustomerState<T>> {
  constructor(protected override store: CustomerStore<T>) {
    super(store);
  }
}
