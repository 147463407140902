import { ID } from '@datorama/akita';
import { Article } from '../../catalog';

/**
 * A item position of a favorite master list
 */
export interface FavoriteItem {
  fakeId: ID;
  /** the id of the favorite item position */
  shtItemID: ID | number;
  /** the id of the favorite master list */
  gListID: ID | string;
  /** quantity */
  decQuantity: number;
  /** article */
  oArticle: Article | undefined;
}

/**
 * A factory function that creates FavoriteItem
 */
export function createFavoriteItem<T extends FavoriteItem = FavoriteItem>({
  gListID = '',
  decQuantity = 1,
  oArticle = undefined,
  shtItemID = 0,
  fakeId = '',
}: {
  gListID?: ID | string;
  decQuantity?: number;
  oArticle: Article | undefined;
  shtItemID?: ID | number;
  fakeId?: ID;
}): T {
  return {
    fakeId,
    gListID,
    decQuantity,
    oArticle,
    shtItemID,
  } as T;
}
