import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Label } from '../../model/label';
import { LabelMapperInterface } from '../../model/label-mapper.interface';

/**
 * Abstract class to make the mapper injectables replaceable
 */
@Injectable()
export abstract class MapperAbstract implements LabelMapperInterface {
  abstract filename(id: string): Observable<string>;

  abstract map(id: string): Observable<Label[]>;
}
