import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { RequestDebounceService } from '../../core';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';
import { StockAvailability } from '../model/stock-availability.model';
import { StockAvailabilityQuery } from '../query/stock-availability.query';
import { StockAvailabilityStore } from '../store/stock-availability.store';

@Injectable({ providedIn: 'root' })
export class StockAvailabilityService {
  constructor(
    protected store: StockAvailabilityStore,
    private http: HttpClient,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
    private query: StockAvailabilityQuery,
    private debouncer: RequestDebounceService,
  ) {}

  get(sArticleID: ID | string): Observable<StockAvailability | undefined> {
    const encodedArticleID = encodeURIComponent(sArticleID);

    return this.debouncer.debounce<StockAvailability, StockAvailabilityStore, StockAvailabilityQuery>(
      sArticleID,
      this.store,
      this.query,
      this.http.get<StockAvailability>(this.catalogConfig.apiUrl + `/stock-availability/${encodedArticleID}`),
    );
  }
}
