import { inject, InjectionToken } from '@angular/core';
import { Paginator } from '@datorama/akita';
import { SalesOffer } from '../model/sales-offer.model';
import { SalesOfferState } from '../store/sales-offer.store';
import { SalesOfferQuery } from '../query/sales-offer.query';

export const SALES_OFFER_PAGINATOR = new InjectionToken('SalesOfferPaginator', {
  providedIn: 'root',
  factory: () => {
    return new Paginator<SalesOfferState<SalesOffer>>(inject(SalesOfferQuery)).withControls().withRange();
  },
});
