import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CORE_CONFIG, CoreConfig, defaultCoreConfig } from './core.config';
import { BaseInterceptor, OfflineDetectInterceptor } from './interceptors';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineDetectInterceptor, multi: true },
  ],
})
export class CoreModule {
  static forRoot(config: CoreConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CORE_CONFIG,
          useValue: { ...defaultCoreConfig, ...config },
        },
      ],
    };
  }
}
