import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Product } from '../model/product.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductState extends EntityState<Product> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'product',
  idKey: 'sUrlPath',
  resettable: true,
})
export class ProductStore extends EntityStore<ProductState> {
  constructor() {
    super();
  }
}
