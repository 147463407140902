import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { FavoriteListStore, FavoriteListState } from '../store/favorite-list.store';
import { FavoriteList } from '../model/favorite-list.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'sListname', sortByOrder: Order.ASC })
export class FavoriteListQuery<T extends FavoriteList> extends QueryEntity<FavoriteListState<T>> {
  constructor(protected override store: FavoriteListStore<T>) {
    super(store);
  }
}
