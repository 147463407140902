import { Inject, Injectable } from '@angular/core';
import { User } from '@lobos/common';
import { captureException, init, setUser } from '@sentry/browser';
import { ErrorInterface } from '../../error';
import { SENTRY_CONFIG, SentryConfig } from '../config/sentry.config';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  constructor(@Inject(SENTRY_CONFIG) private config: SentryConfig) {}

  public setup(): void {
    if (!this.config.active) {
      return;
    }

    init({
      release: this.config.release,
      attachStacktrace: true,
      autoSessionTracking: false,
      debug: this.config.debug,
      environment: this.config.environment,
      dsn: this.config.dsn,
    });
  }

  public updateUser(user: User): void {
    if (!this.config.active) {
      return;
    }

    setUser({ email: user ? user.sEmail || user.sUserName : undefined });
  }

  public captureException(error: ErrorInterface): void {
    if (!this.config.active) {
      return;
    }

    captureException(error.error?.orginalError || error.error, {
      user: { ip_address: undefined },
      extra: error as any,
      tags: { label: error.label },
    });
  }
}
