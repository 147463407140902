import { Injectable } from '@angular/core';
import { SimpleStorageService } from '../../core';
import { AuthResponseInterface } from '../interfaces/auth-response.interface';
import { User } from '../../user';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService<T extends User = User> extends SimpleStorageService<AuthResponseInterface<T>> {
  public key(): string {
    return 'auth_storage';
  }
}
