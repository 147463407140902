import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ErrorInterface, ErrorService, SCANNER_CONFIG, ScannerAdapterAbstract, ScannerConfig } from '@lobos/library';
import * as loadjs_ from 'loadjs';
import { BehaviorSubject, from, interval, Observable, of, Subject, throwError } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ZbarConfig } from '../config/zbar.config';
import { ZbarApiInterface } from '../models/zbar-api.interface';
import { ZbarSymbologyEnum } from '../models/zbar-symbology.enum';

declare let LobosZbarLoader: any;

@Injectable()
export class ZbarAdapter extends ScannerAdapterAbstract {
  private initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private api: ZbarApiInterface | undefined;
  private mod: any;

  private stream: MediaStream | undefined;
  private device: MediaDeviceInfo | undefined;
  private videoElement: HTMLVideoElement | undefined;

  private scanStop$: Subject<void> = new Subject();

  constructor(
    @Inject(SCANNER_CONFIG) private config: ScannerConfig<ZbarConfig>,
    @Inject(DOCUMENT) private document: Document,
    private errorService: ErrorService,
  ) {
    super();
  }

  public askForPermission(): Observable<boolean> {
    return this.getStream({ video: { facingMode: 'environment' } }).pipe(
      tap((stream: MediaStream) => (this.stream = stream)),
      map((stream: MediaStream) => !!stream),
      tap(() => this.stop()),
    );
  }

  public handleError(error: Error, display: boolean | string = 'name', ignore = false): ErrorInterface {
    return this.errorService.add({
      ...error,
      error,
      label: 'ZbarAdapter.handleError()',
      ignore,
      display,
      translate: true,
      translateScope: 'scanner',
    });
  }

  public isSupported(): Observable<boolean> {
    if (!this.config.adapterConfig?.engineLocation) {
      return throwError('EngineConfigMissing');
    }

    if (!this.config.adapterConfig?.symbology?.length) {
      return throwError('SymbologyMissing');
    }

    try {
      return of(!!navigator && !!navigator.mediaDevices && this.isWebAssemblySupported());
    } catch (_) {
      return of(false);
    }
  }

  public ready(): Observable<boolean> {
    return this.ready$.asObservable();
  }

  public start(videoContainer: HTMLDivElement, camera?: MediaDeviceInfo): Observable<string> {
    this.videoElement = this.document.createElement('video');
    videoContainer.append(this.videoElement);

    const canvas: HTMLCanvasElement = this.document.createElement('canvas') as HTMLCanvasElement;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;

    return this.configure().pipe(
      filter((initialized: boolean) => initialized),
      switchMap(() => this.scan(canvas, ctx, camera) as Observable<string>),
      filter((result: string) => !!result),
      takeUntil(this.scanStop$),
    );
  }

  public stop(): void {
    this.scanStop$.next();
    this.scanStop$.complete();
    this.scanStop$ = new Subject();
    this.ready$.next(false);

    if (this.stream) {
      this.stream.getTracks().forEach((t: MediaStreamTrack) => t.stop());
      this.stream = undefined;
    }

    if (this.device) {
      this.device = undefined;
    }

    if (this.videoElement) {
      this.videoElement.remove();
      this.videoElement = undefined;
    }
  }

  private configure(): Observable<boolean> {
    if (this.initialized$.getValue()) {
      return this.initialized$;
    }

    const loadjs = (loadjs_ as any).default || loadjs_;

    return from(loadjs(`${this.config.adapterConfig!.engineLocation}/zbar.js`, { returnPromise: true })).pipe(
      switchMap(() => from(LobosZbarLoader({ locateFile: () => `${this.config.adapterConfig!.engineLocation}/zbar.wasm` }))),
      tap((mod: any) => (this.mod = mod)),
      map(
        () =>
          (this.api = {
            enableSymbology: this.mod.cwrap('enableSymbology', '', ['string']),
            createBuffer: this.mod.cwrap('createBuffer', 'number', ['number']),
            deleteBuffer: this.mod.cwrap('deleteBuffer', '', ['number']),
            triggerDecode: this.mod.cwrap('triggerDecode', 'number', ['number', 'number', 'number']),
            getScanResults: this.mod.cwrap('getScanResults', 'number', []),
          }),
      ),
      tap(() => this.config.adapterConfig!.symbology.forEach((symbol: ZbarSymbologyEnum) => this.api!.enableSymbology(symbol))),
      tap(() => this.initialized$.next(true)),
      first(),
      switchMap(() => this.initialized$),
    );
  }

  private scan(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, camera?: MediaDeviceInfo): Observable<string | undefined> {
    return this.getVideoDevices().pipe(
      switchMap((devices: MediaDeviceInfo[]) => (!devices?.length ? throwError({ name: 'NoDevicesFound' }) : of(devices))),
      map((devices: MediaDeviceInfo[]) => (this.device = camera || this.getBestDevice(devices))),
      switchMap((device: MediaDeviceInfo | undefined) =>
        !!device
          ? this.getStream({ video: { deviceId: device.deviceId, width: 1920, height: 1080 } })
          : throwError({ name: 'NoDeviceFound' }),
      ),
      tap(async (stream: MediaStream) => {
        this.stream = stream;
        this.videoElement!.muted = true;
        this.videoElement!.playsInline = true;
        this.videoElement!.autoplay = false;
        this.videoElement!.srcObject = stream;
        await this.videoElement!.play();
        this.ready$.next(true);
      }),
      map((stream: MediaStream) => {
        const track: MediaStreamTrack = stream.getVideoTracks()[0];
        const settings: MediaTrackSettings = track.getSettings();
        canvas.width = settings.width as number;
        canvas.height = settings.height as number;
      }),
      switchMap(() => interval(this.config.scanFrequency || 500)),
      map(() => this.decodeImage(canvas, ctx)),
    );
  }

  private decodeImage(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): string | undefined {
    const width: number = canvas.width;
    const height: number = canvas.height;

    ctx.drawImage(this.videoElement!, 0, 0, width, height);

    const imageData: ImageData = ctx.getImageData(0, 0, width, height);
    const pix = imageData.data;

    // make image greyscale
    for (let i = 0; i < pix.length; i += 4) {
      const gray = pix[i] * 0.3 + pix[i + 1] * 0.59 + pix[i + 2] * 0.11;
      pix[i] = gray;
      pix[i + 1] = gray;
      pix[i + 2] = gray;
    }
    ctx.putImageData(imageData, 0, 0);

    const buffer = this.api!.createBuffer(width * height * 4);
    this.mod.HEAPU8.set(imageData.data, buffer);
    const results = [];
    if (this.api!.triggerDecode(buffer, width, height) > 0) {
      const resultAddress = this.api!.getScanResults();
      results.push(this.mod.UTF8ToString(resultAddress));
      this.api!.deleteBuffer(resultAddress);
    }
    if (results.length > 0) return results[0];
    else return undefined;
  }

  private isWebAssemblySupported(): boolean {
    try {
      if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
        const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
        if (module instanceof WebAssembly.Module) return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
      }
    } catch (_) {}
    return false;
  }

  private getStream(constraints: MediaStreamConstraints): Observable<MediaStream> {
    return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(first());
  }

  public getVideoDevices(): Observable<MediaDeviceInfo[]> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      first(),
      map((devices: MediaDeviceInfo[]) => devices.filter((device: MediaDeviceInfo) => ['video', 'videoinput'].includes(device.kind))),
      map((devices: MediaDeviceInfo[]) =>
        devices.map((device: MediaDeviceInfo, index: number) => {
          const kind = 'videoinput';
          const deviceId = device.deviceId || (device as any).id;
          const label = device.label || `Video device ${index}`;
          const groupId = device.groupId;

          return { ...device, deviceId, label, kind, groupId };
        }),
      ),
    );
  }

  /**
   * Takes the last camera offering "environment"
   *
   * @todo Checks could be extended, to check the track capabilities for `focusMode`
   */
  private getBestDevice(devices: MediaDeviceInfo[]): MediaDeviceInfo | undefined {
    return (
      devices.reverse().find((device: MediaDeviceInfo) => /back|trás|rear|rück|traseira|environment|ambiente/gi.test(device.label)) ||
      devices.reverse().pop()
    );
  }
}
