import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BACKLOG_CONFIG, BacklogConfig } from './config/backlog.config';

@NgModule({
  imports: [CommonModule],
})
export class BacklogModule {
  static forRoot(config: BacklogConfig): ModuleWithProviders<BacklogModule> {
    return {
      ngModule: BacklogModule,
      providers: [
        {
          provide: BACKLOG_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
