import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ConnectionService, ConnectionStatusType } from '../../../connection';
import { Hook, HookReturnType } from '../../base';
import { ConnectionChangedHook } from '../models/connection-hooks';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ConnectionHookTriggerService {
  constructor(private connection: ConnectionService) {
    this.connection
      .getStatus()
      .pipe(
        distinctUntilChanged(),
        switchMap((status: ConnectionStatusType) => this.triggerConnectionChange(status)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Hook<ConnectionChangedHook, Observable<ConnectionStatusType>>({
    id: { type: 'ConnectionChangedHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  private triggerConnectionChange(status: ConnectionStatusType): Observable<ConnectionStatusType> {
    return of(status);
  }
}
