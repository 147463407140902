import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CartHeader } from '../model/cart-header.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CartHeaderLocalState<T extends CartHeader> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart-header-local', idKey: 'lngOrderID', resettable: true })
export class CartHeaderLocalStore<T extends CartHeader> extends EntityStore<CartHeaderLocalState<T>> {
  constructor() {
    super();
  }
}
