import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AkitaFilterLocal, AkitaFilterServer, AkitaFiltersPlugin } from 'akita-filters-plugin';
import { groupBy } from 'lodash';
import { UrlHelperService } from '../../core';
import { FilterQuery } from '../query/filter.query';
import { FilterState } from '../store/filter.store';

/**
 * A service to perform CRUD-operations on product entities
 */
@Injectable()
export class FilterService extends AkitaFiltersPlugin<FilterState> {
  /**
   * Creates an instance of product service.
   */
  constructor(protected filterQuery: FilterQuery, private location: Location, private urlHelper: UrlHelperService) {
    super(filterQuery);
  }

  /**
   * sets all the filters by the already existing query params (only on initialization)
   */
  setFiltersByUrlParams(): void {
    super.clearFilters();
    Object.entries(this.urlHelper.getQueryParams()).forEach(([key, value]) => {
      if (key.match(/^_/)) {
        return;
      }

      if (value.match(/;/)) {
        const multiValue = value.split(';');
        multiValue.forEach((innerVal: string) => {
          super.setFilter({
            id: `${key}-${innerVal}`,
            name: key,
            value: innerVal,
          });
        });
      } else if (Array.isArray(value)) {
        value.forEach((innerVal: string) => {
          super.setFilter({
            id: `${key}-${innerVal}`,
            name: key,
            value: innerVal,
          });
        });
      } else {
        super.setFilter({
          id: `${key}-${value}`,
          name: key,
          value: value,
        });
      }
    });
  }

  /**
   * removes a specific filter (serves as a proxy for the removeFilter function from akita-filter-plugin)
   * @param option
   * @param facetName
   */
  removeFilterWithParam(option: string, facetName: string): void {
    const filterToRemove = this.getFilters().find((item) => item.name === facetName && item.value === option);
    if (filterToRemove) {
      super.removeFilter(filterToRemove.id);
    }
    this.updateFilterInUrl();
  }

  /**
   * Create or update a filter
   */
  override setFilter(filter: Partial<AkitaFilterLocal<FilterState> | AkitaFilterServer<FilterState>>): void {
    super.setFilter(filter);
    this.updateFilterInUrl();
  }

  /**
   * clears all Filters and removes them from the query params
   */
  override clearFilters(): void {
    super.clearFilters();
    this.updateFilterInUrl();
  }

  private updateFilterInUrl(): void {
    const currentUrlParams = this.urlHelper.getQueryParams();
    const groupedFilters = groupBy(this.getFilters(), 'name');
    Object.keys(groupedFilters).forEach((item: string) => {
      (groupedFilters as any)[item] = groupedFilters[item].map((filterValue) => filterValue.value);
    });
    Object.keys(currentUrlParams).forEach((key) => {
      if (!key.match(/^_/)) {
        return;
      }
      groupedFilters[key] = currentUrlParams[key];
    });

    // using location.go() here, so routing is not triggered entirely
    this.location.go(this.urlHelper.getPath(), this.urlHelper.objectToQueryParams(groupedFilters));
  }
}
