import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Parameter } from '../model/parameter.model';
import { ParameterState, ParameterStore } from '../store/parameter.store';

@Injectable({ providedIn: 'root' })
export class ParameterQuery extends Query<ParameterState> {
  params$: Observable<Parameter> = this.select('params').pipe(filter((p) => !!p));

  constructor(protected override store: ParameterStore) {
    super(store);
  }
}
