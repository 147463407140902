import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Article } from '../model/article.model';
import { Category } from '../model/category.model';
import { HighlightInterface } from '../services/highlight.interface';

export interface SearchState<T extends Article = Article> {
  articles: T[];
  hits: {
    hits: T[];
    total: number;
  };
  totalArticles: number;
  categories: Category[];
  categorySuggestions: any[];
  articleSuggestions: any[];
  categoryHighlights: HighlightInterface[];
  articleHighlights: HighlightInterface[];
  cmsSuggestions: any[];
  pages: any[];
  searchTerm: string;
}

function createInitialState<T extends Article = Article>(): SearchState<T> {
  return {
    articles: [],
    hits: {
      hits: [],
      total: 0,
    },
    totalArticles: 0,
    categories: [],
    categorySuggestions: [],
    articleSuggestions: [],
    categoryHighlights: [],
    articleHighlights: [],
    cmsSuggestions: [],
    pages: [],
    searchTerm: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'search', resettable: true })
export class SearchStore<T extends Article = Article> extends Store<SearchState<T>> {
  constructor() {
    super(createInitialState());
  }
}
