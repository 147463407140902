import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { TicketTypeStore, TicketTypeState } from '../store/ticket-type.store';
import { TicketType } from '../models/ticket-type.model';

@QueryConfig({
  sortBy: 'id',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class TicketTypeQuery<T extends TicketType> extends QueryEntity<TicketTypeState<T>> {
  constructor(protected override store: TicketTypeStore<T>) {
    super(store);
  }
}
