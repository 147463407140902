import { InjectionToken } from '@angular/core';

export const defaultElasticsearchConfig: ElasticsearchConfig = {
  defaultLanguage: 'de',
  availableLanguages: ['de', 'en'],
};

export interface ElasticsearchConfig {
  defaultLanguage: string;
  availableLanguages: string[];
}

export const ELASTICSEARCH_CONFIG = new InjectionToken<ElasticsearchConfig>('ElasticsearchConfig');
