import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Configuration } from '../model/configuration';
import { ConfigurationExtra } from '../model/configuration-extra';

export interface ConfigurationState<T extends ConfigurationExtra | void = void> extends EntityState<Configuration<T>> {
  configuration: Configuration<T>;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'label-print-configuration' })
export class ConfigurationStore<T extends ConfigurationExtra | void = void> extends EntityStore<ConfigurationState<T>> {
  constructor() {
    super({
      configuration: {
        label: '',
        templateId: 'default',
        offset: 0,
        lines: false,
        showPreview: true,
      },
    });
  }
}
