import { Inject, Injectable } from '@angular/core';
import { GroupByPipe } from 'angular-pipes';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ElasticSearchService, SearchResponse } from '../../core';
import { ArticleAccessory } from '../model/article-accessories.model';
import { Accessory, Article } from '../model/article.model';
import { ArticleAccessoriesStore } from '../store/article-accessories.store';
import { buildArticleAccessoriesQuery } from '../utils/article-accessories.util';
import { ArticleFactory } from './article.factory';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';

@Injectable({ providedIn: 'root' })
export class ArticleAccessoriesService {
  constructor(
    private elasticSearch: ElasticSearchService,
    private store: ArticleAccessoriesStore,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {}

  getArticleAccessories(article: Article): Observable<ArticleAccessory> {
    const query = buildArticleAccessoriesQuery(article.oAccessories);
    return this.elasticSearch
      .execute<SearchResponse<Article>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('article'),
        query: JSON.stringify(query),
      })
      .pipe(
        map((response) => {
          return response.hits.hits.map((article) => {
            return article._source;
          });
        }),
        map((articles: Article[]) => {
          const accessories: any[] = [];
          articles.forEach((accessoryArticle) => {
            const foundArticle = article.oAccessories.find((accessory: Accessory) => accessoryArticle.sArticleID === accessory.sArticleID);
            if (foundArticle) {
              accessories.push({
                sAccessoriesType: foundArticle.sAccessoriesType,
                oArticle: ArticleFactory.create(accessoryArticle),
              });
            }
          });
          return accessories;
        }),
        map((accessories) => {
          const groups = new GroupByPipe().transform(accessories, 'sAccessoriesType');
          return { sArticleID: article.sArticleID, accessories: groups };
        }),
        tap((accessories) => {
          this.store.upsertMany([accessories]);
        }),
      );
  }
}
