import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Contact } from '../model/contact.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContactState<T extends Contact> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'contacts', idKey: 'lngContactId', resettable: true })
export class ContactStore<T extends Contact> extends EntityStore<ContactState<T>> {
  constructor() {
    super();
  }
}
