import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SALES_CREDIT_NOTE_CONFIG, SalesCreditNoteConfig } from './config/sales-credit-note.config';

@NgModule({
  imports: [CommonModule],
})
export class SalesCreditNoteModule {
  static forRoot(config: SalesCreditNoteConfig): ModuleWithProviders<SalesCreditNoteModule> {
    return {
      ngModule: SalesCreditNoteModule,
      providers: [
        {
          provide: SALES_CREDIT_NOTE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
