import { Inject, Injectable } from '@angular/core';
import { SalesCreditNoteItemStore } from '../store/sales-credit-note-item.store';
import { SalesCreditNoteItem } from '../model/sales-credit-note-item.model';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SALES_CREDIT_NOTE_CONFIG, SalesCreditNoteConfig } from '../config/sales-credit-note.config';
import { PaginationResponseInterface } from '../../core';
import { ID } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class SalesCreditNoteItemService<T extends SalesCreditNoteItem> {
  constructor(
    private http: HttpClient,
    private store: SalesCreditNoteItemStore<T>,
    @Inject(SALES_CREDIT_NOTE_CONFIG) private config: SalesCreditNoteConfig,
  ) {}

  get(lngOrderID: number | ID): Observable<T[]> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<PaginationResponseInterface<T>>(this.config.apiUrl + '/sales-credit-notes/' + lngOrderID + '/items/')),
      map((res: PaginationResponseInterface<T>) => {
        return res.data.map((item: T) => {
          return { ...item, id: `${item.lngOrderID}-${item.shtFixedItemID}` };
        });
      }),
      tap((entities: T[]) => {
        this.store.upsertMany(entities);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
