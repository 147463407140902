import { Injectable } from '@angular/core';
import { ShippingAddressStore, ShippingAddressState } from '../store/shipping-address.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { tap } from 'rxjs/operators';
import { ShippingAddress } from '../model/shipping-address.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShippingAddressService<T extends ShippingAddress> extends NgEntityService<ShippingAddressState<T>> {
  constructor(store: ShippingAddressStore<T>) {
    super(store);
  }

  getSimple(): Observable<T[]> {
    return this.getHttp()
      .get<T[]>(this.getConfig().baseUrl + '/shipping-addresses')
      .pipe(
        tap((response: any) => {
          this.store.set(response.data);
        }),
      );
  }
}
