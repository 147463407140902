import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Article } from '../model/article.model';
import { ArticleFactory } from '../services/article.factory';
import { ArticleState, ArticleStore } from '../store/article.store';

@Injectable({
  providedIn: 'root',
})
export class ArticleQuery<T extends Article> extends QueryEntity<ArticleState<T>> {
  constructor(protected override store: ArticleStore<T>) {
    super(store);
  }

  override selectEntity(sArticleID: any): Observable<T> {
    return super.selectEntity<T>(sArticleID).pipe(
      filter((article: T | undefined) => !!article),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((article: T | undefined) => ArticleFactory.create(article!) as T),
    );
  }
}
