import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, skip, switchMap } from 'rxjs/operators';
import { Hook, HookReturnType } from '../../base';
import { LanguageChangedHook } from '../models/language-hooks';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageHookTriggerService {
  constructor(private transloco: TranslocoService) {
    this.transloco.langChanges$
      .pipe(
        skip(1), // Transloco first triggers the defaultLang, before we set any language
        distinctUntilChanged(),
        switchMap((lang: string) => this.triggerLanguageChange(lang)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Hook<LanguageChangedHook, Observable<string>>({
    id: { type: 'LanguageChangedHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  private triggerLanguageChange(lang: string): Observable<string> {
    return of(lang);
  }
}
