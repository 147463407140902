import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessService } from '../services/access.service';

@Pipe({
  name: 'hasAccess',
})
export class HasAccessPipe implements PipeTransform {
  constructor(private accessService: AccessService) {}

  public transform(id: string | boolean | undefined): Observable<boolean> {
    return this.accessService.hasAccess(id);
  }
}
