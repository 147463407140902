import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ElasticSearchService, MultiSearchResponse } from '../../core';
import { CATALOG_CONFIG, CatalogConfig, PageViewConfig } from '../config/catalog.config';
import { Article } from '../model/article.model';
import { Feature } from '../model/product.model';
import { SortByType } from '../model/sort-by.type';
import { ProductQuery } from '../query/product.query';
import { ArticleFacetedSearchStore } from '../store/article-faceted-search.store';
import { buildArticleFacetedMultiSearchQuery } from '../utils/article-faceted-search.util';
import { FacetedSearchAbstract } from './abstract/faceted-search.abstract';
import { ArticleFactory } from './article.factory';
import { FilterService } from './filter.service';
import { isEqual, isEqualWith, PropertyName } from 'lodash';
import { CachedSearchHits } from '../store/faceted-search-result.store';

type CachedArticles = CachedSearchHits<Article, PageViewConfig>;

@Injectable()
export class ArticleFacetedSearchService extends FacetedSearchAbstract {
  constructor(
    private elasticSearch: ElasticSearchService,
    private store: ArticleFacetedSearchStore,
    private productQuery: ProductQuery,
    private filterService: FilterService,
    private translocoService: TranslocoService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super();

    this.setDefaultSort('title');
  }

  search(slug: string, queryFilters: any, sortBy?: SortByType): Observable<MultiSearchResponse> {
    this.store.setLoading(true);
    const query = buildArticleFacetedMultiSearchQuery(
      slug,
      queryFilters,
      200,
      0,
      this.getSortMapping(sortBy || this.getDefaultSort()),
      this.elasticSearch.localizedIndex('article'),
    );
    return this.elasticSearch
      .execute<MultiSearchResponse>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry: any) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        tap((response: MultiSearchResponse) => {
          this.doMappings(response);
        }),
        tap(() => this.store.setLoading(false)),
      );
  }

  searchByFiltersCached(slug: string, queryFilters: any, viewConfig: PageViewConfig): Observable<Article[]> {
    const storedValues = this.store.getValue();
    const cachedHits: CachedArticles[] = storedValues.cachedHits || [];
    const filteredHits: CachedArticles[] = cachedHits.filter((hit: CachedArticles) => hit.id === slug);

    if (filteredHits.length) {
      const hit: CachedArticles = filteredHits[0];
      if (isEqual(viewConfig, hit.config) && isEqual(queryFilters, hit.queryFilters)) {
        return of(hit.hits);
      }

      // if anything but page size changed, reset store
      if (
        !isEqualWith(viewConfig, hit.config, (_: PageViewConfig, _2: PageViewConfig, key?: PropertyName) =>
          key === 'pageSize' ? true : undefined,
        ) ||
        !isEqual(queryFilters, hit.queryFilters)
      ) {
        this.store.update({
          cachedHits: [...cachedHits.filter((cachedHit: CachedArticles) => cachedHit.id !== slug)],
        });
      }
    }

    this.store.setLoading(true);
    const query = buildArticleFacetedMultiSearchQuery(
      slug,
      queryFilters,
      viewConfig.pageSize,
      viewConfig.pageIndex,
      this.getSortMapping(viewConfig.sortBy || this.getDefaultSort()),
      this.elasticSearch.localizedIndex('article'),
    );
    return this.elasticSearch
      .execute<MultiSearchResponse>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry: any) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        map((response: MultiSearchResponse) => {
          this.doMappings(response);

          return this._setCachedHits(cachedHits, slug, viewConfig, response.responses[0].hits, queryFilters);
        }),
        tap(() => this.store.setLoading(false)),
      );
  }

  /** saves the hits to the store */
  private _setCachedHits(cachedHits: CachedArticles[], slug: string, config: PageViewConfig, hits: any, queryFilters: any): Article[] {
    const articles = hits.hits.map((product: { _source: { oArticles: Article[] }; _score: string }) => {
      return { score: product._score, ...product._source };
    });

    this.store.update({
      cachedHits: [
        ...cachedHits.filter((cachedHit: CachedArticles) => cachedHit.id !== slug),
        { hits: articles, id: slug, totalHits: hits.total.value, config, queryFilters },
      ],
    });

    return articles;
  }

  /** Calls all other mapping functions **/
  private doMappings(response: MultiSearchResponse): void {
    this._setArticles(response.responses[0].hits);
    this._setFacets(response.responses[0].aggregations, response.responses[1]?.hits?.hits[0]?._source?.oProductInfo[0]?.oFeatureClass);
  }

  private _setFacets(aggregations: any, featureClass: any) {
    const fullAggregations = this._transformAggregations(aggregations.agg_full.filtered);
    const filteredAggregations = this._transformAggregations(aggregations.agg_filtered.filtered);
    const facets = this._buildFacets(fullAggregations, filteredAggregations, featureClass);
    this.store.update({ facets });
  }

  /** saves the hits to the store */
  private _setArticles(hits: any) {
    const articles: Article[] = hits.hits
      .map((hit: { _source: Article; _score: string }) => ({ score: hit._score, ...hit._source }))
      .map((article: Article) => ArticleFactory.create(article));
    this.store.update({ articles });
    this.store.update({ totalHits: hits.total.value });
  }

  private _transformAggregations(aggregations: any) {
    return aggregations.attr_name.buckets.map((facet: any) => {
      const values = facet.attr_value.buckets.map((option: any) => {
        return { key: option.key, count: option.doc_count, disabled: false };
      });
      let newFacet = {
        key: facet.key,
        values,
        shtRepresentationType: 1,
        shtWebshopFilter: 1,
      };
      if (facet.feature && facet.feature.hits.hits.length > 0) {
        const featureConfig: Partial<Feature> = facet.feature.hits.hits[0]._source;
        newFacet = {
          ...newFacet,
          shtRepresentationType: featureConfig.shtRepresentationType as any,
          shtWebshopFilter: featureConfig.shtWebshopFilter as any,
        };
      }

      return newFacet;
    });
  }

  private _buildFacets(fullAggregations: any, filteredAggregations: any, featureClass: any) {
    let facets = fullAggregations.map((facetFull: any) => {
      const facetFiltered = filteredAggregations.find((item: any) => item.key === facetFull.key);
      let newValues: any[];

      if (facetFiltered) {
        newValues = facetFull.values.map((option: any) => {
          const optionFiltered = facetFiltered.values.find((item: any) => item.key === option.key);
          let optionCopy: any;
          if (optionFiltered) {
            optionCopy = { ...option, count: optionFiltered.count };
          } else {
            optionCopy = { ...option, disabled: true, count: 0 };
          }

          return !this.isFirstFilter(facetFull.key) ? optionCopy : { ...optionCopy, disabled: false, count: option.count };
        });
      } else {
        newValues = facetFull.values.map((option: any) => {
          return { ...option, disabled: true, count: 0 };
        });
      }

      newValues = newValues.map((facetOption: any) => {
        return {
          ...facetOption,
          isActive: this.isActiveFilter(facetFull.key, facetOption.key),
        };
      });

      return {
        ...facetFull,
        values: newValues.sort((a: any, b: any) => a.key.localeCompare(b.key, this.translocoService.getActiveLang(), { numeric: true })),
      };
    });

    if (featureClass?.length > 0) {
      facets = this.reduceFacetsByFeatureClass(facets, featureClass);
      facets = this.addPositionFromFeatureClass(facets, featureClass);
    }

    return facets.sort((a: any, b: any) => {
      if (a?.shtFCFPos != b?.shtFCFPos) {
        return a.shtFCFPos - b.shtFCFPos;
      }
      return a.key > b.key ? 1 : a.key === b.key ? 0 : -1;
    });
  }

  private reduceFacetsByFeatureClass(facets: any, featureClass: any) {
    return facets.filter((facet: any) => featureClass.find((fClass: any) => fClass.sName === facet.key));
  }

  private addPositionFromFeatureClass(facets: any, featureClass: any) {
    return facets.map((facet: any) => {
      return { ...facet, shtFCFPos: featureClass.find((fclass: any) => fclass.sName === facet.key)?.shtFCFPos };
    });
  }

  isActiveFilter(key: any, value: any) {
    return !!this.filterService.getFilters().find((filter) => filter.name === key && filter.value === value);
  }

  isFirstFilter(key: any) {
    return this.filterService.getFilters().length > 0 ? this.filterService.getFilters()[0].name === key : false;
  }
}
