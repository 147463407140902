import { Observable } from 'rxjs';
import { LogService } from '../../../error';
import { InjectorClass } from '../../../util';
import { HookId } from '../models/hook-id';
import { HookReturnType } from '../models/hook-return-type';
import { HookHandlerFactoryService } from '../services/hook-handler-factory.service';

interface DecoratorOptions<E extends HookId> {
  /**
   * Identification of this event
   */
  id: E;

  /**
   * Type check the response. This will prevent future errors.
   * @since TS 4.0
   * @todo Find a way in the future, to detect the type automatically.
   * @todo With the current version of TS, that's not possible. Maybe it never will be.
   */
  returnType: HookReturnType;
}

/**
 * This decorator can be used to hook onto any method.
 *
 * Be aware, that hooking into synced methods should only be handled by synced hooks,
 * since the caller of the method might not expect an async result.
 * Use `returnType` to get warnings about mismatched sync/async
 */
export function Hook<E extends HookId, T>(options: DecoratorOptions<E>): MethodDecorator {
  return (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const originalMethod: (...args: any[]) => any = descriptor.value;
    descriptor.value = function (...args: any[]): any {
      const hookHandler: HookHandlerFactoryService | undefined = InjectorClass.inject<HookHandlerFactoryService>(HookHandlerFactoryService);
      const logService: LogService | undefined = InjectorClass.inject<LogService>(LogService);

      if (!hookHandler || !logService) {
        return;
      }

      const returnValue: T = hookHandler.handle<E, T>({
        id: options.id,
        callable: originalMethod,
        host: this,
        params: args.filter(Boolean),
      });

      if (!options.returnType) {
        return returnValue;
      }

      const isPromise: boolean = returnValue instanceof Promise;
      const isObservable: boolean = returnValue instanceof Observable;

      if (options.returnType === HookReturnType.PRIMITIVE && (isPromise || isObservable)) {
        logService.warn('@Hook: Primitive return type expected, but got async type!', returnValue);
      }

      if (options.returnType === HookReturnType.PROMISE && !isPromise) {
        logService.warn('@Hook: Promise return type expected, but got something else!', returnValue);
      }

      if (options.returnType === HookReturnType.OBSERVABLE && !isObservable) {
        logService.warn('@Hook: Observable return type expected, but got something else!', returnValue);
      }

      return returnValue;
    };

    return descriptor;
  };
}
