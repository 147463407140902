import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { InjectorClass } from '../../util';
import { LanguageHookTriggerService } from './services/language-hook-trigger.service';

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class LanguageHookModule {
  constructor(injector: Injector) {
    InjectorClass.set(injector);
    injector.get<LanguageHookTriggerService>(LanguageHookTriggerService);
  }

  static forRoot(): ModuleWithProviders<LanguageHookModule> {
    return {
      ngModule: LanguageHookModule,
      providers: [],
    };
  }
}
