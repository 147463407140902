import { InjectionToken } from '@angular/core';

export interface ParameterConfig {
  apiUrl?: string;
}

export const defaultParameterConfig: ParameterConfig = {
  apiUrl: '/p48apihost',
};

export const PARAMETER_CONFIG = new InjectionToken<ParameterConfig>('ParameterConfig');
