import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UrlHelperService } from '../../core';
import { ArticleFacetedSearchState, FacetedSearchResultStore } from '../store/faceted-search-result.store';
import { PageViewFilterAbstract } from './abstract/page-view-filter.abstract';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';

/**
 * Handles page view related tasks
 * E.g. page size, sort, view mode, etc.
 */
@Injectable()
export class SearchPageViewFilterService extends PageViewFilterAbstract<FacetedSearchResultStore, ArticleFacetedSearchState> {
  constructor(
    store: FacetedSearchResultStore,
    location: Location,
    urlHelper: UrlHelperService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super(store, location, urlHelper);
  }

  /**
   * Reset the store values by checking the url params
   */
  public override resetByUrlParams() {
    super.resetByUrlParams({ ...this.catalogConfig, ...this.defaults });
  }
}
