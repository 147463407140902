import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { TranslocoDatePipe, TranslocoDecimalPipe } from '@ngneat/transloco-locale';
import { defaultLabelPrintConfig, LABEL_PRINT_CONFIG, LabelPrintConfig } from './config/label-print.config';
import { FavoriteMapper } from './services/mapper/favorite.mapper';
import { MapperAbstract } from './services/mapper/mapper.abstract';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'label-print', alias: 'label-print' } }],
})
export class LabelPrintModule {
  static forRoot(config?: LabelPrintConfig): ModuleWithProviders<LabelPrintModule> {
    return {
      ngModule: LabelPrintModule,
      providers: [
        TranslocoDatePipe,
        TranslocoDecimalPipe,
        config?.mapper || { provide: MapperAbstract, useClass: FavoriteMapper },
        {
          provide: LABEL_PRINT_CONFIG,
          useValue: { ...defaultLabelPrintConfig, ...config },
        },
      ],
    };
  }
}
