import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { CATALOG_CONFIG, CatalogConfig, defaultCatalogConfig } from './config/catalog.config';

@NgModule({
  imports: [CommonModule, TranslocoModule],
})
export class CatalogModule {
  static forRoot(config: CatalogConfig): ModuleWithProviders<CatalogModule> {
    return {
      ngModule: CatalogModule,
      providers: [
        {
          provide: CATALOG_CONFIG,
          useValue: { ...defaultCatalogConfig, ...config },
        },
      ],
    };
  }
}
