import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ErrorInterface, ErrorService } from '../../../error';
import { Hook, HookReturnType } from '../../base';
import { ErrorHook } from '../models/error-hooks';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ErrorHookTriggerService {
  constructor(private errorService: ErrorService) {
    this.errorService
      .listen()
      .pipe(
        distinctUntilChanged(),
        switchMap((error: ErrorInterface) => this.triggerError(error)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Hook<ErrorHook, Observable<ErrorInterface>>({
    id: { type: 'ErrorHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  private triggerError(error: ErrorInterface): Observable<ErrorInterface> {
    return of(error);
  }
}
