import { Injectable } from '@angular/core';
import { ContactState, ContactStore } from '../store/contact.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { tap } from 'rxjs/operators';
import { Contact } from '../model/contact.model';

@Injectable({ providedIn: 'root' })
export class ContactService<T extends Contact> extends NgEntityService<ContactState<T>> {
  constructor(protected override store: ContactStore<T>) {
    super(store);
  }

  getAll() {
    return this.getHttp()
      .get<T[]>(this.getConfig().baseUrl + '/contacts/all')
      .pipe(
        tap((entities: T[]) => {
          this.store.set(entities);
        }),
      );
  }

  getAllByCustomerId(lngCustomerId: string) {
    return this.getHttp()
      .get<T[]>(this.getConfig().baseUrl + '/sales-rep/customer-contacts/' + lngCustomerId)
      .pipe(tap((entities: T[]) => this.store.set(entities)));
  }
}
