import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../../auth';
import { User } from '../../../../user';
import { AuthChangedHook } from '../../auth';
import { Hook, HookReturnType } from '../../base';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthHookTriggerService<T extends User = User> {
  constructor(private auth: AuthService<T>) {
    this.auth.authUser$
      .pipe(
        distinctUntilChanged((a?: T, b?: T) => isEqual(a, b)),
        switchMap((user?: T) => this.triggerAuthChange(user)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Hook<AuthChangedHook, Observable<T>>({
    id: { type: 'AuthChangedHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  private triggerAuthChange(user?: T): Observable<T | undefined> {
    return of(user);
  }
}
