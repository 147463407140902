import { ID } from '@datorama/akita';

/**
 * Favorite list
 */
export interface FavoriteList {
  /** favorite list id */
  gListID: ID;
  /** favorite list name */
  sListname: string;
  /** favorite list creation timestamp */
  dtP48EntryDate: number;
}

/**
 * A factory function that creates FavoriteList
 */
export function createFavoriteList<T extends FavoriteList>({ sListname = 'Merkliste' }) {
  return {
    sListname,
  } as T;
}
