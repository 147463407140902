import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

/**
 * Intercepts all cms requests and appends the current locale
 */
@Injectable({ providedIn: 'root' })
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translocoService: TranslocoService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Only intercept cms urls
    if (!req.url.match(/^\/cms\//i)) {
      return next.handle(req);
    }

    if (!!req.headers.get('ignoreLocale')) {
      return next.handle(req);
    }

    return next.handle(req.clone({ params: req.params.append('_locale', this.translocoService.getActiveLang()) }));
  }
}
