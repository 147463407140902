import { guid, ID } from '@datorama/akita';

export interface LocalSearchString {
  id: ID;
  searchTerm: string;
  entryDate: Date;
}

export function createLocalSearchString({ id = guid(), searchTerm = '', entryDate = new Date() }) {
  return {
    id,
    searchTerm,
    entryDate,
  } as LocalSearchString;
}
