import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { TicketPriorityStore, TicketPriorityState } from '../store/ticket-priority.store';
import { TicketPriority } from '../models/ticket-priority.model';

@QueryConfig({
  sortBy: 'id',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class TicketPriorityQuery<T extends TicketPriority> extends QueryEntity<TicketPriorityState<T>> {
  constructor(protected override store: TicketPriorityStore<T>) {
    super(store);
  }
}
