export class HttpEndpointsConstants {
  // Auth
  public static readonly login: string = 'auth/login';
  public static readonly refresh: string = 'auth/refresh-token';
  public static readonly logout: string = 'auth/logout';
  public static readonly register: string = 'register';
  public static readonly requestPassword: string = '/auth/request-pass';
  public static readonly resetPassword: string = '/auth/reset-pass';
  public static readonly changePassword: string = '/auth/new-pass';
}
