import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SteelFigureImage } from '../models/steel-figure-image.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SteelFigureImageState extends EntityState<SteelFigureImage> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'steel-figure-image', idKey: 'sFigureID' })
export class SteelFigureImageStore extends EntityStore<SteelFigureImageState> {
  constructor() {
    super();
  }
}
