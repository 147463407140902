import { Injectable } from '@angular/core';
import { CloudinaryConfig } from '../config/cloudinary.config';

/**
 * A service for building an image path.
 * Should be used statically
 */
@Injectable()
export class ImagePathHelperService {
  public static getImagePath(imagePath: string, cloudinary: CloudinaryConfig) {
    if (!imagePath) {
      return `https://res.cloudinary.com/${cloudinary.cloudName}/image/fetch/${cloudinary.fetchImageUrl}/no-image.png`;
    }

    if (cloudinary.active) {
      return `https://res.cloudinary.com/${cloudinary.cloudName}/image/fetch/w_500,h_500,c_pad/${cloudinary.fetchImageUrl}/${imagePath}`;
    }
    return imagePath;
  }
}
