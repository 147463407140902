import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { LanguageStore, LanguageState } from '../store/language.store';
import { Language } from '../model/language.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'languageName', sortByOrder: Order.ASC })
export class LanguageQuery<T extends Language> extends QueryEntity<LanguageState<T>> {
  constructor(protected override store: LanguageStore<T>) {
    super(store);
  }
}
