import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SALES_ORDER_CONFIG, SalesOrderConfig } from './config/sales-order.config';

@NgModule({
  imports: [CommonModule],
})
export class SalesOrderModule {
  static forRoot(config: SalesOrderConfig): ModuleWithProviders<SalesOrderModule> {
    return {
      ngModule: SalesOrderModule,
      providers: [
        {
          provide: SALES_ORDER_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
