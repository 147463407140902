import { inject, InjectionToken } from '@angular/core';
import { Paginator } from '@datorama/akita';
import { SalesCreditNote } from '../model/sales-credit-note.model';
import { SalesCreditNoteState } from '../store/sales-credit-note.store';
import { SalesCreditNoteQuery } from '../query/sales-credit-note.query';

export const SALES_CREDIT_NOTE_PAGINATOR = new InjectionToken('SalesCreditNotePaginator', {
  providedIn: 'root',
  factory: () => {
    return new Paginator<SalesCreditNoteState<SalesCreditNote>>(inject(SalesCreditNoteQuery)).withControls().withRange();
  },
});
