import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CORE_CONFIG, CoreConfig } from '../../core.config';

/**
 * Offers common breakpoints to reuse within the application.
 * Alls breakpoints are constructed with "max-width". That means,
 * they say if a screen is smaller than that.
 *
 * Examples
 * `mobile` means, that the screen is of mobile size or smaller
 * `tablet` means, that the screen is of tablet size or smaller
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  public readonly mobile: Observable<boolean>;
  public readonly tablet: Observable<boolean>;

  constructor(@Inject(CORE_CONFIG) config: CoreConfig, private breakpointObserver: BreakpointObserver) {
    this.mobile = this.breakpointObserver.observe([`(max-width: ${config.mobileBreakpoint})`]).pipe(
      untilDestroyed(this),
      map((result: BreakpointState) => result.matches),
    );

    this.tablet = this.breakpointObserver.observe([`(max-width: ${config.tabletBreakpoint})`]).pipe(
      untilDestroyed(this),
      map((result: BreakpointState) => result.matches),
    );
  }
}
