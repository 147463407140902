import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RequestDebounceModel } from './request-debounce.model';

export type RequestDebounceState = EntityState<RequestDebounceModel>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'request-debounce',
  idKey: 'id',
  resettable: true,
})
export class RequestDebounceStore extends EntityStore<RequestDebounceState> {
  constructor() {
    super();
  }
}
