import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as jsbarcode from 'jsbarcode';
import { Configuration } from '../../model/configuration';
import { ConfigurationExtra } from '../../model/configuration-extra';
import { Label } from '../../model/label';
import { PaddingBoxInterface } from '../../model/padding-box.interface';
import { Template } from '../../model/template';
import { TemplateRendererInterface } from '../../model/template-renderer.interface';
import { JspdfService } from '../jspdf.service';

@Injectable({
  providedIn: 'root',
})
export abstract class RendererAbstract<T extends ConfigurationExtra | void = void> implements TemplateRendererInterface<T> {
  protected rawLabels: Label[] = [];
  protected labels: Label[] = [];
  protected configuration: Configuration<T> | undefined;

  protected orientation = this.template().orientation;
  protected format = this.template().format;
  protected formatHeight = this.template().format.height;
  protected formatWidth = this.template().format.width;
  protected boxesVertically = this.template().numberOfBoxes.vertical;
  protected boxesHorizontally = this.template().numberOfBoxes.horizontal;
  protected boxWidth = this.template().boxSizes.width;
  protected boxHeight = this.template().boxSizes.height;
  protected offsetVertically = (this.formatHeight - this.boxHeight * this.boxesVertically) / 2;
  protected offsetHorizontally = (this.formatWidth - this.boxWidth * this.boxesHorizontally) / 2;

  constructor(@Inject(DOCUMENT) protected document: any, protected jspdf: JspdfService) {}

  public prepare(labels: Label[], configuration: Configuration<T>): void {
    this.configuration = configuration;

    this.rawLabels = labels;

    this.labels = [];
    // attach empty offset labels
    this.labels = this.labels.concat(Array(+configuration.offset).fill({ type: 'empty' } as Label));
    // this will repeat the labels as often, as `amount` is set
    this.rawLabels
      .filter((rawLabel: Label) => rawLabel.print && rawLabel.amount > 0)
      .forEach((rawLabel: Label, index: number) => {
        if (configuration.showPreview && index > 3) {
          return;
        }

        this.labels = this.labels.concat(Array(rawLabel.amount).fill(rawLabel));
      });

    this.jspdf.startDocument(this.orientation, this.format);
  }

  public async render(): Promise<void> {
    await this.drawBoxes();
  }

  public url(): URL {
    return this.jspdf.getUrl();
  }

  public download(filename: string): void {
    this.jspdf.save(filename);
  }

  /**
   * Takes in dimensions and recalculates then using a padding offset
   */
  protected addPadding(x: number, y: number, width: number, height: number, padding = 2): PaddingBoxInterface {
    return {
      x: x + padding,
      y: y + padding,
      width: width - 2 * padding,
      height: height - 2 * padding,
      padding,
    };
  }

  protected async addImage(path: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!path) {
      return;
    }

    await this.jspdf.drawImage(path, x, y, maxWidth, maxHeight);
  }

  protected async addEan13(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!code) {
      return;
    }

    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    jsbarcode(canvas, code, { format: 'EAN13', width: 7.5, displayValue: false, flat: true, margin: 0 });

    await this.jspdf.drawImage(canvas.toDataURL(), x, y, maxWidth, maxHeight, undefined, 'right');
  }

  /**
   * Draws the grid and delegates the content rendering to `drawData()`
   */
  protected async drawBoxes(counter = 0): Promise<void> {
    const labels: Label[] = this.labels;
    const configuration: Configuration<T> | undefined = this.configuration;

    boxIteration: for (let y = 0; y < this.boxesVertically; y++) {
      for (let x = 0; x < this.boxesHorizontally; x++) {
        if (counter >= labels.length) {
          break boxIteration;
        }

        const realX = x * this.boxWidth + this.offsetHorizontally;
        const realY = y * this.boxHeight + this.offsetVertically;

        if (labels[counter].type !== 'empty') {
          if (configuration!.lines) {
            this.jspdf.drawBorder(realX, realY, this.boxWidth, this.boxHeight);
          }

          await this.drawData(labels[counter], realX, realY);
        }

        counter++;
      }
    }

    if (counter < labels.length) {
      this.jspdf.addPage();
      await this.drawBoxes(counter);
    }
  }

  public abstract template(): Template;

  protected abstract drawData(label: Label, x: number, y: number): Promise<void>;
}
