import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SENTRY_CONFIG, SentryConfig } from './config/sentry.config';
import { SentryService } from './services/sentry.service';

@NgModule({
  imports: [CommonModule],
})
export class SentryModule {
  constructor(private sentryService: SentryService) {
    this.sentryService.setup();
  }

  static forRoot(config: SentryConfig): ModuleWithProviders<SentryModule> {
    return {
      ngModule: SentryModule,
      providers: [
        {
          provide: SENTRY_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
