import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { TicketStore, TicketState } from '../store/ticket.store';
import { Observable } from 'rxjs';
import { Ticket } from '../models/ticket.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'dtAlterationDate', sortByOrder: Order.DESC })
export class TicketQuery<T extends Ticket> extends QueryEntity<TicketState<T>> {
  /** Creates a new instance of ticket query */
  constructor(protected override store: TicketStore<T>) {
    super(store);
  }

  /**
   * Selects open tickets
   */
  selectOpenTickets(): Observable<T[]> {
    return this.selectAll({
      filterBy: (entity) => entity.lngStatus === 1,
      limitTo: 5,
    });
  }

  /**
   * Selects all tickets and filters them by status
   */
  selectTicketsByStatus(status: number): Observable<T[]> {
    return this.selectAll({
      filterBy: (entity) => entity.lngStatus === status,
    });
  }

  /**
   * Selects all tickets and filters them by status, contact, responsible
   */
  selectTicketsFiltered(status = 0, contactID = 0, responsible = '') {
    let tickets: Observable<Ticket[]>;
    if (status !== 0) {
      tickets = this.selectAll({
        filterBy: (entity) => entity.lngStatus === status,
      });
    } else {
      tickets = this.selectAll();
    }
    if (contactID !== 0) {
      tickets = tickets.pipe(map((items) => items.filter((item) => item.lngContactID === contactID)));
    }
    if (responsible !== '') {
      tickets = tickets.pipe(map((items) => items.filter((item) => item.sB22Responsible === responsible)));
    }
    return tickets;
  }
}
