import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TicketType } from '../models/ticket-type.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TicketTypeState<T extends TicketType> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ticket-types', idKey: 'lngTypeID' })
export class TicketTypeStore<T extends TicketType> extends EntityStore<TicketTypeState<T>> {
  constructor() {
    super();
  }
}
