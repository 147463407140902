import { Injectable } from '@angular/core';
import { SalesDocument } from '../model/sales-document.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesDocumentState<T extends SalesDocument> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sales-document', idKey: 'lngDocumentID', resettable: true })
export class SalesDocumentStore<T extends SalesDocument> extends EntityStore<SalesDocumentState<T>> {
  constructor() {
    super();
  }
}
