import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageViewConfig } from '../config/catalog.config';
import { Category } from '../model/category.model';
import { Facet } from '../model/facet.model';
import { Product } from '../model/product.model';
import { CachedHits, CategoryFacetedSearchState, CategoryFacetedSearchStore } from '../store/category-faceted-search.store';

type CachedProducts = CachedHits<Product, PageViewConfig>;

@Injectable()
export class CategoryFacetedSearchQuery extends Query<CategoryFacetedSearchState> {
  category$ = this.select('category');
  facets$ = this.select('facets').pipe(
    map((facets: Facet[]) => {
      return facets.filter((facet: Facet) => facet.shtWebshopFilter === 1);
    }),
  );
  hits$: Observable<Product[]> = this.select('hits');
  cachedHits$: Observable<CachedProducts[]> = this.select('cachedHits');
  pageIndex$ = this.select('pageIndex');
  pageSize$ = this.select('pageSize');
  sortBy$ = this.select('sortBy');
  viewMode$ = this.select('viewMode');
  subLevelCategories$ = this.select('subLevelCategories');
  totalHits$ = this.select('totalHits');
  articleCount$ = this.select('articleCount');

  constructor(protected override store: CategoryFacetedSearchStore) {
    super(store);
  }

  cachedFor(category: Category): Observable<Product[]> {
    return this.cachedHits$.pipe(
      map((hits: CachedProducts[]) => hits.filter((hit: CachedProducts) => hit.id === category.sUrlPath)),
      map((hits: CachedProducts[]) => (hits.length ? hits[0].hits : [])),
    );
  }

  cachedTotalFor(category: Category): Observable<number> {
    return this.cachedHits$.pipe(
      map((hits: CachedProducts[]) => hits.filter((hit: CachedProducts) => hit.id === category.sUrlPath)),
      map((hits: CachedProducts[]) => (hits.length ? hits[0].totalHits : 0)),
    );
  }
}
