import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ConfigurationExtra } from '../model/configuration-extra';
import { ConfigurationState, ConfigurationStore } from '../store/configuration.store';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationQuery<T extends ConfigurationExtra | void = void> extends QueryEntity<ConfigurationState<T>> {
  configuration$ = this.select('configuration');

  constructor(protected override store: ConfigurationStore<T>) {
    super(store);
  }
}
