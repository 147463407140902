import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ShippingTypeState, ShippingTypeStore } from '../store/shipping-type.store';
import { ShippingType } from '../model/shipping-type.model';

@Injectable({ providedIn: 'root' })
export class ShippingTypeQuery<T extends ShippingType> extends QueryEntity<ShippingTypeState<T>> {
  constructor(protected override store: ShippingTypeStore<T>) {
    super(store);
  }
}
