import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TicketStatistic } from '../models/ticket-statistic.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TicketStatisticState<T extends TicketStatistic> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ticket-statistic', idKey: 'sYear' })
export class TicketStatisticStore<T extends TicketStatistic> extends EntityStore<TicketStatisticState<T>> {
  constructor() {
    super();
  }
}
