import { InjectionToken } from '@angular/core';
import { ViewModeType } from '../model/view-mode.type';
import { SortByType } from '../model/sort-by.type';

export interface PageViewConfig {
  sortBy: SortByType;
  viewMode: ViewModeType;
  pageIndex: number;
  pageSize: number;
}

export interface CatalogConfig extends PageViewConfig {
  apiUrl: string;
}

export const defaultPageViewConfig: PageViewConfig = {
  sortBy: 'relevance',
  viewMode: 'grid',
  pageIndex: 0,
  pageSize: 12,
};

export const defaultCatalogConfig: CatalogConfig = {
  apiUrl: '',
  ...defaultPageViewConfig,
};

export const CATALOG_CONFIG = new InjectionToken<CatalogConfig>('CatalogConfig');
