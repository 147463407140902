import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LabelState, LabelStore } from '../store/label.store';

@Injectable({
  providedIn: 'root',
})
export class LabelQuery extends QueryEntity<LabelState> {
  labels$ = this.select('labels');
  filename$ = this.select('filename');

  constructor(protected override store: LabelStore) {
    super(store);
  }
}
