import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CartHeaderState, CartHeaderStore } from '../store/cart-header.store';
import { CartHeader } from '../model/cart-header.model';

@Injectable({ providedIn: 'root' })
export class CartHeaderQuery<T extends CartHeader> extends QueryEntity<CartHeaderState<T>> {
  constructor(protected override store: CartHeaderStore<T>) {
    super(store);
  }
}
