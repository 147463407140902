import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Customer } from '../model/customer.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerState<T extends Customer> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customers', idKey: 'lngCustomerID' })
export class CustomerStore<T extends Customer> extends EntityStore<CustomerState<T>> {
  constructor() {
    super();
  }
}
