import { Injectable } from '@angular/core';
import { HookEvent } from '../models/hook-event';
import { HookHandlerInterface } from '../models/hook-handler.interface';
import { HookId } from '../models/hook-id';
import { HookPriority } from '../models/hook-priority';

@Injectable({
  providedIn: 'root',
})
export abstract class HookHandlerAbstract<E extends HookId, T> implements HookHandlerInterface<E, T> {
  /**
   * @see HookHandlerInterface.ids()
   */
  abstract ids(): E[];

  /**
   * @see HookHandlerInterface.priority()
   */
  priority(): number {
    return HookPriority.DEFAULT;
  }

  /**
   * @see HookHandlerInterface.handle()
   */
  handle(event: HookEvent<E, T>, originalEvent: HookEvent<E, T>): () => T {
    return (): T => {
      const callable: () => T = this.before(event, originalEvent);
      return this.after(event, callable.apply(event.host, event.params as any));
    };
  }

  /**
   * If only a simple "before" hook is needed, you can just overwrite this method.
   */
  before(event: HookEvent<E, T>, originalEvent: HookEvent<E, T>): () => T {
    return event.callable || originalEvent.callable;
  }

  /**
   * If only a simple "after" hook is needed, you can just overwrite this method.
   */
  after(_: HookEvent<E, T>, result: T): T {
    return result;
  }
}
