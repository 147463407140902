import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ELASTICSEARCH_CONFIG, ElasticsearchConfig, defaultElasticsearchConfig } from './config/elasticsearch.config';

@NgModule({
  imports: [CommonModule],
})
export class ElasticsearchModule {
  static forRoot(config?: ElasticsearchConfig): ModuleWithProviders<ElasticsearchModule> {
    return {
      ngModule: ElasticsearchModule,
      providers: [
        {
          provide: ELASTICSEARCH_CONFIG,
          useValue: { ...defaultElasticsearchConfig, ...config },
        },
      ],
    };
  }
}
