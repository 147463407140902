import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CartHeader } from '../model/cart-header.model';

export interface CartHeaderState<T extends CartHeader> extends EntityState<T>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart-header', idKey: 'lngOrderID', resettable: true })
export class CartHeaderStore<T extends CartHeader> extends EntityStore<CartHeaderState<T>> {
  constructor() {
    super();
  }
}
