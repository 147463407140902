import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, from, firstValueFrom, Observable, of, throwError, lastValueFrom } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthLoginHook, AuthLogoutHook, ErrorService, Hook, HookReturnType } from '../../core';
import { LoginEvent, TrackMethod } from '../../tracking';
import { User } from '../../user';
import { AuthRegistrationInterface } from '../interfaces/auth-registration.interface';
import { ResetPasswordInterface } from '../interfaces/auth-reset-password.interface';
import { AuthResponseInterface } from '../interfaces/auth-response.interface';
import { AuthStateEnum } from '../interfaces/auth-state.enum';
import { UnknownType } from '../interfaces/unknown.type';
import { AuthStorageService } from '../storage/auth-storage.service';
import { AuthHttpService } from './auth-http.service';
import { AuthorizationInterface } from '../interfaces/authorization.interface';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthService<T extends User = User> {
  private token: BehaviorSubject<AuthResponseInterface<T> | undefined> = new BehaviorSubject<AuthResponseInterface<T> | undefined>(
    undefined,
  );
  private status: BehaviorSubject<AuthStateEnum> = new BehaviorSubject<AuthStateEnum>(AuthStateEnum.UNKNOWN);
  public isLoggedIn$: Observable<boolean>;
  public authUser$: Observable<T | undefined>;
  public userAuthorization$: Observable<AuthorizationInterface[] | undefined>;

  constructor(
    private errorService: ErrorService,
    private authHttpService: AuthHttpService<T>,
    private authStorageService: AuthStorageService<T>,
  ) {
    this.isLoggedIn$ = this.listen().pipe(
      map((status: AuthStateEnum) => status === AuthStateEnum.AUTHORIZED),
      shareReplay(),
    );
    this.authUser$ = this.token.pipe(map((authResponse: AuthResponseInterface<T> | undefined) => authResponse?.oUser));
    this.userAuthorization$ = this.token.pipe(map((authResponse: AuthResponseInterface<T> | undefined) => authResponse?.oAuthorizations));
  }

  public getToken(): Observable<AuthResponseInterface<T> | undefined> {
    if (this.isUnknown()) {
      this.restoreState();
    }

    return this.token.asObservable();
  }

  // TODO refactor -> use async listen instead!
  /**
   * @deprecated Use getAuthUserAsync() instead!
   */
  public getAuthUser(): T | undefined {
    return this.token.getValue()?.oUser;
  }

  public getAuthUserAsync(): Observable<T | undefined> {
    return this.authUser$;
  }

  public isAuthorized(): boolean {
    return this.status.getValue() === AuthStateEnum.AUTHORIZED;
  }

  public isUnknown(): boolean {
    return this.status.getValue() === AuthStateEnum.UNKNOWN;
  }

  public isLoadingState(): boolean {
    return this.status.getValue() === AuthStateEnum.UNKNOWN || this.status.getValue() === AuthStateEnum.LOADING;
  }

  public isAnonymous(): boolean {
    return this.status.getValue() === AuthStateEnum.ANONYMOUS;
  }

  public listen(): Observable<AuthStateEnum> {
    if (this.isUnknown()) {
      this.restoreState();
    }

    return this.status.asObservable();
  }

  @Hook<AuthLoginHook, Promise<AuthStateEnum>>({ id: { type: 'AuthLoginHook' }, returnType: HookReturnType.PROMISE })
  @TrackMethod<LoginEvent>({
    event: {
      name: 'login',
      payload: { method: 'email' },
    },
  })
  public async login(username: string, password: string): Promise<AuthStateEnum> {
    this.status.next(AuthStateEnum.LOADING);

    return firstValueFrom(
      this.authHttpService.login({ username, password }).pipe(
        first(),
        tap((authResponse: AuthResponseInterface<T>) => this.token.next(authResponse)),
        tap((authResponse: AuthResponseInterface<T>) => this.authStorageService.set(authResponse)),
        tap(() => this.status.next(AuthStateEnum.AUTHORIZED)),
        first(),
        map(() => AuthStateEnum.AUTHORIZED),
        catchError((error: Error) => {
          this.errorService.add({ label: 'AuthService.login()', ...error, error });
          this.status.next(AuthStateEnum.ANONYMOUS);
          return throwError(() => error);
        }),
        untilDestroyed(this),
      ),
    );
  }

  public async refresh(): Promise<AuthStateEnum> {
    this.status.next(AuthStateEnum.LOADING);

    return firstValueFrom(
      this.authHttpService.refresh().pipe(
        first(),
        tap((authResponse: AuthResponseInterface<T>) => this.token.next(authResponse)),
        tap((authResponse: AuthResponseInterface<T>) => this.authStorageService.set(authResponse)),
        tap(() => this.status.next(AuthStateEnum.AUTHORIZED)),
        map(() => AuthStateEnum.AUTHORIZED),
        catchError((error: Error) => {
          this.errorService.add({ label: 'AuthService.refresh()', ...error, error });
          this.status.next(AuthStateEnum.ANONYMOUS);
          return throwError(() => error);
        }),
        untilDestroyed(this),
      ),
    );
  }

  @Hook<AuthLogoutHook, Promise<AuthStateEnum>>({ id: { type: 'AuthLogoutHook' }, returnType: HookReturnType.PROMISE })
  public async logout(onlyLocally = false): Promise<AuthStateEnum> {
    this.status.next(AuthStateEnum.LOADING);

    return firstValueFrom(
      (onlyLocally ? of(undefined) : this.authHttpService.logout()).pipe(
        first(),
        // if an error occurs while logging out remotely, proceed anyway with local token destruction
        catchError((error: Error) => of(this.errorService.add({ label: 'AuthService.logout()', ...error, error }))),
        tap(() => this.token.next(undefined)),
        tap(() => this.authStorageService.remove()),
        tap(() => this.status.next(AuthStateEnum.ANONYMOUS)),

        first(),
        map(() => AuthStateEnum.ANONYMOUS),
        untilDestroyed(this),
      ),
    );
  }

  private restoreState(): void {
    this.status.next(AuthStateEnum.LOADING);

    from(this.authStorageService.get())
      .pipe(
        first(),
        tap((authResponse: AuthResponseInterface<T> | null) => this.token.next(authResponse || undefined)),
        tap((authResponse: AuthResponseInterface<T> | null) =>
          this.status.next(authResponse ? AuthStateEnum.AUTHORIZED : AuthStateEnum.ANONYMOUS),
        ),
        map(() => this.status.getValue()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  updateAuthUser(user: Partial<T>): Observable<T> {
    return this.authUser$.pipe(
      filter((user: T | undefined) => !!user),
      map((authUser: T | undefined) => ({ ...authUser, ...user } as T)),
      map((user: T) => ({ ...this.token.getValue()!, oUser: user })),
      switchMap((token: AuthResponseInterface<T>) => from(this.authStorageService.set(token))),
      map((token: AuthResponseInterface<T>) => token?.oUser),
    );
  }

  changeAuthUser(user: T) {
    this.token.next({ ...this.token.getValue(), oUser: user } as AuthResponseInterface<T>);
  }

  public async register(registrationData: AuthRegistrationInterface): Promise<UnknownType> {
    return lastValueFrom(this.authHttpService.register(registrationData));
  }

  public async checkVatRegNoExists(vatRegNo: string): Promise<UnknownType> {
    return lastValueFrom(this.authHttpService.checkVatRegNoExists(vatRegNo));
  }

  public async requestPassword(username: string): Promise<UnknownType> {
    return lastValueFrom(this.authHttpService.requestPassword(username));
  }

  public async resetPassword(resetPasswordData: ResetPasswordInterface): Promise<UnknownType> {
    return lastValueFrom(this.authHttpService.resetPassword(resetPasswordData));
  }

  public async changePassword(newPassword: string): Promise<UnknownType> {
    return lastValueFrom(this.authHttpService.changePassword(newPassword));
  }
}
