export function buildCatalogQuery() {
  return {
    size: 10000,
    query: { bool: { filter: [{ terms: { 'sType.keyword': ['G', 'K'] } }] } },
    _source: ['sUrlPath', 'lngParentGroup', 'sTitle', 'lngGroup', 'sType', 'lngItemId'],
  };
}

export function buildCatalogByLevelQuery(groupdId: string) {
  return {
    size: 10000,
    query: {
      bool: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  ['lngParentGroup']: {
                    value: groupdId,
                  },
                },
              },
              {
                term: {
                  ['sType.keyword']: {
                    value: 'G',
                  },
                },
              },
            ],
          },
        },
      },
    },
    _source: ['sUrlPath', 'lngParentGroup', 'sTitle', 'lngGroup', 'sType'],
  };
}

export function buildCatalgQueryByGroupId(groupdId: string) {
  return {
    size: 10000,
    query: {
      bool: {
        filter: [
          {
            term: {
              ['lngGroup']: {
                value: groupdId,
              },
            },
          },
        ],
      },
    },
    _source: ['sUrlPath', 'lngParentGroup', 'sTitle', 'lngGroup', 'sType', 'lngItemId'],
  };
}

export function buildCatalgQueryByParentUrlPath(parentUrlPath: string) {
  return {
    size: 10000,
    query: {
      bool: {
        filter: {
          bool: {
            must: [
              {
                regexp: {
                  'sUrlPath.keyword': parentUrlPath + '([^/]+/)',
                },
              },
              {
                term: {
                  'sType.keyword': {
                    value: 'G',
                  },
                },
              },
            ],
            must_not: [
              {
                term: {
                  'sUrlPath.keyword': parentUrlPath,
                },
              },
            ],
          },
        },
      },
    },
    _source: ['sUrlPath', 'lngParentGroup', 'sTitle', 'lngGroup', 'sType', 'lngItemId'],
  };
}
