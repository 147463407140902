import { ErrorHandler, HostListener, Injectable } from '@angular/core';
import { ErrorInterface } from '../models/error.interface';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private errorService: ErrorService) {
    super();
  }

  @HostListener('window:unhandledrejection', [])
  public unhandledRejection(event: unknown) {
    this.handleError(event, true);
  }

  public override handleError(errorEvent: any, unhandled = false): void {
    this.errorService.add({
      label: `ErrorHandlerService.handleError(${unhandled ? 'unhandled' : ''})`,
      ...errorEvent,
      error: { ...errorEvent, label: `ErrorHandlerService.handleError(${unhandled ? 'unhandled' : ''})` },
    } as ErrorInterface);
  }
}
