import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService, InjectorClass } from '../../core';
import { EventTracking } from '../models/event-tracking';
import { TrackingFactory } from '../services/tracking.factory';

/**
 * The event can be tracked statically by providing the event directly,
 * or dynamically by using the `provide` callback.
 *
 * If both are provided, the result will be merged, with the dynamic data having higher priority.
 */
interface DecoratorOptions<T extends EventTracking> {
  event?: Partial<T>;
  provide?: (params: any[], self: any) => Partial<T>;
}

const doTracking = <T extends EventTracking, C = void>(options: DecoratorOptions<T>, args: any[], instance: C): void => {
  const tracking: TrackingFactory | undefined = InjectorClass.inject<TrackingFactory>(TrackingFactory);
  const errorService: ErrorService | undefined = InjectorClass.inject<ErrorService>(ErrorService);

  if (!tracking || !errorService) {
    return;
  }

  let event: T = (options.event || {}) as T;
  if (options.provide) {
    event = { ...event, ...options.provide(args, instance) };
  }

  tracking.trackEvent<T>(event).catch((error: Error) => errorService.add({ label: 'TrackMethod.doTracking()', ...error, error }));
};

/**
 * Method tracking for trigger based events.
 * If the decorated method is returning an error, tracking is skipped.
 */
export function TrackMethod<T extends EventTracking, C = void>(options: DecoratorOptions<T>): MethodDecorator {
  if (!options.event && !options.provide) {
    console.warn('When using @TrackMethod, provide at least one option to determine the event data.');
  }

  return (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const originalMethod: (...args: any[]) => any = descriptor.value;

    descriptor.value = function (...args: any[]): any {
      try {
        const methodReturn: any = originalMethod.apply(this, args);
        if (methodReturn instanceof Promise) {
          return methodReturn.then(() => doTracking<T, C>(options, args, this as C));
        }
        if (methodReturn instanceof Observable) {
          return methodReturn.pipe(tap(() => doTracking<T, C>(options, args, this as C)));
        }

        doTracking<T, C>(options, args, this as C);
        return methodReturn;
      } catch (exception) {}
    };

    return descriptor;
  };
}
