import { Inject, Injectable } from '@angular/core';
import { ERROR_CONFIG, ErrorConfig } from '../config/error.config';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(@Inject(ERROR_CONFIG) private config: ErrorConfig) {}

  private showOutput: boolean = this.config.showOutput;

  public error(message: string, params?: unknown): void {
    if (!this.showOutput) {
      return;
    }

    console.error(message, params);
  }

  public log(message: string, params?: unknown): void {
    if (!this.showOutput) {
      return;
    }

    console.log(message, params);
  }

  public warn(message: string, params?: unknown): void {
    if (!this.showOutput) {
      return;
    }

    console.warn(message, params);
  }
}
