import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { FavoriteItemState, FavoriteItemStore } from '../store/favorite-item.store';
import { Observable } from 'rxjs';
import { FavoriteItem } from '../model/favorite-item.model';

@Injectable({ providedIn: 'root' })
export class FavoriteItemQuery<T extends FavoriteItem> extends QueryEntity<FavoriteItemState<T>> {
  constructor(protected override store: FavoriteItemStore<T>) {
    super(store);
  }

  selectFavoriteItems(favoriteListId: ID): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ gListID }) => gListID === favoriteListId,
    });
  }
}
