import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { SalesOfferItem } from '../model/sales-offer-item.model';
import { SalesOfferItemStore, SalesOfferItemState } from '../store/sales-offer-item.store';

@Injectable({ providedIn: 'root' })
export class SalesOfferItemQuery<T extends SalesOfferItem> extends QueryEntity<SalesOfferItemState<T>> {
  constructor(protected override store: SalesOfferItemStore<T>) {
    super(store);
  }

  selectByOfferId(id: number | ID): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ lngOrderID }) => lngOrderID === id,
    });
  }

  hasItemsByOfferId(id: number | ID): boolean {
    return !!this.getAll({
      filterBy: ({ lngOrderID }) => lngOrderID === id,
    }).length;
  }
}
