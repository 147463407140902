import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ScanNotificationService } from '../scan-notification.service';
import { Article, ArticleService, ScannerAdapterAbstract, TrackingFactory } from '@lobos/library';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
  @ViewChild('preview', { static: true })
  videoContainer: ElementRef<HTMLDivElement> | undefined;

  public scannerReady$: Observable<boolean> = this.scannerAdapter.ready();
  public articles: Article[] | undefined;

  private startScan$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private scannerStream$: Observable<string> | undefined;
  private enabled: boolean = true;
  trackingService = inject(TrackingFactory);

  selectedCamera: MediaDeviceInfo | undefined;
  _cameras: MediaDeviceInfo[] | undefined;
  cameras$: Observable<
    {
      key: string;
      value: string;
    }[]
  > = this.getVideoDevices().pipe(
    tap((devices: MediaDeviceInfo[]) => (this._cameras = devices)),
    map((devices: MediaDeviceInfo[]) =>
      devices.map((device: MediaDeviceInfo) => ({
        key: device.deviceId,
        value: device.label,
      })),
    ),
  );

  constructor(
    private scannerAdapter: ScannerAdapterAbstract,
    private articleService: ArticleService<Article>,
    private scanNotification: ScanNotificationService,
    private router: Router,
    private dialog: SimpleModalService,
  ) {
    super();
    // when navigation happens while scanner is open
    // -> close the scanner
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.stop());

    this.startScan$
      .pipe(
        filter((start: boolean) => start),
        switchMap(() =>
          this.scannerStream$
            ? this.scannerStream$
            : (this.scannerStream$ = this.scannerAdapter.start(this.videoContainer?.nativeElement as HTMLDivElement, this.selectedCamera)),
        ),
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((searchTerm: string | boolean) => !!searchTerm && this.enabled),
        tap(() => {
          this.scanNotification.play();
          this.startScan$.next(false);
          this.enabled = false;
        }),
        tap(() =>
          this.trackingService.trackEvent({
            name: 'scanned_article',
            payload: {},
          }),
        ),
        mergeMap((searchTerm: string | boolean) =>
          this.articleService.getArticleByMultiMatchQuery((searchTerm as string).replace(/\s/g, ''), ['sArticleID', 'sEAN']),
        ),
        untilDestroyed(this),
      )
      .subscribe((articles: Article[]) => (this.articles = articles));
  }

  public ngOnInit(): void {
    if (!this.videoContainer?.nativeElement) {
      return;
    }

    this.startScan$.next(true);
  }

  public rescan(): void {
    this.articles = undefined;
    this.startScan$.next(true);
    this.enabled = true;
  }

  public stop(): void {
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(false);
    this.dialog.removeAll();
  }

  selectCamera(id: string | number) {
    this.selectedCamera = this._cameras?.find((camera: MediaDeviceInfo) => camera.deviceId === id);
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(true);
  }

  ngOnDestroy(): void {
    this.scannerAdapter.stop();
  }

  public getVideoDevices(): Observable<MediaDeviceInfo[]> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      first(),
      map((devices: MediaDeviceInfo[]) => devices.filter((device: MediaDeviceInfo) => ['video', 'videoinput'].includes(device.kind))),
      map((devices: MediaDeviceInfo[]) =>
        devices.map((device: MediaDeviceInfo, index: number) => {
          const kind = 'videoinput';
          const deviceId = device.deviceId || (device as any).id;
          const label = device.label || `Video device ${index}`;
          const groupId = device.groupId;

          return {
            ...device,
            deviceId,
            label,
            kind,
            groupId,
          };
        }),
      ),
    );
  }
}
