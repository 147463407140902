import { InjectionToken } from '@angular/core';

export interface SentryConfig {
  active: boolean;
  release: string;
  debug: boolean;
  environment: string;
  dsn: string;
}

export const SENTRY_CONFIG = new InjectionToken<SentryConfig>('SentryConfig');
