import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { first } from 'rxjs/operators';
import { ParameterService } from './services/parameter.service';
import { defaultParameterConfig, PARAMETER_CONFIG, ParameterConfig } from './config/parameter.config';

export function init_remote_params(parameterService: ParameterService) {
  const result: any = () => parameterService.get().pipe(first()).subscribe();
  return result;
}

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: APP_INITIALIZER, useFactory: init_remote_params, deps: [ParameterService], multi: true }],
})
export class ParameterModule {
  static forRoot(config: ParameterConfig = {}): ModuleWithProviders<ParameterModule> {
    return {
      ngModule: ParameterModule,
      providers: [
        {
          provide: PARAMETER_CONFIG,
          useValue: { ...defaultParameterConfig, ...config },
        },
      ],
    };
  }
}
