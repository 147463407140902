import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { StockAvailability } from '../model/stock-availability.model';

export type StockAvailabilityState = EntityState<StockAvailability>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'storages',
  idKey: 'sArticleID',
  resettable: true,
})
export class StockAvailabilityStore extends EntityStore<StockAvailabilityState> {
  constructor() {
    super();
  }
}
