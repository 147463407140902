import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CORE_CONFIG, CoreConfig } from '../core.config';
import { TranslocoService } from '@ngneat/transloco';

/**
 * An interceptor which adds webshopId and branchKey in header of all requests.
 */
@Injectable()
export class BaseInterceptor implements HttpInterceptor {
  constructor(@Inject(CORE_CONFIG) private coreConfig: CoreConfig, private translocoService: TranslocoService) {}

  /**
   * Interceptor to add webshopId, branchKey and language in header (all requests)
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      headers: request.headers
        .set('webshopId', this.coreConfig.webshopId.toString())
        .set('businessUnit', this.coreConfig.branchKey)
        .set('language', this.translocoService.getActiveLang()),
    });
    return next.handle(request);
  }
}
