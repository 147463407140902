import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CartHeader } from '../model/cart-header.model';
import { CartHeaderLocalState, CartHeaderLocalStore } from '../store/cart-header-local.store';

@Injectable({ providedIn: 'root' })
export class CartHeaderLocalQuery<T extends CartHeader> extends QueryEntity<CartHeaderLocalState<T>> {
  constructor(protected override store: CartHeaderLocalStore<T>) {
    super(store);
  }
}
