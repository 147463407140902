import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Breadcrumb } from '../services/breadcrumb.service';

export class BreadcrumbsResolver implements Resolve<Breadcrumb[]> {
  public resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Breadcrumb[]> {
    const data = route.data;

    if (!data) {
      return of([]);
    }

    const path = this.getFullPath(route);

    return of([{ label: data!['breadcrumbs'], translate: data!['translate'], url: path }]);
  }

  public getFullPath(route: ActivatedRouteSnapshot): string {
    return this.fetchFullPath(route.pathFromRoot);
  }

  private fetchFullPath(routes: ActivatedRouteSnapshot[]): string {
    return routes.reduce((path, route) => path + this.fetchRelativePath(route.url), '');
  }

  private fetchRelativePath(urlSegments: UrlSegment[]): string {
    return urlSegments.reduce((path, urlSegment) => path + '/' + urlSegment.path, '');
  }
}
