import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Facet } from '../model/facet.model';
import { ArticleFacetedSearchStore, CachedArticleHits, ProductFacetedSearchState } from '../store/article-faceted-search.store';
import { Observable } from 'rxjs';
import { Article } from '../model/article.model';
import { PageViewConfig } from '../config/catalog.config';

type CachedArticles = CachedArticleHits<Article, PageViewConfig>;

@Injectable()
export class ArticleFacetedSearchQuery extends Query<ProductFacetedSearchState> {
  articles$ = this.select('articles');
  facets$ = this.select('facets').pipe(
    map((facets: Facet[]) => {
      return facets.filter((facet: Facet) => facet.shtWebshopFilter === 1);
    }),
  );
  totalHits$ = this.select('totalHits');
  cachedHits$: Observable<CachedArticles[]> = this.select('cachedHits');
  pageIndex$ = this.select('pageIndex');
  pageSize$ = this.select('pageSize');
  sortBy$ = this.select('sortBy');
  viewMode$ = this.select('viewMode');

  constructor(protected override store: ArticleFacetedSearchStore) {
    super(store);
  }

  cachedFor(slug: string): Observable<Article[]> {
    return this.cachedHits$.pipe(
      map((hits: CachedArticles[]) => hits.filter((hit: CachedArticles) => hit.id === slug)),
      map((hits: CachedArticles[]) => (hits.length ? hits[0].hits : [])),
    );
  }

  cachedTotalFor(slug: string): Observable<number> {
    return this.cachedHits$.pipe(
      map((hits: CachedArticles[]) => hits.filter((hit: CachedArticles) => hit.id === slug)),
      map((hits: CachedArticles[]) => (hits.length ? hits[0].totalHits : 0)),
    );
  }
}
