import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ErrorInterface, ErrorService, InjectorClass, RecursivePartial } from '../../core';
import { PageMetaInterface } from '../model/page-meta.interface';
import { PageMetaService } from '../services/page-meta.service';

interface DecoratorOptions<T> {
  noTracking?: boolean;
  index?: boolean;
  provide: (caller: T) => Observable<RecursivePartial<PageMetaInterface>>;
}

/**
 * Class decorator for adding page meta
 * This class should primarily being added to Features which will have their own route.
 * By default, the page is set to "index, follow". This can be disabled.
 * By default, the page meta call is also sent to tracking. This can be disabled.
 */
export function PageMeta<T>(options: DecoratorOptions<T>): ClassDecorator {
  return (constructor: any): void => {
    /**
     * Subject to manage unsubscription
     */
    let destroyed: Subject<void> = new Subject<void>();

    const originalPageEnterEvent: () => any = constructor.prototype.ngOnInit;
    const originalPageExitEvent: () => any = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnInit = function (...args: any[]): void {
      destroyed = new Subject<void>();
      const pageMetaService: PageMetaService | undefined = InjectorClass.inject<PageMetaService>(PageMetaService);
      const errorService: ErrorService | undefined = InjectorClass.inject<ErrorService>(ErrorService);

      options
        .provide(this)
        .pipe(
          takeUntil(destroyed),
          catchError((error: Error) => of(errorService!.add({ label: 'PageMeta.ngOnInit()', ...error, error: error }))),
        )
        .subscribe((meta: RecursivePartial<PageMetaInterface> | ErrorInterface) =>
          pageMetaService!.updateMetadata(meta as Partial<PageMetaInterface>, options.index, options.noTracking),
        );

      originalPageEnterEvent && typeof originalPageEnterEvent === 'function' && originalPageEnterEvent.apply(this, args as any);
    };

    constructor.prototype.ngOnDestroy = function (...args: any[]): void {
      destroyed.next();
      destroyed.complete();

      originalPageExitEvent && typeof originalPageExitEvent === 'function' && originalPageExitEvent.apply(this, args as any);
    };
  };
}
