import { Injectable } from '@angular/core';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentMenuLevel } from '../model/cms.models';

/**
 * A service to help with reoccurring tasks
 */
@Injectable({ providedIn: 'root' })
export class CmsHelperService {
  public flattenMenu(menu: CmsUiDynamicContentMenu | CmsUiDynamicContentMenuLevel, level: number = 1): CmsUiDynamicContentMenuLevel[] {
    const selector: string = `level_${level}`;
    const subMenu: CmsUiDynamicContentMenuLevel[] = (menu as any)[selector];
    if (!subMenu) {
      return [];
    }

    const currentLevelItems: CmsUiDynamicContentMenuLevel[] = subMenu;
    let nextLevelItems: CmsUiDynamicContentMenuLevel[] = [];
    for (const nextLevelMenu of subMenu) {
      nextLevelItems = [...nextLevelItems, ...this.flattenMenu(nextLevelMenu, level + 1)];
    }

    return [...currentLevelItems, ...nextLevelItems];
  }
}
