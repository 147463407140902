import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { Customer } from '../model/customer.model';
import { CustomerStore } from '../store/customer.store';

@Injectable({ providedIn: 'root' })
export class CustomerService<T extends Customer> {
  constructor(protected store: CustomerStore<T>, protected http: HttpClient) {}

  getById(id: ID) {
    return this.http.get<T>(`p48apihost/customers/${id}`).pipe(tap((customer: T) => this.store.set([customer])));
  }
}
