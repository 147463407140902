import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { LogService } from '../../../error';
import { HookEvent } from '../models/hook-event';
import { HookHandlerInterface } from '../models/hook-handler.interface';
import { HookId } from '../models/hook-id';

@Injectable({
  providedIn: 'root',
})
export class HookHandlerFactoryService {
  private handlers: HookHandlerInterface<any, any>[] = [];

  constructor(private logService: LogService) {}

  public register(handler: HookHandlerInterface<any, any>): void {
    this.handlers = [...this.handlers, handler];
    this.handlers.sort((a: HookHandlerInterface<any, any>, b: HookHandlerInterface<any, any>) => a.priority() - b.priority());
  }

  /**
   * Passes the hook event to all handlers.
   * If one handler throws an exception, the complete execution is stopped.
   */
  public handle<E extends HookId, T>(event: HookEvent<E, T>): T {
    const originalEvent: HookEvent<E, T> = { ...event };
    try {
      for (const handler of this.filterHandlersByEvent<E, T>(event)) {
        event.callable = handler.handle({ ...event }, originalEvent);
      }
    } catch (error) {
      this.logService.warn('@Hook: Execution canceled!', error);
      event.callable = () => undefined as any;
    }

    return event.callable.apply(event.host, event.params as any);
  }

  private filterHandlersByEvent<E extends HookId, T>(event: HookEvent<E, T>): HookHandlerInterface<E, T>[] {
    return this.handlers.filter((h: HookHandlerInterface<E, T>) => h.ids().find((id: HookId) => isEqual(id, event.id)));
  }
}
