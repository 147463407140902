import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ShippingAddress } from '../model/shipping-address.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ShippingAddressState<T extends ShippingAddress> = EntityState<T>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'shipping-addresses', idKey: 'lngAddressID', resettable: true })
export class ShippingAddressStore<T extends ShippingAddress> extends EntityStore<ShippingAddressState<T>> {
  constructor() {
    super();
  }
}
