import { Inject, Injectable } from '@angular/core';
import { SalesOfferItemStore } from '../store/sales-offer-item.store';
import { SalesOfferItem } from '../model/sales-offer-item.model';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SALES_OFFER_CONFIG, SalesOfferConfig } from '../config/sales-offer.config';
import { PaginationResponseInterface } from '../../core';
import { ID } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class SalesOfferItemService<T extends SalesOfferItem> {
  constructor(
    private http: HttpClient,
    private store: SalesOfferItemStore<T>,
    @Inject(SALES_OFFER_CONFIG) private config: SalesOfferConfig,
  ) {}

  get(lngOrderID: number | ID): Observable<T[]> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<PaginationResponseInterface<T>>(this.config.apiUrl + '/sales-offers/' + lngOrderID + '/items/')),
      map((res: PaginationResponseInterface<T>) => {
        return res.data.map((item: T) => {
          return { ...item, id: `${item.lngOrderID}-${item.shtFixedItemID}` };
        });
      }),
      tap((entities: T[]) => {
        this.store.upsertMany(entities);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
