import { Injectable } from '@angular/core';

export type DateTimeType = number;

@Injectable({ providedIn: 'root' })
export class DateHelperService {
  /**
   * JavaScript working days
   * 1 - Monday
   * 2 - Tuesday
   * 3 - Wednesday
   * 4 - Thursday
   * 5 - Friday
   */
  public static workingDays: number[] = [1, 2, 3, 4, 5];

  /**
   * @alexander_widmer
   * Perfect case for writing an Unit-Test
   */
  public static getDateInWorkingDaysFromNow(daysFromNow = 1): number {
    const today: Date = new Date();
    let daysSinceToday = 0;
    let workingDaysSinceToday = 0;
    while (workingDaysSinceToday < daysFromNow + 1) {
      const dateToCheck = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysSinceToday);
      if (DateHelperService.workingDays.includes(dateToCheck.getDay())) {
        workingDaysSinceToday++;
      }

      daysSinceToday++;
    }

    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysSinceToday).getTime();
  }

  public static getISODate(timestamp: DateTimeType): string {
    const date: Date = new Date(timestamp);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  public static getUTCDate(dateString: string): number {
    const newDate: Date = new Date(dateString);
    return newDate.getTime() - newDate.getTimezoneOffset() * 60000;
  }
}
