import { InjectionToken } from '@angular/core';
import { ErrorFilterInterface } from '../models/error-filter.interface';

export const defaultErrorConfig: ErrorConfig = {
  showOutput: false,
  filters: [],
};

export interface ErrorConfig {
  showOutput: boolean;
  disableErrorHandler?: boolean;
  filters?: ErrorFilterInterface[];
}

export const ERROR_CONFIG = new InjectionToken<ErrorConfig>('ErrorConfig');
