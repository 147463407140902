import { Injectable } from '@angular/core';
import { UnknownType } from '../../../auth/interfaces/unknown.type';

// TODO replace with akita persist by locastorage
@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  public async set(key: string, value: UnknownType): Promise<UnknownType> {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  public async get<T>(key: string): Promise<T | null> {
    return JSON.parse(localStorage.getItem(key) as string) as T;
  }

  public async remove(key: string): Promise<void> {
    return localStorage.removeItem(key);
  }
}
