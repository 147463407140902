import { Injectable } from '@angular/core';
import { EntityState, EntityStore, ID, QueryEntity } from '@datorama/akita';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { RequestDebounceModel } from './request-debounce.model';
import { RequestDebounceQuery } from './request-debounce.query';
import { RequestDebounceStore } from './request-debounce.store';

@Injectable({
  providedIn: 'root',
})
export class RequestDebounceService {
  constructor(private store: RequestDebounceStore, private query: RequestDebounceQuery) {}

  debounce<T, S extends EntityStore<EntityState<T>>, Q extends QueryEntity<EntityState<T>>>(
    id: ID,
    store: S,
    query: Q,
    observable: Observable<T>,
  ): Observable<T | undefined> {
    if (query.hasEntity(id)) {
      return query.selectEntity(id);
    }

    return this._debounce<T, S>(id, store, observable);
  }

  debounceAll<T, S extends EntityStore<EntityState<T>>>(store: S, observable: Observable<T[]>): Observable<T[]> {
    return this._debounce<T[], S>('all', store, observable, true);
  }

  private _debounce<T, S extends EntityStore>(id: ID, store: S, observable: Observable<T>, multi: boolean = false): Observable<T> {
    const debounceId: string = `${store.storeName}_${id.toString()}`;
    if (this.query.hasEntity(debounceId)) {
      const cache: RequestDebounceModel = this.query.getEntity(debounceId)!;
      if (['done', 'loading'].includes(cache.state!)) {
        return cache.observable;
      }
    }

    const state: RequestDebounceModel = {
      id: debounceId,
      entityId: id.toString(),
      store: store.storeName,
      idKey: store.idKey,
      state: 'loading',
      observable: observable.pipe(
        tap((result: T | T[]) => {
          if (multi) {
            store.upsertMany(result as T[]);
          } else {
            store.upsert(id, result as T);
          }

          this.store.update(debounceId, { state: 'done' });
        }),
        catchError((error: Error) => {
          this.store.update(debounceId, { state: 'error' });

          return throwError(() => error);
        }),
        shareReplay(1),
      ),
    };

    this.store.upsert(debounceId, state);
    return state.observable;
  }
}
