import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InjectorClass } from '../../util';
import { ConfigurableError } from '../models/error.interface';
import { ErrorService } from '../services/error.service';

interface CatchDecoratorOptions extends ConfigurableError {
  /**
   * Track all (boolean) or a specific (key as string) parameter
   */
  trackParam?: string | boolean;
}

export function Catch(options: CatchDecoratorOptions): MethodDecorator {
  return (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const originalMethod: (...args: any[]) => any = descriptor.value;

    descriptor.value = function (...args: any[]): any {
      let params: any;
      if (args.length) {
        if (options.trackParam === true) {
          params = JSON.stringify(args[0]);
        } else if (options.trackParam) {
          params = JSON.stringify(args[0][options.trackParam]);
        }
      }

      const errorService: ErrorService | undefined = InjectorClass.inject<ErrorService>(ErrorService);
      if (!errorService) {
        return;
      }

      try {
        const methodReturn: any = originalMethod.apply(this, args);
        if (methodReturn instanceof Promise) {
          return methodReturn.catch((error: Error) => errorService.add(ErrorService.mapError(error, options, params)));
        }
        if (methodReturn instanceof Observable) {
          return methodReturn.pipe(catchError((error: Error) => of(errorService.add(ErrorService.mapError(error, options, params)))));
        }

        return methodReturn;
      } catch (exception) {
        errorService.add(ErrorService.mapError(exception as any, options, params));
      }
    };

    return descriptor;
  };
}
