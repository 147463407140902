import { ElasticSearchSortInterface } from '../../core/elasticsearch/model/elastic-search-sort.interface';
import { SortByType } from '../model/sort-by.type';
import { filtersToSearchQuery } from './faceted-search-result.util';

export function buildFacetedBrandMultiSearchQuery(
  featureID: number,
  featureValue: string,
  queryFilters: any[],
  size = 12,
  page = 1,
  sortBy: SortByType | ElasticSearchSortInterface[] = 'relevance',
  articleIndex: string,
) {
  const sort = Array.isArray(sortBy) ? sortBy : sortBy === 'title' ? [{ 'sName.keyword': 'asc' }] : [];

  return [
    { index: articleIndex },
    {
      from: page,
      size: size,
      sort: sort,
      query: {
        nested: {
          path: 'oFeatures',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'oFeatures.lngFeatureID': featureID,
                  },
                },
                {
                  term: {
                    'oFeatures.sValue.keyword': featureValue,
                  },
                },
              ],
            },
          },
        },
      },
      post_filter: {
        bool: {
          must: [...filtersToSearchQuery(queryFilters, 'oFeatures')],
        },
      },
      aggs: {
        agg_full: {
          nested: {
            path: 'oFeatures',
          },
          aggs: {
            attr_name: {
              terms: {
                field: 'oFeatures.sName.keyword',
              },
              aggs: {
                feature: {
                  top_hits: {
                    size: 1,
                    _source: {
                      include: ['oFeatures.shtRepresentationType', 'oFeatures.shtWebshopFilter'],
                    },
                  },
                },
                attr_value: {
                  terms: {
                    field: 'oFeatures.sValue.keyword',
                  },
                  aggs: {
                    option: {
                      top_hits: {
                        size: 1,
                        _source: {
                          include: ['oFeatures.shtPos'],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        agg_filtered: {
          filter: {
            bool: {
              must: [
                {
                  nested: {
                    path: 'oFeatures',
                    query: {
                      bool: {
                        must: [
                          {
                            term: {
                              'oFeatures.lngFeatureID': featureID,
                            },
                          },
                          {
                            term: {
                              'oFeatures.sValue.keyword': featureValue,
                            },
                          },
                        ],
                      },
                    },
                  },
                },
                ...filtersToSearchQuery(queryFilters, 'oFeatures'),
              ],
            },
          },
          aggs: {
            filtered: {
              nested: {
                path: 'oFeatures',
              },
              aggs: {
                attr_name: {
                  terms: {
                    field: 'oFeatures.sName.keyword',
                  },
                  aggs: {
                    attr_value: {
                      terms: {
                        field: 'oFeatures.sValue.keyword',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  ];
}
