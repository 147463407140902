import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Role } from '../model/role.model';
import { RoleQuery } from '../query/role.query';
import { RoleState, RoleStore } from '../store/role.store';

@Injectable({ providedIn: 'root' })
export class RoleService<T extends Role> extends NgEntityService<RoleState<T>> {
  private loading: boolean = false;

  constructor(protected query: RoleQuery<T>, protected override store: RoleStore<T>) {
    super(store);
  }

  override get(): Observable<T[]> {
    if (this.loading) {
      return this.query.selectAll();
    }
    this.loading = true;
    return this.getHttp()
      .get<T[]>('p48apihost/roles')
      .pipe(
        tap((roles: T[]) => this.store.set(roles)),
        finalize(() => (this.loading = false)),
      );
  }
}
