import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CloudinaryImagePathPipe } from './pipes/cloudinary-image-path.pipe';
import { CLOUDINARY_CONFIG, CloudinaryConfig } from './config/cloudinary.config';

@NgModule({
  imports: [CommonModule],
  declarations: [CloudinaryImagePathPipe],
  exports: [CloudinaryImagePathPipe],
})
export class CloudinaryModule {
  static forRoot(config: CloudinaryConfig): ModuleWithProviders<CloudinaryModule> {
    return {
      ngModule: CloudinaryModule,
      providers: [
        {
          provide: CLOUDINARY_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
