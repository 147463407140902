import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SearchState, SearchStore } from '../store/search.store';
import { Article } from '../model/article.model';

@Injectable({
  providedIn: 'root',
})
export class SearchQuery<T extends Article = Article> extends Query<SearchState<T>> {
  articles$ = this.select('articles');
  categories$ = this.select('categories');
  categorySuggestions$ = this.select('categorySuggestions');
  cmsSuggestions$ = this.select('cmsSuggestions');
  pages$ = this.select('pages');
  totalArticles$ = this.select('totalArticles');
  hits$ = this.select('hits');
  searchTerm$ = this.select('searchTerm');
  articleHighlights$ = this.select('articleHighlights');
  categoryHighlights$ = this.select('categoryHighlights');

  constructor(protected override store: SearchStore<T>) {
    super(store);
  }
}
