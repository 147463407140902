import { InjectionToken } from '@angular/core';

export const defaultCoreConfig: CoreConfig = {
  baseUrl: 'http://localhost:4200',
  branchKey: '',
  webshopId: 1,
  mobileBreakpoint: '600px',
  tabletBreakpoint: '1024px',
  useConsent: false,
};

export interface CoreConfig {
  baseUrl: string;
  branchKey: string;
  webshopId: number;
  ignoreLanguageForUrls?: boolean;
  mobileBreakpoint?: string;
  tabletBreakpoint?: string;
  useConsent?: boolean;
}

export const CORE_CONFIG = new InjectionToken<CoreConfig>('CoreConfig');
