import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isEqual, isEqualWith, PropertyName } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CachedSearchHits, FacetedSearchResultStore } from '../store/faceted-search-result.store';
import { FacetedSearchAbstract } from './abstract/faceted-search.abstract';
import { CATALOG_CONFIG, CatalogConfig, PageViewConfig } from '../config/catalog.config';
import { buildFacetedBrandMultiSearchQuery } from '../utils/faceted-specific-feature-result.util';
import { ElasticSearchService, MultiSearchResponse } from '../../core';
import { CatalogQuery } from '../query/catalog.query';
import { FilterService } from './filter.service';
import { Article } from '../model/article.model';
import { SortByType } from '../model/sort-by.type';
import { buildFacetedMultiSearchQuery } from '../utils/faceted-search-result.util';
import { Feature } from '../model/product.model';

type CachedArticles = CachedSearchHits<Article, PageViewConfig>;

@Injectable()
export class FacetedSpecificFeatureResultService extends FacetedSearchAbstract {
  /**
   * Creates an instance of search service.
   */
  constructor(
    private elasticSearch: ElasticSearchService,
    private store: FacetedSearchResultStore,
    private catalogQuery: CatalogQuery,
    private filterService: FilterService,
    private translocoService: TranslocoService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super();
  }

  searchByFiltersCached(featureId: number, featureValue: string, queryFilters: any, viewConfig: PageViewConfig): Observable<Article[]> {
    const storedValues = this.store.getValue();
    const cachedHits: CachedArticles[] = storedValues.cachedHits || [];
    const filteredHits: CachedArticles[] = cachedHits.filter((hit: CachedArticles) => hit.id === `${featureId}__${featureValue}`);

    if (filteredHits.length) {
      const hit: CachedArticles = filteredHits[0];
      if (isEqual(viewConfig, hit.config) && isEqual(queryFilters, hit.queryFilters)) {
        return of(hit.hits);
      }

      // if anything but page size changed, reset store
      if (
        !isEqualWith(viewConfig, hit.config, (_: PageViewConfig, _2: PageViewConfig, key?: PropertyName) =>
          key === 'pageSize' ? true : undefined,
        ) ||
        !isEqual(queryFilters, hit.queryFilters)
      ) {
        this.store.update({
          cachedHits: [...cachedHits.filter((cachedHit: CachedArticles) => cachedHit.id !== `${featureId}__${featureValue}`)],
        });
      }
    }

    this.store.setLoading(true);
    const query = buildFacetedBrandMultiSearchQuery(
      featureId,
      featureValue,
      queryFilters,
      viewConfig.pageSize,
      viewConfig.pageIndex,
      this.getSortMapping(viewConfig.sortBy || this.getDefaultSort()),
      this.elasticSearch.localizedIndex('article'),
    );
    return this.elasticSearch
      .execute<MultiSearchResponse>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        map((response: MultiSearchResponse) => {
          this.doMappings(response);

          return this._setCachedHits(cachedHits, `${featureId}__${featureValue}`, viewConfig, response.responses[0].hits, queryFilters);
        }),
        tap(() => this.store.setLoading(false)),
      );
  }

  searchByFilters(searchTerm: string, queryFilters: any, page = 1, pageSize = 12, sortBy?: SortByType): Observable<MultiSearchResponse> {
    this.store.setLoading(true);
    const query = buildFacetedMultiSearchQuery(
      searchTerm,
      queryFilters,
      pageSize,
      page,
      this.getSortMapping(sortBy || this.getDefaultSort()),
      this.elasticSearch.localizedIndex('article'),
      this.elasticSearch.localizedIndex('category'),
    );
    return this.elasticSearch
      .execute<MultiSearchResponse>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        tap((response: MultiSearchResponse) => {
          this.doMappings(response);
        }),
        tap(() => this.store.setLoading(false)),
      );
  }

  /** Calls all other mapping functions **/
  private doMappings(response: MultiSearchResponse): void {
    this._setFacets(response.responses[0].aggregations);
    this._setHits(response.responses[0].hits);
  }

  /** set the facets by es-aggregations and the provided category and filters */
  private _setFacets(aggregations: any) {
    const fullAggregations = this._transformAggregations(aggregations.agg_full);
    const filteredAggregations = this._transformAggregations(aggregations.agg_filtered.filtered);
    const facets = this._buildFacets(fullAggregations, filteredAggregations);
    this.store.update({ facets });
  }

  /** saves the hits to the store */
  private _setHits(hits: any) {
    const articles = hits.hits.map((product: { _source: { oArticles: Article[] }; _score: string }) => {
      return { score: product._score, ...product._source };
    });
    this.store.update({ hits: articles });
    this.store.update({ totalHits: hits.total.value });
  }

  /** saves the hits to the store */
  private _setCachedHits(
    cachedHits: CachedArticles[],
    searchTerm: string,
    config: PageViewConfig,
    hits: any,
    queryFilters: any,
  ): Article[] {
    const articles = hits.hits.map((product: { _source: { oArticles: Article[] }; _score: string }) => {
      return { score: product._score, ...product._source };
    });

    this.store.update({
      cachedHits: [
        ...cachedHits.filter((cachedHit: CachedArticles) => cachedHit.id !== searchTerm),
        { hits: articles, id: searchTerm, totalHits: hits.total.value, config, queryFilters },
      ],
    });

    return articles;
  }

  /** transforms es aggregations to key values object */
  private _transformAggregations(aggregations: any) {
    return aggregations.attr_name.buckets.map((facet: any) => {
      const values = facet.attr_value.buckets.map((option: any) => {
        return { key: option.key, count: option.doc_count, disabled: false };
      });
      let newFacet = {
        key: facet.key,
        values,
        shtRepresentationType: 1,
        shtWebshopFilter: 1,
      };
      if (facet.feature && facet.feature.hits.hits.length > 0) {
        const featureConfig: Partial<Feature> = facet.feature.hits.hits[0]._source;
        newFacet = {
          ...newFacet,
          shtRepresentationType: featureConfig.shtRepresentationType as any,
          shtWebshopFilter: featureConfig.shtWebshopFilter as any,
        };
      }

      return newFacet;
    });
  }

  /** builds the facets by the transformed aggregations */
  private _buildFacets(fullAggregations: any, filteredAggregations: any) {
    const facets = fullAggregations.map((facetFull: any) => {
      const facetFiltered = filteredAggregations.find((item: any) => item.key === facetFull.key);
      let newValues;
      if (facetFiltered) {
        newValues = facetFull.values.map((option: any) => {
          const optionFiltered = facetFiltered.values.find((item: any) => item.key === option.key);
          let optionCopy;
          if (optionFiltered) {
            optionCopy = { ...option, count: optionFiltered.count };
          } else {
            optionCopy = { ...option, disabled: true, count: 0 };
          }

          return !this.isFirstFilter(facetFull.key) ? optionCopy : { ...optionCopy, disabled: false, count: option.count };
        });
      } else {
        newValues = facetFull.values.map((option: any) => {
          return { ...option, disabled: true, count: 0 };
        });
      }

      newValues = newValues.map((facetOption: any) => {
        return {
          ...facetOption,
          isActive: this.isActiveFilter(facetFull.key, facetOption.key),
        };
      });

      return {
        ...facetFull,
        values: newValues.sort((a: any, b: any) => a.key.localeCompare(b.key, this.translocoService.getActiveLang(), { numeric: true })),
      };
    });
    return facets.sort((a: any, b: any) => (a['key'] > b['key'] ? 1 : a['key'] === b['key'] ? 0 : -1));
  }

  isActiveFilter(key: any, value: any) {
    return !!this.filterService.getFilters().find((filter) => filter.name === key && filter.value === value);
  }

  isFirstFilter(key: any) {
    return this.filterService.getFilters().length > 0 ? this.filterService.getFilters()[0].name === key : false;
  }
}
