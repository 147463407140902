import { ID } from '@datorama/akita';

export interface ShippingAddress {
  lngCustomerID: number;
  lngAddressID: ID | number;
  shtAddressType: number;
  lngContactID: number;
  sContact: string;
  lngRouteBaseID: number;
  sCompany1: string;
  sCompany2: string;
  sCompany3: string;
  sZipBox: string;
  sPostBox: string;
  sStreet: string;
  sZipCode: string;
  sCity: string;
  sCountry: string;
  sCountryCode: string;
  sMemo: string;
}

export function createShippingAddress(
  lngCustomerID = 0,
  lngAddressID = 0,
  shtAddressType = 2,
  lngContactID = 0,
  sContact = '',
  lngRouteBaseID = 1,
  sCompany1 = '',
  sCompany2 = '',
  sCompany3 = '',
  sZipBox = '',
  sPostBox = '',
  sStreet = '',
  sZipCode = '',
  sCity = '',
  sCountry = '',
  sCountryCode = '',
  sMemo = '',
): ShippingAddress {
  return {
    lngCustomerID,
    lngAddressID,
    shtAddressType,
    lngContactID,
    sContact,
    lngRouteBaseID,
    sCompany1,
    sCompany2,
    sCompany3,
    sZipBox,
    sPostBox,
    sStreet,
    sZipCode,
    sCity,
    sCountry,
    sCountryCode,
    sMemo,
  };
}
