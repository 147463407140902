import { Inject, Injectable } from '@angular/core';
import { SalesCreditNoteStore } from '../store/sales-credit-note.store';
import { SalesCreditNote } from '../model/sales-credit-note.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec, PaginationResponseInterface, queryPaginated } from '../../core';
import { filter, finalize, tap } from 'rxjs/operators';
import { SALES_CREDIT_NOTE_CONFIG, SalesCreditNoteConfig } from '../config/sales-credit-note.config';

/**
 * A service that manages offer entities
 */
@Injectable({ providedIn: 'root' })
export class SalesCreditNoteService<T extends SalesCreditNote> {
  constructor(
    private http: HttpClient,
    private store: SalesCreditNoteStore<T>,
    @Inject(SALES_CREDIT_NOTE_CONFIG) private config: SalesCreditNoteConfig,
  ) {}

  getByFilters(filters: any[]) {
    this.store.setLoading(true);
    let params = new HttpParams({ encoder: new CustomHttpParameterCodec() });
    filters.forEach((filter) => {
      params = params.append(filter.id, filter.value);
    });
    return this.http.get(this.config.apiUrl + '/sales-credit-notes', { params }).pipe(
      filter((res: any) => res.data),
      tap((res: any) => {
        this.store.upsertMany(res.data);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  get(urlOrParams: string | object): Observable<PaginationResponseInterface<T>> {
    return queryPaginated<T>(this.http, this.config.apiUrl + '/sales-credit-notes', urlOrParams).pipe(
      tap((response: PaginationResponseInterface<T>) => this.store.upsertMany(response.data)),
    );
  }

  getById(lngOrderId: number) {
    return this.http
      .get<T>(this.config.apiUrl + '/sales-credit-notes/' + lngOrderId)
      .pipe(tap((salesOrder: T) => this.store.upsert(salesOrder.lngOrderID, salesOrder)));
  }
}
