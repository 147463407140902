import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogService } from '../../error';
import { ELASTICSEARCH_CONFIG, ElasticsearchConfig } from '../config/elasticsearch.config';

@Injectable({
  providedIn: 'root',
})
export class ElasticSearchService {
  private availableLanguages: string[];
  private indexLanguage: string = this.config.defaultLanguage;

  constructor(@Inject(ELASTICSEARCH_CONFIG) private config: ElasticsearchConfig, private logService: LogService, private http: HttpClient) {
    this.availableLanguages = config.availableLanguages;
    this.setIndexLanguage(config.defaultLanguage);
  }

  public setIndexLanguage(lang: string): void {
    const shortenedLanguage = lang.substring(0, 2);
    if (!this.availableLanguages.includes(shortenedLanguage)) {
      return this.logService.warn('Trying to set a non-configured index language', lang);
    }

    this.indexLanguage = shortenedLanguage;
  }

  public execute<T>(url: string, query: any): Observable<T> {
    return this.http.post<T>(url, query);
  }

  public localizedIndex(name: string): string {
    return `${name}_${this.indexLanguage}`;
  }
}
