import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { SalesDocumentQuery } from '../query/sales-document.query';
import { SalesDocument } from '../model/sales-document.model';
import { take, tap } from 'rxjs/operators';
import { SALES_DOCUMENT_CONFIG, SalesDocumentConfig } from '../config/sales-document.config';
import { Observable } from 'rxjs';
import { PaginationMetaResponse } from '../../core/interfaces/pagination-meta-response.interface';
import { SalesDocumentStore } from '../store/sales-document.store';

@Injectable({ providedIn: 'root' })
export class SalesDocumentService<T extends SalesDocument> {
  constructor(
    private salesDocumentQuery: SalesDocumentQuery<T>,
    private store: SalesDocumentStore<SalesDocument>,
    private http: HttpClient,
    @Inject(SALES_DOCUMENT_CONFIG) public salesDocumentConfig: SalesDocumentConfig,
  ) {}

  // TODO Fix any type
  download(document: SalesDocument): any {
    if (this.salesDocumentConfig.useArchive) {
      return this.downloadFromArchive(document);
    } else {
      return this.reprintDocument(document);
    }
  }

  getById(documentId: number): Observable<PaginationMetaResponse<SalesDocument>> {
    return this.http
      .get<PaginationMetaResponse<SalesDocument>>(this.salesDocumentConfig.apiUrl + '/sales-documents?lngOrderID=' + documentId)
      .pipe(tap((res: PaginationMetaResponse<SalesDocument>) => this.store.upsertMany(res.data)));
  }

  getByIdAndOrderType(orderId: number, orderType: string): Observable<PaginationMetaResponse<SalesDocument>> {
    return this.http
      .get<PaginationMetaResponse<SalesDocument>>(
        this.salesDocumentConfig.apiUrl + '/sales-documents?lngOrderID=' + orderId + '&sOrderType=' + orderType,
      )
      .pipe(tap((res: PaginationMetaResponse<SalesDocument>) => this.store.upsertMany(res.data)));
  }

  // TODO Fix any type
  reprintDocument(salesDocument: SalesDocument): Observable<any> {
    const acceptHeaders = new HttpHeaders();
    acceptHeaders.append('Accept', '*/*');
    acceptHeaders.append('Accept-Encoding', 'gzip, deflate, br');
    acceptHeaders.append('Connection', 'keep-alive');
    const encodedDocumentType = encodeURIComponent(salesDocument.sDocumentType);
    return this.http
      .get<[any]>(
        `${this.salesDocumentConfig.apiUrl}/sales-documents?sDocumentType=${encodedDocumentType}&lngDocumentID=${salesDocument.lngDocumentID}`,
        {
          headers: acceptHeaders,
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        take(1),
        tap((res) => {
          this.saveFileAs(res as any, `${salesDocument.sDocumentTypeName}_${salesDocument.lngDocumentID.toString()}.pdf`);
        }),
      );
  }

  // TODO Fix any type
  downloadFromArchive(salesDocument: SalesDocument): any {
    const acceptHeaders = new HttpHeaders();
    acceptHeaders.append('Accept', '*/*');
    acceptHeaders.append('Accept-Encoding', 'gzip, deflate, br');
    acceptHeaders.append('Connection', 'keep-alive');
    const encodedOrderType = encodeURIComponent(salesDocument.sOrderType);
    const encodedDocumentType = encodeURIComponent(salesDocument.sDocumentType);
    return this.http
      .get<[any]>(
        `${this.salesDocumentConfig.apiUrl}/archive-documents?sProgram=SalesDocument&lngReferenceID=${salesDocument.lngOrderID}&sOrderType=${encodedOrderType}&sDocumentType=${encodedDocumentType}`,
        {
          headers: acceptHeaders,
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        take(1),
        tap((res) => {
          this.saveFileAs(res as any, `${salesDocument.sDocumentTypeName}_${salesDocument.lngDocumentID.toString()}.pdf`);
        }),
      );
  }

  saveFileAs(fileBlob: any, fileName: string) {
    const file = new Blob([fileBlob], { type: 'application/pdf' });
    saveAs(file, fileName);
  }
}
