import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Article } from '../model/article.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ArticleState<T extends Article> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'article', idKey: 'sArticleID' })
export class ArticleStore<T extends Article> extends EntityStore<ArticleState<T>> {
  /**
   * Creates an instance of article store.
   */
  constructor() {
    super();
  }
}
