import { Injectable } from '@angular/core';
import { Configuration } from '../model/configuration';
import { ConfigurationExtra } from '../model/configuration-extra';
import { Label } from '../model/label';
import { Template } from '../model/template';
import { TemplateRendererInterface } from '../model/template-renderer.interface';
import { TrackMethod } from '@lobos/library';

@Injectable({
  providedIn: 'root',
})
export class TemplateRendererFactory<T extends ConfigurationExtra | void = void> {
  private renderer: TemplateRendererInterface<T>[] = [];

  public register(renderer: TemplateRendererInterface<T>): void {
    this.renderer = this.renderer.filter((r: TemplateRendererInterface<T>) => r.template().id !== renderer.template().id);
    this.renderer = [...this.renderer, renderer];
  }

  public getAllTemplates(): Template[] {
    return this.renderer.map((r: TemplateRendererInterface<T>) => r.template());
  }

  @TrackMethod({
    provide: () => ({
      name: 'label_print',
      payload: {},
    }),
  })
  public async renderDownload(labels: Label[], configuration: Configuration<T>, filename: string): Promise<void> {
    return (
      await this.prepare(labels, {
        ...configuration,
        showPreview: false,
      })
    ).download(filename);
  }

  public async renderUrl(labels: Label[], configuration: Configuration<T>): Promise<URL> {
    return (await this.prepare(labels, configuration)).url();
  }

  private async prepare(labels: Label[], configuration: Configuration<T>): Promise<TemplateRendererInterface<T>> {
    const renderer = this.renderer.find((r: TemplateRendererInterface<T>) => r.template().id === configuration.templateId);
    if (!renderer) {
      throw new Error(`Could not find a renderer for template id "${configuration.templateId}"`);
    }

    renderer.prepare(labels, configuration);
    await renderer.render();

    return renderer;
  }
}
