import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export type FilterState = EntityState<any>;

@Injectable()
@StoreConfig({ name: 'filter' })
export class FilterStore extends EntityStore<FilterState, any> {
  constructor() {
    super();
  }
}
