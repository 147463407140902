import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConnectionService } from '../connection';

@Injectable({
  providedIn: 'root',
})
export class OfflineDetectInterceptor implements HttpInterceptor {
  private offlineDetectEndpoints: string[] = ['/param/'];

  constructor(private connection: ConnectionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isDetectUrl: boolean = request.url.match(this.offlineDetectEndpoints.join('|')) !== null;
    if (!isDetectUrl || request.method.toLowerCase() === 'options') {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (!(event instanceof HttpResponse)) {
          return;
        }

        if (!this.connection.isOk(event.status)) {
          this.connection.goOffline();
          return;
        }

        if (!event.body) {
          this.connection.goOffline();
          return;
        }

        this.connection.goOnline();
      }),
    );
  }
}
