import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TicketMessage } from '../models/ticket-message.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TicketMessageState<T extends TicketMessage> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ticket-messages', idKey: 'id' })
export class TicketMessageStore<T extends TicketMessage> extends EntityStore<TicketMessageState<T>> {
  constructor() {
    super();
  }
}
