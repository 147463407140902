import { Injectable } from '@angular/core';
import { UnknownType } from '../../../auth/interfaces/unknown.type';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export abstract class SimpleStorageService<T> {
  protected constructor(private storage: LocalstorageService) {}

  abstract key(): string;

  public async set(value: UnknownType): Promise<UnknownType> {
    return this.storage.set(this.key(), value);
  }

  public async get(): Promise<T | null> {
    return this.storage.get<T>(this.key());
  }

  public async remove(): Promise<void> {
    return this.storage.remove(this.key());
  }
}
