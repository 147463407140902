import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionOfflineHook, ConnectionOnlineHook, Hook, HookReturnType } from '../hook';
import { ConnectionStatusType } from './connection-status.type';
import { ConnectionInterface } from './connection.interface';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private connection$: BehaviorSubject<ConnectionInterface> = new BehaviorSubject<ConnectionInterface>({
    status: ConnectionStatusType.ONLINE,
  });

  public getStatus(): Observable<ConnectionStatusType> {
    return this.connection$.asObservable().pipe(map((connection: ConnectionInterface) => connection.status));
  }

  @Hook<ConnectionOfflineHook, boolean>({ id: { type: 'ConnectionOfflineHook' }, returnType: HookReturnType.PRIMITIVE })
  public goOffline(): ConnectionStatusType {
    this.connection$.next({ ...this.connection$.getValue(), status: ConnectionStatusType.OFFLINE });
    return ConnectionStatusType.OFFLINE;
  }

  @Hook<ConnectionOnlineHook, boolean>({ id: { type: 'ConnectionOnlineHook' }, returnType: HookReturnType.PRIMITIVE })
  public goOnline(): ConnectionStatusType {
    this.connection$.next({ ...this.connection$.getValue(), status: ConnectionStatusType.ONLINE });
    return ConnectionStatusType.ONLINE;
  }

  /**
   * Checks if response status code is OK (status code = 20x)
   */
  public isOk(statusCode: number): boolean {
    return statusCode.toString().startsWith('20');
  }
}
