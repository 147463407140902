import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isEqual, isEqualWith, PropertyName } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ElasticSearchService, MultiSearchResponse } from '../../core';
import { CATALOG_CONFIG, CatalogConfig, PageViewConfig } from '../config/catalog.config';
import { ArticleCount } from '../model/article-count.model';
import { Feature, Product } from '../model/product.model';
import { SortByType } from '../model/sort-by.type';
import { CatalogQuery } from '../query/catalog.query';
import { CachedHits, CategoryFacetedSearchStore } from '../store/category-faceted-search.store';
import { buildCategoryFacetedMultiSearchQuery } from '../utils/category-faceted-search.util';
import { FacetedSearchAbstract } from './abstract/faceted-search.abstract';
import { FilterService } from './filter.service';

type CachedProducts = CachedHits<Product, PageViewConfig>;

@Injectable()
export class CategoryFacetedSearchService extends FacetedSearchAbstract {
  /**
   * Creates an instance of search service.
   */
  constructor(
    private elasticSearch: ElasticSearchService,
    private store: CategoryFacetedSearchStore,
    private catalogQuery: CatalogQuery,
    private filterService: FilterService,
    private translocoService: TranslocoService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super();
    this.addSortMapping('title', [{ 'sTitle.keyword': { order: 'asc' } }]);
  }

  searchByFiltersCached(slug: string, queryFilters: any, viewConfig: PageViewConfig, exclude: string[] = []): Observable<Product[]> {
    const storedValues = this.store.getValue();
    const cachedHits: CachedProducts[] = storedValues.cachedHits || [];
    const filteredHits: CachedProducts[] = cachedHits.filter((hit: CachedProducts) => hit.id === slug);

    if (filteredHits.length) {
      const hit: CachedProducts = filteredHits[0];
      if (isEqual(viewConfig, hit.config) && isEqual(queryFilters, hit.queryFilters)) {
        return of(hit.hits);
      }

      // if anything but page size changed, reset store
      if (
        !isEqualWith(viewConfig, hit.config, (_: PageViewConfig, _2: PageViewConfig, key?: PropertyName) =>
          key === 'pageSize' ? true : undefined,
        ) ||
        !isEqual(queryFilters, hit.queryFilters)
      ) {
        this.store.update({
          cachedHits: [...cachedHits.filter((cachedHit: CachedProducts) => cachedHit.id !== slug)],
        });
      }
    }

    this.store.setLoading(true);
    const query = buildCategoryFacetedMultiSearchQuery(
      slug,
      queryFilters,
      viewConfig.pageSize,
      viewConfig.pageIndex,
      this.getSortMapping(viewConfig.sortBy || this.getDefaultSort()),
      this.elasticSearch.localizedIndex('article'),
      this.elasticSearch.localizedIndex('category'),
      exclude,
    );
    return this.elasticSearch
      .execute<MultiSearchResponse>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        map((response: MultiSearchResponse) => {
          this.doMappings(response);

          return this._setCachedHits(cachedHits, slug, viewConfig, response.responses[1].hits, queryFilters);
        }),
        tap(() => this.store.setLoading(false)),
      );
  }

  updateHits(slug: string) {
    const storedValues = this.store.getValue();
    const cachedHits: CachedProducts[] = storedValues.cachedHits || [];
    const filteredHits: CachedProducts[] = cachedHits.filter((hit: CachedProducts) => hit.id === slug);

    if (filteredHits.length) {
      this.store.update({
        cachedHits: [...cachedHits.filter((cachedHit: CachedProducts) => cachedHit.id !== slug)],
      });
    }
  }

  searchByFilters(
    slug: string,
    queryFilters: any,
    pageIndex = 0,
    pageSize = 12,
    sortBy?: SortByType,
    exclude: string[] = [],
  ): Observable<MultiSearchResponse> {
    this.store.setLoading(true);
    const query = buildCategoryFacetedMultiSearchQuery(
      slug,
      queryFilters,
      pageSize,
      pageIndex,
      this.getSortMapping(sortBy || this.getDefaultSort()),
      this.elasticSearch.localizedIndex('article'),
      this.elasticSearch.localizedIndex('category'),
      exclude,
    );
    return this.elasticSearch
      .execute<MultiSearchResponse>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        tap((response: MultiSearchResponse) => this.doMappings(response)),
        tap(() => this.store.setLoading(false)),
      );
  }

  /** Calls all other mapping functions **/
  private doMappings(response: MultiSearchResponse): void {
    this._setArticleCount(response.responses[0].aggregations.agg_filtered.article_count);
    this._setFacets(response.responses[2].hits, response.responses[0].aggregations);
    this._setHits(response.responses[1].hits);
    this._setCategory(response.responses[2].hits);
    this._setSubLevelCategories(response.responses[2].hits);
  }

  /** sets the article count, respect the current set of filter */
  private _setArticleCount(aggregations: any) {
    this.store.update({
      articleCount: aggregations.buckets.map(
        (bucket: { key: number; doc_count: number; article: any }) =>
          ({
            lngGroupId: bucket.key,
            count: bucket.doc_count,
            sArticleID: bucket.article.hits.hits[0]._source.sArticleID,
          } as ArticleCount),
      ),
    });
  }

  /** set the facets by es-aggregations and the provided category and filters */
  private _setFacets(category: any, aggregations: any) {
    const featureClass = category.hits[0]._source.oFeatureClass;
    const fullAggregations = this._transformAggregations(aggregations.agg_full.filtered);
    const filteredAggregations = this._transformAggregations(aggregations.agg_filtered.filtered);
    const facets = this._buildFacets(fullAggregations, filteredAggregations, featureClass);
    this.store.update({ facets });
  }

  /** set the subLevelCategories store */
  private _setSubLevelCategories(category: any) {
    const parentGroupId = category.hits[0]._source.lngGroup;
    const categories = this.catalogQuery.getCategoriesByParentGroupId(parentGroupId);
    this.store.update({ subLevelCategories: categories });
  }

  /** saves the hits to the store */
  private _setHits(hits: any) {
    const products = hits.hits.map((product: { _source: any; _score: string }) => ({
      score: product._score,
      ...product._source,
    }));
    this.store.update({ hits: products });
    this.store.update({ totalHits: hits.total.value });
  }

  /** saves the hits to the store */
  private _setCachedHits(cachedHits: CachedProducts[], slug: string, config: PageViewConfig, hits: any, queryFilters: any): Product[] {
    const products = hits.hits.map((product: { _source: any; _score: string }) => ({
      score: product._score,
      ...product._source,
    }));

    this.store.update({
      cachedHits: [
        ...cachedHits.filter((cachedHit: CachedProducts) => cachedHit.id !== slug),
        { hits: products, id: slug, totalHits: hits.total.value, config, queryFilters },
      ],
    });

    return products;
  }

  /** saves the category to the store */
  private _setCategory(category: any) {
    category = category.hits[0]._source;
    this.store.update({ category });
  }

  /** transforms es aggregations to key values object */
  private _transformAggregations(aggregations: any) {
    return aggregations.attr_name.buckets.map((facet: any) => {
      const values = facet.attr_value.buckets.map((option: any) => {
        return { key: option.key, count: option.doc_count, disabled: false };
      });
      let newFacet = {
        key: facet.key,
        values,
        shtRepresentationType: 1,
        shtWebshopFilter: 1,
      };
      if (facet.feature && facet.feature.hits.hits.length > 0) {
        const featureConfig: Partial<Feature> = facet.feature.hits.hits[0]._source;
        newFacet = {
          ...newFacet,
          shtRepresentationType: featureConfig.shtRepresentationType as any,
          shtWebshopFilter: featureConfig.shtWebshopFilter as any,
        };
      }

      return newFacet;
    });
  }

  /** builds the facets by the transformed aggregations */
  private _buildFacets(fullAggregations: any, filteredAggregations: any, featureClass: any) {
    let facets = fullAggregations.map((facetFull: any) => {
      const facetFiltered = filteredAggregations.find((item: any) => item.key === facetFull.key);
      let newValues: any[];

      if (facetFiltered) {
        newValues = facetFull.values.map((option: any) => {
          const optionFiltered = facetFiltered.values.find((item: any) => item.key === option.key);
          let optionCopy: any;
          if (optionFiltered) {
            optionCopy = { ...option, count: optionFiltered.count };
          } else {
            optionCopy = { ...option, disabled: true, count: 0 };
          }

          return !this.isFirstFilter(facetFull.key) ? optionCopy : { ...optionCopy, disabled: false, count: option.count };
        });
      } else {
        newValues = facetFull.values.map((option: any) => {
          return { ...option, disabled: true, count: 0 };
        });
      }

      newValues = newValues.map((facetOption: any) => {
        return {
          ...facetOption,
          isActive: this.isActiveFilter(facetFull.key, facetOption.key),
        };
      });
      // TODO sort values
      return {
        ...facetFull,
        values: newValues.sort((a: any, b: any) => a.key.localeCompare(b.key, this.translocoService.getActiveLang(), { numeric: true })),
      };
    });

    if (featureClass?.length > 0) {
      facets = this.reduceFacetsByFeatureClass(facets, featureClass);
      facets = this.addPositionFromFeatureClass(facets, featureClass);
    }

    return facets.sort((a: any, b: any) => {
      if (a?.shtFCFPos != b?.shtFCFPos) {
        return a.shtFCFPos - b.shtFCFPos;
      }
      return a.key > b.key ? 1 : a.key === b.key ? 0 : -1;
    });
  }

  private reduceFacetsByFeatureClass(facets: any, featureClass: any) {
    return facets.filter((facet: any) => featureClass.find((fClass: any) => fClass.sName === facet.key));
  }

  private addPositionFromFeatureClass(facets: any, featureClass: any) {
    return facets.map((facet: any) => {
      return { ...facet, shtFCFPos: featureClass.find((fclass: any) => fclass.sName === facet.key)?.shtFCFPos };
    });
  }

  private isActiveFilter(key: any, value: any) {
    return !!this.filterService.getFilters().find((filter) => filter.name === key && filter.value === value);
  }

  private isFirstFilter(key: any) {
    return this.filterService.getFilters().length > 0 ? this.filterService.getFilters()[0].name === key : false;
  }
}
