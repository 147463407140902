import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Parameter } from '../model/parameter.model';
import { ParameterStore } from '../store/parameter.store';

@Injectable({
  providedIn: 'root',
})
export class ParameterService {
  constructor(protected store: ParameterStore, private http: HttpClient) {}

  public get(): Observable<Parameter> {
    return this.http.get<Parameter>(`/p48apihost/param/`).pipe(tap((oParameter: Parameter) => this.store.update({ params: oParameter })));
  }
}
