import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RoleState, RoleStore } from '../store/role.store';
import { Role } from '../model/role.model';

@Injectable({ providedIn: 'root' })
export class RoleQuery<T extends Role> extends QueryEntity<RoleState<T>> {
  constructor(protected override store: RoleStore<T>) {
    super(store);
  }
}
