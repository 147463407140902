import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CartItem } from '../model/cart-item.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CartItemLocalState<T extends CartItem> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart-items-local', idKey: 'fakeId', resettable: true })
export class CartItemLocalStore<T extends CartItem> extends EntityStore<CartItemLocalState<T>> {
  constructor() {
    super();
  }

  override akitaPreAddEntity(x: Readonly<T>): T {
    return {
      ...x,
      fakeId: [x.lngOrderID, x.shtFixedItemID].join(','),
    };
  }
}
