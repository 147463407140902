import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { ProjectStore, ProjectState } from '../store/project.store';
import { Project } from '../models/project.model';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'sProjectName', sortByOrder: Order.ASC })
export class ProjectQuery<T extends Project> extends QueryEntity<ProjectState<T>> {
  constructor(protected override store: ProjectStore<T>) {
    super(store);
  }
}
