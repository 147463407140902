import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Category } from '../model/category.model';

export type CategoryState = EntityState<Category>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'category', resettable: true })
export class CategoryStore extends EntityStore<CategoryState> {
  constructor() {
    super();
  }
}
