import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Ticket } from '../models/ticket.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TicketState<T extends Ticket> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tickets', idKey: 'lngTicketID', resettable: true })
export class TicketStore<T extends Ticket> extends EntityStore<TicketState<T>> {
  constructor() {
    super();
  }
}
