import { Injectable } from '@angular/core';
import { Order } from '@datorama/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { SalesOrder } from '../model/sales-order.model';
import { SalesOrderState } from '../store/sales-order.store';
import { SalesOrderQuery } from '../query/sales-order.query';

@Injectable()
export class SalesOrderFilterService<T extends SalesOrder> extends AkitaFiltersPlugin<SalesOrderState<T>> {
  constructor(protected offerQuery: SalesOrderQuery<T>) {
    super(offerQuery, { filtersStoreName: 'sales-offer-filter' });
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 3);
    this.setFilter({
      id: 'dtEntryDate[gte]',
      value: fromDate.valueOf(),
      predicate: (entity: T) => {
        return entity.dtEntryDate >= fromDate.valueOf();
      },
    });
    this.setFilter({
      id: 'intPageIndex',
      value: 1,
      predicate: (_) => true,
    });
    this.setFilter({
      id: 'intPageSize',
      value: 10,
      predicate: (_) => true,
    });
  }

  /**
   * Sets orderBy
   */
  setOrderBy(by: any, order: string | '+' | '-') {
    this.setSortBy({
      sortBy: by,
      sortByOrder: order === '+' ? Order.ASC : Order.DESC,
    });
  }
}
