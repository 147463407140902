import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { iif, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { StringHelper } from '../../core';
import { User } from '../../user';
import { AuthService } from './auth.service';

export enum ACCESS_ID {
  SHOW_HISTORICAL_DATA = 'show-historical-data',
  SHOW_USER_MANAGEMENT = 'show-user-management',
  SHOW_OFFERS = 'sales-offers',
  SHOW_ORDERS = 'sales-orders',
  SHOW_CREDIT_NOTES = 'sales-credit-notes',
  SHOW_BACKORDER = 'backlog',
}

export enum CRUD_METHOD {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
  READ = 'read',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor(private authService: AuthService) {}

  public hasAccess(accessId: string | boolean | undefined): Observable<boolean> {
    if (accessId === undefined) {
      return of(true);
    }

    if (!StringHelper.isString(accessId)) {
      return of(!!accessId);
    }

    return this.authService.authUser$.pipe(
      first(),
      switchMap((user: User | undefined) =>
        iif(
          () => !user,
          // true
          of(false),
          // false
          of(this.isUserAuthorized(accessId as string, user)),
        ),
      ),
    );
  }

  public isUserAuthorized(accessId: string, user: User | undefined, method: CRUD_METHOD = CRUD_METHOD.READ): boolean {
    if (user !== undefined) {
      if (accessId === ACCESS_ID.SHOW_HISTORICAL_DATA) {
        return user.oP48APIAuthorizationGroup?.shtShowOpenItems === 1;
      } else if (accessId === ACCESS_ID.SHOW_USER_MANAGEMENT) {
        if (user.oP48APIAuthorizationGroup?.lngAPIAuthorizationGroupID !== null) {
          return user.oP48APIAuthorizationGroup?.shtP48ShowUserManagement === 1;
        } else {
          return user.shtP48ShowUserManagement === 1;
        }
      } else {
        const endpoint = user.oP48APIAuthorizationGroup?.oP48APIAuthorizationGroupAssignList.find(
          (agl) => agl.sAPIAuthorizationID === accessId,
        );

        if (endpoint) {
          switch (method) {
            case CRUD_METHOD.CREATE:
              return endpoint.bCRUDCreate;
            case CRUD_METHOD.UPDATE:
              return endpoint.bCRUDUpdate;
            case CRUD_METHOD.DELETE:
              return endpoint.bCRUDDelete;
            case CRUD_METHOD.READ:
              return endpoint.bCRUDRead;
          }
        }

        return false;
      }
    }
    return false;
  }
}
