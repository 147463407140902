import { Location } from '@angular/common';
import { Store } from '@datorama/akita';
import { StringHelper, UrlHelperService } from '../../../core';
import { ViewModeType } from '../../model/view-mode.type';

enum PageViewParameterMapping {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
}

/**
 * Handles page view related tasks
 * E.g. page size, sort, view mode, etc.
 */
export abstract class PageViewFilterAbstract<T extends Store, D> {
  protected defaults: Partial<D> = {};

  protected constructor(protected store: T, protected location: Location, protected urlHelper: UrlHelperService) {}

  /**
   * When converting url parameter back, we need some kind of mapping, which type they will take in the store
   * Reading them from the Url, will otherwise always result in a String.
   */
  private urlParameterTypeMapping: { [key: string]: PageViewParameterMapping } = {
    pageIndex: PageViewParameterMapping.NUMBER,
    pageSize: PageViewParameterMapping.NUMBER,
    sortBy: PageViewParameterMapping.STRING,
    viewMode: PageViewParameterMapping.STRING,
  };

  public updateDefaults(defaults: Partial<D>): void {
    this.defaults = defaults;
    this.store.update(defaults);
  }

  /**
   * Reset the store values by checking the url params
   */
  public resetByUrlParams(values?: Partial<D>): void {
    const urlValues: Partial<D> = {};

    Object.entries(this.urlHelper.getQueryParams()).forEach(([key, value]: [string, string]) => {
      if (!key.match(/^_/)) {
        return;
      }

      const index: string = StringHelper.ltrim(key, '_');
      if (!this.urlParameterTypeMapping[index]) {
        return;
      }

      switch (this.urlParameterTypeMapping[index]) {
        case PageViewParameterMapping.NUMBER:
          (urlValues as any)[index] = +value;
          break;
        default:
          (urlValues as any)[index] = '' + value;
      }
    });

    this.store.update({ ...values, ...urlValues });
  }

  /**
   * Updates the sort
   */
  public updateSort(sortBy: string): void {
    this.updateSettingsInUrl('sortBy', sortBy);
    this.store.update({ sortBy });
  }

  /**
   * Updates all pagination values at once
   */
  public updatePagination(pageIndex: number, pageSize: number): void {
    this.updateSettingsInUrl('pageIndex', pageIndex);
    this.updateSettingsInUrl('pageSize', pageSize);

    this.store.update({ pageIndex, pageSize });
  }

  /**
   * Updates the current page
   */
  public updateCurrentPage(pageIndex: number): void {
    this.updateSettingsInUrl('pageIndex', pageIndex);
    this.store.update({ pageIndex });
  }

  /**
   * Updates the page size
   */
  public updatePageSize(pageSize: number): void {
    this.updateSettingsInUrl('pageSize', pageSize);
    this.store.update({ pageSize });
  }

  /**
   * Updates the display mode
   */
  public updateViewMode(viewMode: ViewModeType): void {
    this.updateSettingsInUrl('viewMode', viewMode);
    this.store.update({ viewMode });
  }

  private updateSettingsInUrl(key: string, value: string | number): void {
    const currentUrlParams = this.urlHelper.getQueryParams();
    const currentValues = this.store.getValue();

    if (this.normalizeValue(currentValues[key]) === this.normalizeValue(value)) {
      return;
    }

    currentUrlParams[`_${key}`] = this.normalizeValue(value);

    // using location.go() here, so routing is not triggered entirely
    this.location.go(this.urlHelper.getPath(), this.urlHelper.objectToQueryParams(currentUrlParams));
  }

  /**
   * Normalizes all values to string, so we can compare them strictly
   */
  private normalizeValue(value: string | number): string {
    return '' + value;
  }
}
