import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SalesRepCustomer } from '../model/sales-rep-customer.model';
import { SalesRepCustomerStore } from '../store/sales-rep-customer.store';
import { filter, tap } from 'rxjs/operators';
import { User, UserService } from '../../user';
import { AuthService } from '../../auth';
import { switchTap } from '../../core';

@Injectable({ providedIn: 'root' })
export class SalesRepService<T extends SalesRepCustomer, R extends User = User> {
  constructor(
    protected store: SalesRepCustomerStore<T>,
    protected http: HttpClient,
    protected userService: UserService<R>,
    protected authService: AuthService<R>,
  ) {}

  getSalesRepCustomers(): Observable<T[]> {
    return this.http.get<T[]>(`p48apihost/sales-rep/customers`).pipe(tap((customers: T[]) => this.store.upsertMany(customers)));
  }

  changeSalesRepCustomer(lngCustomerID: number): Observable<R> {
    return this.http.put<R>('p48apihost/sales-rep/' + lngCustomerID, {}).pipe(
      filter((data) => !!data),
      switchTap((user: R) => this.authService.updateAuthUser(user)),
      tap((user) => this.authService.changeAuthUser(user)),
    );
  }

  resetSalesRepCustomer(): Observable<R> {
    return this.http.put<R>('p48apihost/sales-rep/reset', {}).pipe(
      filter((data) => !!data),
      switchTap((user: R) => this.authService.updateAuthUser(user)),
      tap((user) => this.authService.changeAuthUser(user)),
    );
  }
}
