import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { ShippingAddressStore, ShippingAddressState } from '../store/shipping-address.store';
import { ShippingAddress } from '../model/shipping-address.model';

@QueryConfig({ sortBy: 'sCompany1', sortByOrder: Order.ASC })
@Injectable({ providedIn: 'root' })
export class ShippingAddressQuery<T extends ShippingAddress> extends QueryEntity<ShippingAddressState<T>> {
  constructor(protected override store: ShippingAddressStore<T>) {
    super(store);
  }
}
