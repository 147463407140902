import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ArticleFactory } from '../services/article.factory';
import { Article } from '../model/article.model';
import { Facet } from '../model/facet.model';
import { ArticleFacetedSearchState, FacetedSearchResultStore } from '../store/faceted-search-result.store';
import { Observable } from 'rxjs';
import { CachedHits } from '../store/category-faceted-search.store';
import { PageViewConfig } from '../config/catalog.config';

type CachedArticles = CachedHits<Article, PageViewConfig>;

@Injectable()
export class FacetedSearchResultQuery extends Query<ArticleFacetedSearchState> {
  categorySuggestions$ = this.select('categorySuggestions');
  cmsSuggestions$ = this.select('cmsSuggestions');
  facets$ = this.select('facets').pipe(
    map((facets: Facet[]) => {
      return facets.filter((facet: Facet) => facet.shtWebshopFilter === 1);
    }),
  );
  hits$ = this.select('hits').pipe(map((articles: Article[]) => articles.map((article: Article) => ArticleFactory.create(article))));
  cachedHits$: Observable<CachedArticles[]> = this.select('cachedHits');
  pageIndex$ = this.select('pageIndex');
  pageSize$ = this.select('pageSize');
  sortBy$ = this.select('sortBy');
  viewMode$ = this.select('viewMode');
  subLevelCategories$ = this.select('subLevelCategories');
  totalHits$ = this.select('totalHits');

  constructor(protected override store: FacetedSearchResultStore) {
    super(store);
  }

  cachedFor(searchTerm: string): Observable<Article[]> {
    return this.cachedHits$.pipe(
      map((hits: CachedArticles[]) => hits.filter((hit: CachedArticles) => hit.id === searchTerm)),
      map((hits: CachedArticles[]) => (hits.length ? hits[0].hits : [])),
    );
  }

  cachedTotalFor(searchTerm: string): Observable<number> {
    return this.cachedHits$.pipe(
      map((hits: CachedArticles[]) => hits.filter((hit: CachedArticles) => hit.id === searchTerm)),
      map((hits: CachedArticles[]) => (hits.length ? hits[0].totalHits : 0)),
    );
  }
}
