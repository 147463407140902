import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Language } from '../model/language.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LanguageState<T extends Language> extends EntityState<T> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'languages', idKey: 'shtLanguageID' })
export class LanguageStore<T extends Language> extends EntityStore<LanguageState<T>> {
  constructor() {
    super();
  }
}
