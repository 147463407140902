import { Inject, Injectable } from '@angular/core';
import { SalesOrderItemStore } from '../store/sales-order-item.store';
import { SalesOrderItem } from '../model/sales-order-item.model';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SALES_ORDER_CONFIG, SalesOrderConfig } from '../config/sales-order.config';
import { PaginationResponseInterface } from '../../core';
import { ID } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class SalesOrderItemService<T extends SalesOrderItem> {
  constructor(
    private http: HttpClient,
    private store: SalesOrderItemStore<T>,
    @Inject(SALES_ORDER_CONFIG) private config: SalesOrderConfig,
  ) {}

  get(lngOrderID: number | ID): Observable<T[]> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<PaginationResponseInterface<T>>(this.config.apiUrl + '/sales-orders/' + lngOrderID + '/items/')),
      map((res: PaginationResponseInterface<T>) => {
        return res.data.map((item: T) => {
          return { ...item, id: `${item.lngOrderID}-${item.shtFixedItemID}` };
        });
      }),
      tap((entities: T[]) => {
        this.store.upsertMany(entities);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
