import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { STRAPI_CMS_CONFIG, StrapiCmsConfig } from '../config/strapi-cms.config';
import { BlogPost } from '../model/blog-post';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentPage } from '../model/cms.models';
import { Footer } from '../model/footer';
import { CmsUiDynamicContentStore } from '../store/cms.store';
import { CustomHttpParameterCodec } from '../../core';

/**
 * A service to manage article entities
 */
@Injectable({ providedIn: 'root' })
export class CmsUiDynamicContentService {
  /**
   * Creates an instance of article service.
   */
  constructor(
    protected store: CmsUiDynamicContentStore,
    protected http: HttpClient,
    @Inject(STRAPI_CMS_CONFIG) protected config: StrapiCmsConfig,
  ) {}

  getCmsMainMenu(): Observable<CmsUiDynamicContentMenu> {
    return this.http
      .get<CmsUiDynamicContentMenu>(this.config.apiUrl + '/main-menu')
      .pipe(tap((response: CmsUiDynamicContentMenu) => this.store.update({ cmsMainMenu: response })));
  }

  getHomePage(): Observable<CmsUiDynamicContentPage> {
    return this.http.get<CmsUiDynamicContentPage>(this.config.apiUrl + '/home').pipe(
      tap((response: CmsUiDynamicContentPage) => {
        this.store.update({ homePage: response });
      }),
    );
  }

  getErrorPage(): Observable<CmsUiDynamicContentPage> {
    return this.http.get<CmsUiDynamicContentPage>(this.config.apiUrl + '/error').pipe(
      tap((response: CmsUiDynamicContentPage) => {
        this.store.update({ errorPage: response });
      }),
    );
  }

  getCMSPage(id: number): Observable<CmsUiDynamicContentPage> {
    const selector = `/cms/pages/${id}`;

    return this.http.get<CmsUiDynamicContentPage>(`${this.config.apiUrl}/pages/${id}`).pipe(
      tap((response: CmsUiDynamicContentPage) => {
        this.store.update({ [selector]: response });
      }),
    );
  }

  searchCMSPages(term: string): Observable<BlogPost[]> {
    const params = new HttpParams({ encoder: new CustomHttpParameterCodec() }).set('_q', term);
    return this.http
      .get<BlogPost[]>(this.config.apiUrl + '/pages', { params })
      .pipe(tap((response: BlogPost[]) => this.store.update({ pageSearch: response })));
  }

  getBlogPosts(): Observable<BlogPost[]> {
    return this.http
      .get<BlogPost[]>(this.config.apiUrl + '/posts')
      .pipe(tap((response: BlogPost[]) => this.store.update({ blogPosts: response })));
  }

  searchBlogPosts(term: string): Observable<BlogPost[]> {
    const params = new HttpParams({ encoder: new CustomHttpParameterCodec() }).set('_q', term);
    return this.http
      .get<BlogPost[]>(this.config.apiUrl + '/posts', { params })
      .pipe(tap((response: BlogPost[]) => this.store.update({ blogSearch: response })));
  }

  resetSearchStore(): void {
    this.store.update({ blogSearch: [] });
    this.store.update({ pageSearch: [] });
  }

  getBlogPost(id: number): Observable<BlogPost> {
    const selector = `/cms/posts/${id}`;

    return this.http.get<BlogPost>(`${this.config.apiUrl}/posts/${id}`).pipe(
      tap((response: BlogPost) => {
        this.store.update({ [selector]: response });
      }),
    );
  }

  getFooter(): Observable<Footer> {
    const selector = `${this.config.apiUrl}/footer`;

    return this.http.get<Footer>(selector).pipe(
      tap((response: Footer) => {
        this.store.update({ footer: response });
      }),
    );
  }
}
