import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CountryStore, CountryState } from '../store/country.store';
import { Country } from '../model/country.model';

@Injectable({ providedIn: 'root' })
export class CountryQuery<T extends Country> extends QueryEntity<CountryState<T>> {
  constructor(protected override store: CountryStore<T>) {
    super(store);
  }
}
