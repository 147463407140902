import { Pipe, PipeTransform } from '@angular/core';
import { UrlHelperService } from '../url/url-helper.service';

@Pipe({
  name: 'localizeUrlPath',
})
export class LocalizeUrlPathPipe implements PipeTransform {
  constructor(private urlHelper: UrlHelperService) {}

  public transform(path: string): string {
    return this.urlHelper.localizeUrl(path);
  }
}
