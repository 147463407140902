import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UrlHelperService } from '../../core';
import { CmsHelperService } from '../helper/cms-helper.service';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentMenuLevel, CmsUiDynamicContentPage } from '../model/cms.models';
import { CmsUiDynamicContentQuery } from '../query/cms.query';

@Pipe({
  name: 'pagePath',
})
export class PagePathPipe implements PipeTransform {
  constructor(private urlHelper: UrlHelperService, private cmsQuery: CmsUiDynamicContentQuery, private cmsHelper: CmsHelperService) {}

  public transform(page: CmsUiDynamicContentPage): Observable<string> {
    return this.cmsQuery.cmsMainMenu$.pipe(
      map((menu: CmsUiDynamicContentMenu) => this.cmsHelper.flattenMenu(menu)),
      map((flatMenu: CmsUiDynamicContentMenuLevel[]) => flatMenu.find((item: CmsUiDynamicContentMenuLevel) => item.page?.id === page.id)),
      filter((item: CmsUiDynamicContentMenuLevel | undefined) => !!item),
      map((item: CmsUiDynamicContentMenuLevel | undefined) => this.urlHelper.localizeUrl(item!.slug)),
    );
  }
}
