import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Label } from '../model/label';

export interface LabelState extends EntityState<Label[]> {
  labels: Label[];
  filename: string;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'label-print-labels' })
export class LabelStore extends EntityStore<LabelState> {
  constructor() {
    super({ labels: [], filename: 'print' });
  }
}
