import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ArticleFactory } from '../services/article.factory';
import { Article } from '../model/article.model';
import { Product } from '../model/product.model';
import { ProductState, ProductStore } from '../store/product.store';

@Injectable({
  providedIn: 'root',
})
export class ProductQuery extends QueryEntity<ProductState, Product> {
  constructor(protected override store: ProductStore) {
    super(store);
  }

  public selectById(id: ID): Observable<Product> {
    return this.selectAll({
      filterBy: (product: Product) => product.Id === id,
    }).pipe(
      map((data) => {
        return data[0];
      }),
      filter((p) => !!p),
      map((product: Product) => {
        return {
          ...product,
          oArticles: product.oArticles.map((article: Article) => ArticleFactory.create(article)),
        };
      }),
    );
  }

  public selectByGroupIds(groupIds: number[]): Observable<Product[]> {
    return this.selectAll({
      filterBy: (product: Product) => groupIds.includes(product.lngGroup),
    });
  }

  public getMainProductImage(_: Product): string {
    return '';
  }
}
